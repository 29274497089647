import React, { useState, useEffect } from "react";
import Table from "../table";
import api from "../../../api/axios";
import { Doughnut, Bar } from "react-chartjs-2";
import "chart.js/auto";
import { useNavigate } from "react-router-dom";
import CreateCourseButton from "./createCourseButton";
import {
	MdBusiness,
	MdGroup,
	MdStar,
	MdTrendingUp,
	MdAttachMoney,
} from "react-icons/md";

const CoursesTable = ({ courses }) => {
	const columnNames = [
		"Name",
		"Code",
		"Fees",
		"Duration",
	];
	const columnValues = [
		"name",
		"code",
		"price",
		"duration",
	];

	const args = {
		columnNames: columnNames,
		columnValues: columnValues,
		route: "courses",
	};

	return (
		<div>
			{courses.length > 0 ? (
				<div className="row">
					<Table {...args} data={courses} />
				</div>
			) : (
				<p>You have not registered an organization.</p>
			)}
		</div>
	);
};

const CoursesHeader = ({ courses, employees = [], enrollments = 0 }) => {
	const totalCourses = courses.length;
	const totalEmployees = employees.length || 1;
	const EnrollmentRates =
		courses.reduce((acc, org) => acc + (org.average_sales || 0), 0) /
		courses.length;

	// let performingCourse = courses.reduce(
	// 	(acc, org) => (org.performing_department || 'N/A'),
	// 	0
	// );

	return (
		<div className="d-flex flex-row flex-wrap justify-content-between w-100">
			<div className="col-lg-2 display-cards">
				<div className="w-100 d-flex flex-row justify-content-between align-items-center">
					<h2>{totalCourses}</h2>
					<MdBusiness size={32} />
				</div>
				<div className="w-100 d-flex justify-content-end">
					<p>Total Courses</p>
				</div>
			</div>
			<div className="col-lg-2 display-cards">
				<div className="w-100 d-flex flex-row justify-content-between align-items-center">
					<h2>{totalEmployees}</h2>
					<MdGroup size={32} />
				</div>
				<div className="w-100 d-flex justify-content-end">
					<p>Total Employees</p>
				</div>
			</div>
			{/* <div className="col-lg-2 display-cards">
				<div className="w-100 d-flex flex-row justify-content-between align-items-center">
					<h2>{performingCourse}</h2>
					<MdStar size={32} />
				</div>
				<div className="w-100 d-flex justify-content-end">
					<p>Performing Course</p>
				</div>
			</div> */}
			<div className="col-lg-2 display-cards">
				<div className="w-100 d-flex flex-row justify-content-between align-items-center">
					<h2>{EnrollmentRates}</h2>
					<MdAttachMoney size={32} />
				</div>
				<div className="w-100 d-flex justify-content-end">
					<p>Average Enrollments</p>
				</div>
			</div>
		</div>
	);
};

const OpenCourses = ({ courses }) => {
	const navigate = useNavigate();

	const handleViewAllClick = () => {
		navigate('/courses');
	};

	return (
		<div>
			<div className="card-title">
				<h3>Open Positions by Courses</h3>
			</div>
			<div className="card d-flex justify-content-between">
				{courses.length === 0 ? (
					<p>No courses available.</p>
				) : (
					courses.map((department) => (
						<div key={department.name} className="card-list">
							<h4>{department.name}</h4>
							<ul>
								<li>
									Employees: <span>{department.positions}</span>
								</li>
							</ul>
						</div>
					))
				)}
				<a
					href="#"
					className="view-all-button"
					onClick={handleViewAllClick}
				>
					View All
					<span className="arrow-icon">↓</span>
				</a>
			</div>
		</div>
	);
};

const CoursePerformance = ({ courses }) => {
	const data = courses.length
		? {
				labels: courses.map((dept) => dept.name),
				datasets: [
					{
						data: courses.map((dept) => dept.size || 1),
						backgroundColor: [
							"#fc7703",
							"#FF6384",
							"#36A2EB",
							"#FFCE56",
							"#4BC0C0",
							"#9966FF",
							"#FF9F40",
							"#FFCD56",
							"#4BC0C0",
							"#C9CBCF",
							"#FF6384",
							"#36A2EB",
							"#FFCE56",
							"#4BC0C0",
						],
						hoverBackgroundColor: [
							"#fc7703",
							"#FF6384",
							"#36A2EB",
							"#FFCE56",
							"#4BC0C0",
							"#9966FF",
							"#FF9F40",
							"#FFCD56",
							"#4BC0C0",
							"#C9CBCF",
							"#FF6384",
							"#36A2EB",
							"#FFCE56",
							"#4BC0C0",
						],
					},
				],
		  }
		: {
				labels: ["No Data Available"],
				datasets: [
					{
						data: [1],
						backgroundColor: ["#E0E0E0"],
						hoverBackgroundColor: ["#E0E0E0"],
						borderWidth: 1,
					},
				],
		  };

	const options = {
		indexAxis: "x",
		scales: {
			x: {
				beginAtZero: true,
			},
		},
		plugins: {
			legend: {
				position: "bottom",
			},
		},
		maintainAspectRatio: false,
		cutout: '70%',
	};

	return (
		<div>
			<div className="card-title">
				<h3>Course Performance</h3>
			</div>
			<div className="card chart-container">
				<Doughnut data={data} options={options} />
			</div>
		</div>
	);
};

const CourseSizes = ({ courses }) => {
	const data = courses.length
		? {
				labels: courses.map((dept) => dept.name),
				datasets: [
					{
						label: "Number of Employees",
						data: courses.map((dept) => dept.size || 1),
						backgroundColor: "#fc9003",
						hoverBackgroundColor: "#fc7703",
					},
				],
		  }
		: {
				labels: ["No Data Available"],
				datasets: [
					{
						label: "Number of Employees",
						data: [1],
						backgroundColor: ["#E0E0E0"],
						hoverBackgroundColor: ["#E0E0E0"],
					},
				],
		  };

	const options = {
		indexAxis: "x",
		scales: {
			x: {
				beginAtZero: true,
			},
		},
		plugins: {
			legend: {
				display: true,
				position: 'bottom',
			},
			tooltip: {
				enabled: true,
			},
		},
		maintainAspectRatio: false,
	};

	return (
		<div>
			<div className="card-title">
				<h3>Course Sizes</h3>
			</div>
			<div className="card chart-container">
				<Bar data={data} options={options} />
			</div>
		</div>
	);
};

const Courses = () => {
	const [headerData, setHeaderData] = useState([]);
	const [courses, setCourses] = useState([]);
	const [user, setUser] = useState(null);

	useEffect(() => {
		api.get("/user")
			.then(({ data: userData }) => {
				setUser(userData.id);
			})
			.catch((error) => {
				console.error("Error fetching data", error.response);
			});
	}, []);

	useEffect(() => {
		const handleDataFetch = async () => {
			try {
				const response = await api.get(`/courses`);
				console.log(response.data.courses.data);
				setCourses(response.data.courses.data);
				setHeaderData(response.data.data);
			} catch (error) {
				console.error("Error fetching data", error.response);
			}
		};

		if (user) {
			handleDataFetch();
		}
	}, [user]);

	return (
		<div className="courses-section">
			<div className="container">
				<div className="row">
					<div className="col-lg-9">
						<h2 className="page-titles">Courses Overview</h2>
					</div>
					<div className="col-lg-3 d-flex justify-content-end float-right">
						<CreateCourseButton userId={user} />
					</div>
				</div>
				<div className="row mb-4 mt-3">
					<p>This month</p>
					{/* <CoursesHeader courses={headerData} /> */}
				</div>
				<div className="row">
					<div className="col-lg-4">
						<OpenCourses courses={[]} />
					</div>
					<div className="col-lg-4">
						<CoursePerformance courses={[]} />
					</div>
					<div className="col-lg-4">
						<CourseSizes courses={[]} />
					</div>
				</div>
				<div className="row">
					<div className="col-lg-12">
						<div className="row">
							<h3>Available Courses</h3>
						</div>
						<div className="row mt-2">
							<CoursesTable courses={courses} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Courses;
