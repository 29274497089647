import React, { useState, useEffect } from "react";
import {
	PlannerHeader,
	Calendar,
} from "../../components/user/taskPlannerComponents";
import CalendarPannel from "../../components/user/calendarPannel";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import api from "../../api/axios";

const PlannerPage = () => {
	const [userTasksData, setUserTasksData] = useState([]);
	const [currentDate, setCurrentDate] = useState(new Date());
	const [viewMode, setViewMode] = useState("week");

	const toggleViewMode = () => {
		setViewMode((prevMode) => (prevMode === "week" ? "month" : "week"));
	};

	const goToPreviousWeek = () => {
		const newDate = new Date(currentDate);
		newDate.setDate(currentDate.getDate() - 7);
		setCurrentDate(newDate);
	};

	const goToNextWeek = () => {
		const newDate = new Date(currentDate);
		newDate.setDate(currentDate.getDate() + 7);
		setCurrentDate(newDate);
	};

	const goToCurrentWeek = () => {
		setCurrentDate(new Date());
	};

	const formatDay = (day) => {
		const suffixes = ["th", "st", "nd", "rd"];
		const digit = day % 10;
		const suffix =
			suffixes[day % 100 > 10 && day % 100 < 14 ? 0 : digit] ||
			suffixes[0];
		return `${day}${suffix}`;
	};

	const formatDate = (date) => {
		const month = date.toLocaleDateString("en-US", { month: "long" });
		return `${formatDay(date.getDate())} ${month} ${date.getFullYear()}`;
	};

	const currentWeekStartDate = new Date(currentDate);
	currentWeekStartDate.setDate(
		currentWeekStartDate.getDate() - currentWeekStartDate.getDay()
	);
	const currentWeekEndDate = new Date(currentWeekStartDate);
	currentWeekEndDate.setDate(currentWeekEndDate.getDate() + 6);

	const formattedStartDate = formatDate(currentWeekStartDate);
	const formattedEndDate = formatDate(currentWeekEndDate);

	const year = currentDate.getFullYear();
	const month = currentDate.getMonth() + 1;
	const formattedMonth = `${year}-${month.toString().padStart(2, "0")}`;

	useEffect(() => {
		api.get("/user")
			.then(({ data: userData }) => {
				if (userData.id) {
					api.get(`/calendar/${userData.id}?month=${formattedMonth}`)
						.then(({ data: userTaskData }) => {
							console.log("Tasks", userTaskData);
							setUserTasksData(userTaskData);
						})
						.catch((error) => {
							console.error(
								"Error fetching user tasks",
								error.response
							);
						});
				}
			})
			.catch((error) => {
				console.error("Error fetching data", error.response);
			});
	}, [formattedMonth]);

	return (
		<div className="planner-section">
			<div className="calendar-Pannel">
				<CalendarPannel userTasksData={userTasksData} />
			</div>

			<div className="w-100">
				<div className="row">
					<div className="col-lg-9 mb-3">
						<h2 className="date">
							{formattedStartDate} - {formattedEndDate}
						</h2>
						<div className="task-btn">
							<button
								className="add-task-button mr-2"
								onClick={toggleViewMode}
							>
								{viewMode === "week" ? "Week" : "Month"}
							</button>
							<div className="calendar-nav-btn-container">
								<div className="d-flex flex-row justify-content-between">
									<button
										className="add-task-button mr-2"
										onClick={goToPreviousWeek}
									>
										<AiOutlineArrowLeft />
									</button>
									<button
										className="add-task-button"
										onClick={goToCurrentWeek}
									>
										Today
									</button>
									<button
										className="add-task-button ml-2"
										onClick={goToNextWeek}
									>
										<AiOutlineArrowRight />
									</button>
								</div>
							</div>
						</div>
					</div>
					<div
						className="col-lg-3 d-flex float-right justify-content-center"
					><PlannerHeader /></div>
				</div>
				<div>
					<Calendar
						userTasksData={userTasksData}
						setUserTasksData={setUserTasksData}
						currentDate={currentDate}
						viewMode={viewMode}
					/>
				</div>
			</div>
		</div>
	);
};

export default PlannerPage;
