import React, { useEffect, useState } from "react";
import api from "../../api/axios";
import Preloader from "../preloader";
import Table from "./table";

const EventsTable = ({ events }) => {
	const route = "planning/events";
	const columnNames = [
		"date",
		"name",
		"description",
		"address",
		"Venue",
		"type",
		"status",
	];
	const columnValues = [
		"due_date",
		"name",
		"description",
		"address",
		"venue",
		"type",
		"status",
	];

	const args = {
		columnNames: columnNames,
		columnValues: columnValues,
		route: route,
	};

	return (
		<div>
			<div className="row">
				<Table {...args} data={events} />
			</div>
		</div>
	);
};

const Events = () => {
	const [user, setUser] = useState();
	const [view, setView] = useState({});
	const [load, setLoad] = useState(true);

	useEffect(() => {
		api.get("/user")
			.then(({ data: userData }) => {
				setUser(userData.id);
			})
			.catch((error) => {
				console.error("Error fetching data", error.response);
			});
	}, []);

	useEffect(() => {
		const handleDataFetch = async () => {
			try {
				const response = await api.get(`/users-data/events/${user}`);
				setView(response.data[0][0].data);
				console.log(response.data[0][0].data);
			} catch (error) {
				console.error("Error fetching data", error.response);
			} finally {
				setLoad(false);
			}
		};

		if (user) {
			handleDataFetch();
		}
	}, [user]);

	return load ? (
		<Preloader />
	) : (
		<div className="campaigns-section">
			<div className="container">
				<div className="d-flex flex-row">
					<h4 className="mr-1">Calendar</h4>
					<h4 className="mr-1">{">"}</h4>
					<h4 className="mr-1">
						<b>Events</b>
					</h4>
				</div>
				<div className="row">
					<div
						className="col-lg-12 d-flex flex-row justify-content-between align-items-center"
						style={{ margin: "15px 0px 15px 0px" }}
					>
						<h2 className="page-titles">Events</h2>
					</div>
					<div className="col-lg-12">
						<EventsTable events={view} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Events;
