import AddLecturersButton from "./addLecturer";
import React, { useEffect, useState } from "react";
import api from "../../api/axios";
import Preloader from "../preloader";
import Table from "./table";

const LecturersTable = ({ organizations }) => {
  const route = "lecturer";

  const columnValues = [
    "first_name",
    "last_name",
    "email",
    "contact",
    "started_in",
    "city",
    "country",
  ];

  const columnNames = [
    "First Name",
    "Last Name",
    "Email",
    "Contact",
    "Joined in",
    "City",
    "Country",
  ];

  const headingStyle = {
    fontSize: "medium",
    fontWeight: "600",
    fontFamily: "Arial, Helvetica, sans-serif",
    margin: "10px 0",
  };

  const args = {
    columnNames: columnNames,
    columnValues: columnValues,
    route: route,
  };

  return (
    <div>
      {organizations && organizations.length > 0 ? (
        organizations.map((organization) => (
          <div className="row" key={organization.organization}>
            <p style={headingStyle}>{organization.organization}</p>
            <Table {...args} data={organization.vendors} />
          </div>
        ))
      ) : (
        <p>You are currently not registered in an organization.</p>
      )}
    </div>
  );
};

const Lecturers = () => {
  const [user, setUser] = useState();
  const [view, setView] = useState({});
  const [load, setLoad] = useState(true);

  useEffect(() => {
    api
      .get("/user")
      .then(({ data: userData }) => {
        setUser(userData.id);
      })
      .catch((error) => {
        console.error("Error fetching data", error.response);
      });
  }, []);

  useEffect(() => {
    const handleDataFetch = async () => {
      try {
        api
          .get("/institutions")
          .then(async ({ data: instituteData }) => {
            const response = await api.get(
              `/lecturers/${instituteData.institute.institution_id}`
            );
            setView(response.data.lecturers.data);
          })
          .catch((error) => {
            console.error("Error fetching data", error.response);
          });
      } catch (error) {
        console.error("Error fetching data", error.response);
      } finally {
        setLoad(false);
      }
    };

    if (user) {
      handleDataFetch();
    }
  }, [user]);

  return load ? (
    <Preloader />
  ) : (
    <div className="campaigns-section">
      <div className="container">
        <div className="d-flex flex-row">
          <h4 className="mr-1">Analysis</h4>
          <h4 className="mr-1">{">"}</h4>
          <h4 className="mr-1">
            <b>Lecturers</b>
          </h4>
        </div>
        <div className="row">
          <div
            className="col-lg-12 d-flex flex-row justify-content-between align-items-center"
            style={{ margin: "15px 0px 15px 0px" }}
          >
            <h2 className="page-titles">Lecturers</h2>
            <div className="d-flex flex-row">
              <AddLecturersButton userId={user} />
            </div>
          </div>
          <div className="col-lg-12">
            <LecturersTable organizations={view} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lecturers;
