import React from "react";

const ContactSection = () => {
    return (
        <div className="d-flex justify-content-center mt-5" data-scroll-index="3">
            <div className="contact-section d-block">
                <form className="contact-form validate-form">
                    <span className="contact-form-title">Contact Us</span>
                    <div className="wrap-input rs1-wrap-input validate-input" data-validate="Name is required">
                        <span className="label-input">Your Name</span>
                        <input className="input" type="text" name="name" placeholder="Enter your name" />
                        <span className="focus-input"></span>
                    </div>
                    <div
                        className="wrap-input rs1-wrap-input validate-input"
                        data-validate="Valid email is required: ex@abc.xyz"
                    >
                        <span className="label-input">Email</span>
                        <input className="input" type="text" name="email" placeholder="Enter your email addess" />
                        <span className="focus-input"></span>
                    </div>
                    <div className="wrap-input validate-input" data-validate="Message is required">
                        <span className="label-input">Message</span>
                        <textarea className="input" name="message" placeholder="Your message here..."></textarea>
                        <span className="focus-input"></span>
                    </div>
                    <div className="container-contact-form-btn">
                        <button className="contact-form-btn">
                            <span>
                                Submit
                                <i className="fa fa-long-arrow-right m-l-7" aria-hidden="true"></i>
                            </span>
                        </button>
                    </div>
                </form>
                <span className="contact-more">
                    For any question contact our 24/7 call center:{" "}
                    <span className="contact-more-highlight">+254 7436 87737</span>
                </span>
            </div>
        </div>
    );
};

export default ContactSection;
