import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import api from "../../api/axios";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { MenuItem, TextField } from "@mui/material";
import parseISO from "date-fns/parseISO";
import CustomDialog from "./dialog";
import { TextFieldComponent, SelectField, NumberField } from "./formFields";

const formFields = {
	events: {
		name: "",
		venue: "",
		description: "",
		address: "",
		start_date: parseISO(new Date().toISOString().split("T")[0]),
		due_date: parseISO(new Date().toISOString().split("T")[0]),
		start_time: new Date().toLocaleTimeString("en-US", { hour12: false }),
		end_time: new Date().toLocaleTimeString("en-US", { hour12: false }),
		location: "",
		type: "paid",
		budget: 0,
		cost: 0,
		status: "upcoming",
	},

	reminders: {
		name: "",
		note: "",
		due_date: parseISO(new Date().toISOString().split("T")[0]),
	},

	tasks: {
		name: "",
		description: "",
		notes: "",
		start_date: parseISO(new Date().toISOString().split("T")[0]),
		due_date: parseISO(new Date().toISOString().split("T")[0]),
		start_time: new Date().toLocaleTimeString("en-US", { hour12: false }),
		end_time: new Date().toLocaleTimeString("en-US", { hour12: false }),
		priority: "Medium",
		status: "pending",
	},

	team_projects: {
		project_name: "",
		type: "",
		description: "",
		notes: "",
		start_date: parseISO(new Date().toISOString().split("T")[0]),
		due_date: parseISO(new Date().toISOString().split("T")[0]),
		status: "pending",
	},

	personal_projects: {
		project_name: "",
		type: "",
		description: "",
		notes: "",
		start_date: parseISO(new Date().toISOString().split("T")[0]),
		due_date: parseISO(new Date().toISOString().split("T")[0]),
		status: "pending",
	},
};

const CreateTaskButton = () => {
	const [open, setOpen] = useState(false);
	const [activeTab, setActiveTab] = useState(0);
	const [organization, setOrganization] = useState(null);
	const [userId, setUserId] = useState(null);
	const [formData, setFormData] = useState(formFields.personal_projects);
	const [route, setRoute] = useState("events");

	useEffect(() => {
		api.get("/user")
			.then(({ data: userData }) => {
				setUserId(userData.id);
				setFormData((prevData) => ({
					...prevData,
					user_id: userData.id,
				}));
			})
			.catch((error) => {
				console.error("Error fetching data", error.response);
			});
	}, []);

	useEffect(() => {
		const fetchOrganization = () => {
			api.get(`/organizations/user-organization/${userId}`)
				.then(({ data: userData }) => {
					console.log("role =>", userData.role[0].role);
					if (userData.role[0].role === "admin") {
						setOrganization(
							userData.organizations[0].organization_id
						);
					}
				})
				.catch((error) => {
					console.error(
						"Error fetching organization details.",
						error.response
					);
				});
		};

		if (userId) {
			fetchOrganization();
		}
	}, [userId]);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleFormChange = (field, value) => {
		setFormData((prevData) => ({ ...prevData, [field]: value }));
	};

	const handleCreateTask = async () => {
		const isoStartDate = formData.start_date.toJSON().split("T")[0] || "";
		const isoDueDate = formData.due_date.toJSON().split("T")[0] || "";

		const formattedFormData = {
			...formData,
			organization_id: organization,
			start_date: isoStartDate,
			due_date: isoDueDate,
		};

		try {
			const response = await api.post(`/${route}/`, formattedFormData);
			console.log(`${route} created successfully:`, response.data);
			handleClose();
		} catch (error) {
			console.error("Unable to create task", error);
		}
	};

	const handleTabChange = (event, newValue) => {
		setActiveTab(newValue);

		switch (newValue) {
			case 0:
				setFormData(formFields.personal_projects);
				setRoute("personal_projects");
				handleFormChange("user_id", userId);
				break;
			case 1:
				setFormData(formFields.reminders);
				setRoute("reminders");
				handleFormChange("user_id", userId);
				break;
			case 2:
				setFormData(formFields.tasks);
				setRoute("tasks");
				handleFormChange("user_id", userId);
				break;
			case 3:
				setFormData(formFields.team_projects);
				setRoute("team_projects");
				handleFormChange("team_id", userId);
				break;
			case 4:
				setFormData(formFields.events);
				setRoute("events");
				handleFormChange("user_id", userId);
				break;
			default:
				setFormData(formFields.events);
				setRoute("events");
		}
	};

	return (
		<>
			<div className="task-btn w-100">
				<button className="add-task-button" onClick={handleOpen}>
					+ Add New Task
				</button>
			</div>
			<CustomDialog
				isOpen={open}
				onClose={handleClose}
				event={{ type: "Create a new task" }}
				user={{ id: userId }}
				handleSubmit={handleCreateTask}
			>
				<Tabs
					value={activeTab}
					onChange={handleTabChange}
					variant="fullWidth"
					className="mb-3"
				>
					<Tab label="Reminders" />
					<Tab label="Tasks" />
					<Tab label="Personal Projects" />
					<Tab label="Team Projects" />
					{organization ? <Tab label="Events" /> : ""}
				</Tabs>

				{activeTab === 0 && (
					<div className="d-flex flex-column" style={{ gap: "30px" }}>
						<TextFieldComponent
							label="Project Title"
							value={formData.title}
							onChange={(e) =>
								handleFormChange("title", e.target.value)
							}
						/>
						<TextField
							label="Project Description"
							fullWidth
							multiline
							rows={4}
							value={formData.description}
							onChange={(e) =>
								handleFormChange("description", e.target.value)
							}
						/>
						<DatePicker
							selected={formData.start_date}
							onChange={(value) =>
								handleFormChange("start_date", value)
							}
							dateFormat="yyyy-MM-dd"
							customInput={
								<TextFieldComponent
									label="Start Date"
									fullWidth
								/>
							}
						/>
						<DatePicker
							selected={formData.due_date}
							onChange={(value) =>
								handleFormChange("due_date", value)
							}
							dateFormat="yyyy-MM-dd"
							customInput={
								<TextFieldComponent
									label="Due Date"
									fullWidth
								/>
							}
						/>
						<TextField
							label="Notes"
							fullWidth
							multiline
							rows={4}
							value={formData.notes}
							onChange={(e) =>
								handleFormChange("notes", e.target.value)
							}
						/>

						<SelectField
							label="Status"
							value={formData.status}
							onChange={(e) =>
								handleFormChange("status", e.target.value)
							}
							options={["Pending", "Completed", "Inactive"]}
						/>
					</div>
				)}
				{activeTab === 1 && (
					<div className="d-flex flex-column" style={{ gap: "30px" }}>
						<TextFieldComponent
							label="Reminder Title"
							value={formData.name}
							onChange={(e) =>
								handleFormChange("name", e.target.value)
							}
						/>
						<TextField
							label="Notes"
							fullWidth
							multiline
							rows={4}
							value={formData.note}
							onChange={(e) =>
								handleFormChange("note", e.target.value)
							}
						/>
						<DatePicker
							selected={formData.due_date}
							onChange={(value) =>
								handleFormChange("due_date", value)
							}
							dateFormat="yyyy-MM-dd"
							customInput={
								<TextFieldComponent
									label="Due Date"
									fullWidth
								/>
							}
						/>
					</div>
				)}

				{activeTab === 2 && (
					<div className="d-flex flex-column" style={{ gap: "30px" }}>
						<TextFieldComponent
							label="Task Title"
							value={formData.name}
							onChange={(e) =>
								handleFormChange("title", e.target.value)
							}
						/>
						<TextField
							label="Task Description"
							fullWidth
							multiline
							rows={4}
							value={formData.description}
							onChange={(e) =>
								handleFormChange("description", e.target.value)
							}
						/>

						<DatePicker
							selected={formData.start_date}
							onChange={(value) =>
								handleFormChange("start_date", value)
							}
							dateFormat="yyyy-MM-dd"
							customInput={
								<TextFieldComponent
									label="Start Date"
									fullWidth
								/>
							}
						/>

						<DatePicker
							selected={formData.due_date}
							onChange={(value) =>
								handleFormChange("due_date", value)
							}
							dateFormat="yyyy-MM-dd"
							customInput={
								<TextFieldComponent
									label="Due Date"
									fullWidth
								/>
							}
						/>

						<TextField
							label="Start Time"
							type="time"
							step="2"
							value={formData.start_time}
							onChange={(e) =>
								handleFormChange("start_time", e.target.value)
							}
							InputLabelProps={{
								shrink: true,
							}}
						/>

						<TextField
							label="End Time"
							type="time"
							step="2"
							value={formData.end_time}
							onChange={(e) =>
								handleFormChange("end_time", e.target.value)
							}
							InputLabelProps={{
								shrink: true,
							}}
						/>

						<TextFieldComponent
							label="Priority"
							value={formData.priority}
							onChange={(e) =>
								handleFormChange("priority", e.target.value)
							}
						/>
					</div>
				)}

				{activeTab === 3 && (
					<div className="d-flex flex-column" style={{ gap: "30px" }}>
						<TextFieldComponent
							label="Project Title"
							value={formData.name}
							onChange={(e) =>
								handleFormChange("name", e.target.value)
							}
						/>
						<TextField
							label="Project Description"
							fullWidth
							multiline
							rows={4}
							value={formData.description}
							onChange={(e) =>
								handleFormChange("description", e.target.value)
							}
						/>

						<DatePicker
							selected={formData.start_date}
							onChange={(value) =>
								handleFormChange("start_date", value)
							}
							dateFormat="yyyy-MM-dd"
							customInput={
								<TextFieldComponent
									label="Start Date"
									fullWidth
								/>
							}
						/>

						<DatePicker
							selected={formData.due_date}
							onChange={(value) =>
								handleFormChange("due_date", value)
							}
							dateFormat="yyyy-MM-dd"
							customInput={
								<TextFieldComponent
									label="Due Date"
									fullWidth
								/>
							}
						/>

						<TextField
							label="Notes"
							fullWidth
							multiline
							rows={4}
							value={formData.notes}
							onChange={(e) =>
								handleFormChange("notes", e.target.value)
							}
						/>
					</div>
				)}
				{activeTab === 4 && (
					<div className="d-flex flex-column" style={{ gap: "30px" }}>
						<TextFieldComponent
							label="Event Name"
							value={formData.name}
							onChange={(e) =>
								handleFormChange("name", e.target.value)
							}
						/>
						<TextFieldComponent
							label="Event Venue"
							value={formData.venue}
							onChange={(e) =>
								handleFormChange("venue", e.target.value)
							}
						/>
						<TextField
							label="Event Description"
							fullWidth
							multiline
							rows={4}
							value={formData.description}
							onChange={(e) =>
								handleFormChange("description", e.target.value)
							}
						/>
						<DatePicker
							selected={formData.start_date}
							onChange={(value) =>
								handleFormChange("start_date", value)
							}
							dateFormat="yyyy-MM-dd"
							customInput={
								<TextFieldComponent
									label="Start Date"
									fullWidth
								/>
							}
						/>

						<DatePicker
							selected={formData.due_date}
							onChange={(value) =>
								handleFormChange("due_date", value)
							}
							dateFormat="yyyy-MM-dd"
							customInput={
								<TextFieldComponent
									label="Due Date"
									fullWidth
								/>
							}
						/>

						<TextField
							label="Start Time"
							type="time"
							step="2"
							value={formData.start_time}
							onChange={(e) =>
								handleFormChange("start_time", e.target.value)
							}
							InputLabelProps={{
								shrink: true,
							}}
						/>

						<TextField
							label="End Time"
							type="time"
							step="2"
							value={formData.end_time}
							onChange={(e) =>
								handleFormChange("end_time", e.target.value)
							}
							InputLabelProps={{
								shrink: true,
							}}
						/>

						<TextField
							label="Address"
							fullWidth
							multiline
							rows={4}
							value={formData.address}
							onChange={(e) =>
								handleFormChange("address", e.target.value)
							}
						/>

						<TextFieldComponent
							label="Location"
							value={formData.location}
							onChange={(e) =>
								handleFormChange("location", e.target.value)
							}
						/>
						<NumberField
							label="Budget"
							field="budget"
							value={formData.budget}
							onChange={handleFormChange}
						/>
						<NumberField
							label="Cost"
							field="number"
							value={formData.cost}
							onChange={handleFormChange}
						/>

						<TextField
							label="Type"
							value={formData.type ? formData.type : "free"}
							onChange={(e) =>
								handleFormChange("type", e.target.value)
							}
							select
						>
							<MenuItem value="free">Free</MenuItem>
							<MenuItem value="paid">Paid</MenuItem>
						</TextField>

						<SelectField
							label="Status"
							value={
								formData.status ? formData.status : "upcoming"
							}
							onChange={(e) =>
								handleFormChange("status", e.target.value)
							}
							options={["Upcoming", "Attended", "Missed"]}
						/>
					</div>
				)}
			</CustomDialog>
		</>
	);
};

export default CreateTaskButton;
