import Home from "../pages/default/index";
import Support from "../pages/default/support";
import LoginPage from "../pages/default/login";
import SignupPage from "../pages/default/signup";
import CompleteRegistration from "../pages/default/completeRegistration";

import AttendancePage from "../pages/user/attendance";
import EventsPage from "../pages/user/events";
import Dashboard from "../pages/user/dashboard";
import PlannerPage from "../pages/user/planner";
import LecturersPage from "../pages/user/lecturers";
import SettingsPage from "../pages/user/settings";
import MessagesPage from "../pages/user/messages";
import EnrollmentsPage from "../pages/user/enrollments";
import CoursesPage from "../pages/user/courses";
import FinancialsPage from "../pages/user/financials";
import NotificationsPage from "../pages/user/notifications";
import EventInfo from "../components/user/eventInfo";
import AdmissionsPage from "../pages/user/admissions";
import ResultsPage from "../pages/user/results";

const routes = {
	Home: "/",
	FAQ: "/support",
	Login: "/login",
	Signup: "/signup",
	User: "/user",
};

const userRoutes = {
	Admissions: "/admissions",
	Attendance: "/attendance",
	Courses: "/courses",
	Course: "/course",
	Dashboard: "/dashboard",
	Enrollments: "/enrollments",
	Financials: "/financials",
	Lecturers: "/lecturers",
	Messages: "/messages",
	Notifications: "/notifications",
	Planner: "/planning",
	Results: "/results",
	Settings: "/settings",
};

const searchLinks = [userRoutes];

const defaultPaths = [
	{
		path: routes.Home,
		element: <Home />,
	},
	{
		path: routes.FAQ,
		element: <Support />,
	},
	{
		path: routes.Login,
		element: <LoginPage />,
	},
	{
		path: routes.Signup,
		element: <SignupPage />,
	},
	{
		path: routes.User,
		element: <CompleteRegistration />,
	},
];

const userPaths = [
	{
		path: userRoutes.Admissions,
		element: <AdmissionsPage />,
	},
	{
		path: userRoutes.Attendance,
		element: <AttendancePage />,
	},
    {
		path: userRoutes.Courses,
		element: <CoursesPage />,
	},
	{
		path: userRoutes.Dashboard,
		element: <Dashboard />,
	},
	{
		path: userRoutes.Enrollments,
		element: <EnrollmentsPage />,
	},
	{
		path: userRoutes.Financials,
		element: <FinancialsPage />,
	},
	{
		path: userRoutes.Lecturers,
		element: <LecturersPage />,
	},
	{
		path: userRoutes.Messages,
		element: <MessagesPage />,
	},
	{
		path: userRoutes.Notifications,
		element: <NotificationsPage />,
	},
	{
		path: userRoutes.Planner,
		element: <PlannerPage />,
	},
	{
		path: `${userRoutes.Planner}/events`,
		element: <EventsPage />
	},
	{
		path: `${userRoutes.Planner}/events/:eventId`,
		element: <EventInfo />,
	},
	{
		path: userRoutes.Results,
		element: <ResultsPage />
	},
	{
		path: userRoutes.Settings,
		element: <SettingsPage />,
	},
];

export { routes, userRoutes, defaultPaths, userPaths, searchLinks };
