import React, { useEffect, useState } from "react";
import CreateLeadsButton from "./createLeads";
import api from "../../api/axios";
import Preloader from "../preloader";
import Table from "./table";

const LeadTable = ({ organizations }) => {
	const route = "results";
	const columnNames = [
		"Student Id",
		"First Name",
		"Last Name",
		"Course",
		"Grade",
		"Status",
	];
	const columnValues = [
		"stud_id",
		"first_name",
		"last_name",
		"course",
		"grade",
		"status",
	];

	const headingStyle = {
		fontSize: "medium",
		fontWeight: "600",
		fontFamily: "Arial, Helvetica, sans-serif",
		margin: "10px 0",
	};

	const args = {
		columnNames: columnNames,
		columnValues: columnValues,
		route: route,
	};

	return (
		<div>
			{organizations && organizations.length > 0 ? (
				organizations.map((organization) => (
					<div className="row" key={organization.id}>
						<p style={headingStyle}>{organization.organization}</p>
						<Table {...args} data={organization.leads} />
					</div>
				))
			) : (
				<p>You are currently not registered in an organization.</p>
			)}
		</div>
	);
};
const Attendance = () => {
	const [user, setUser] = useState();
	const [view, setView] = useState({});
	const [load, setLoad] = useState(true);

	useEffect(() => {
		api.get("/user")
			.then(({ data: userData }) => {
				setUser(userData.id);
			})
			.catch((error) => {
				console.error("Error fetching data", error.response);
			});
	}, []);

	useEffect(() => {
		const handleDataFetch = async () => {
			try {
				const response = await api.get(`/users-data/leads/${user}`);
				console.log(response.data.data);

				setView(response.data.data);
			} catch (error) {
				console.error("Error fetching data", error.response);
			} finally {
				setLoad(false);
			}
		};

		if (user) {
			handleDataFetch();
		}
	}, [user]);

	return load ? (
		<Preloader />
	) : (
		<div className="leads-section">
			<div className="container">
				<div className="d-flex flex-row">
					<h4 className="mr-1">Academic</h4>
					<h4 className="mr-1">{"> "}</h4>
					<h4 className="mr-1">
						<b>Attendance</b>
					</h4>
				</div>
				<div className="row">
					<div
						className="col-lg-12 d-flex flex-row justify-content-between align-items-center"
						style={{ margin: "15px 0px 15px 0px" }}
					>
						<h2 className="page-titles">Attendance</h2>
						<div>
							<CreateLeadsButton userId={user} />
						</div>
					</div>
					<div className="col-lg-12">
						<LeadTable organizations={view} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Attendance;
