import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import api from "../../api/axios";
import { parseISO } from "date-fns";
import CustomDialog from "./dialog";
import {
  AutocompleteField,
  TextFieldComponent,
  SelectField,
} from "./formFields";
import { TextField } from "@mui/material";

const EnrollStudent = ({ userId }) => {
  const [open, setOpen] = useState(false);
  const [courses, setCourses] = useState([]);
  const [genderOptions] = useState(["male", "female"]);
  const [formData, setFormData] = useState({
    admission_no: "",
    first_name: "",
    surname: "",
    phone: "",
    email: "",
    country: "",
    city: "",
    course: null,
    // date_enrolled: new Date().toISOString().split('T')[0],
    exam_body: "",
    passport: "",
    postal_code: "00000",
    gender: null,
    image: "",
    errorMessage: "",
  });

  useEffect(() => {
    const getCourses = () => {
      api
        .get(`/courses`)
        .then(({ data: userData }) => {
          setCourses(userData.courses.data);
        })
        .catch((error) => {
          console.error("Error fetching courses", error.response);
        });
    };
    if (userId) {
      getCourses();
    }
  }, [userId]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFormData((prevData) => ({ ...prevData, errorMessage: "" }));
  };

  const handleFormChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({ ...prevData, file: file }));
  };

  const handleCreateCampaign = async () => {
    const requiredFields = [
      "admission_no",
      "first_name",
      "surname",
      "phone",
      "email",
      "city",
      "course",
      "postal_code",
      // "date_enrolled",
      "gender",
    ];

    const hasEmptyFields = requiredFields.some((field) => !formData[field]);
    console.log(hasEmptyFields);

    if (hasEmptyFields) {
      setFormData((prevData) => ({
        ...prevData,
        errorMessage: "Please fill all fields.",
      }));
      return;
    }

    console.log(formData);
    try {
      const response = await api.post(
        `/enrollments`,
        { formData },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Ennrollment created successfully:", response.data);
    } catch (error) {
      console.error("Unable to create enrollment", error);
    }
    handleClose();
  };

  return (
    <div>
      <button className="upload-btn" onClick={handleOpen}>
        Enroll Student
      </button>
      <CustomDialog
        isOpen={open}
        onClose={handleClose}
        event={{ type: "Enroll Student" }}
        user={{ id: userId }}
        handleSubmit={handleCreateCampaign}
      >
        <div className="d-flex flex-column" style={{ gap: "30px" }}>
          <TextFieldComponent
            label="First Name"
            field="first_name"
            value={formData.first_name}
            onChange={handleFormChange}
          />
          <TextFieldComponent
            label="Surname"
            field="surname"
            value={formData.surname}
            onChange={handleFormChange}
          />

          <AutocompleteField
            label="Course"
            options={courses}
            getOptionLabel={(course) => course.name}
            valueFinder={(course) => course.id === formData.course}
            onChange={(newValue) =>
              handleFormChange("course", newValue ? newValue.id : "")
            }
          />
          <TextFieldComponent
            label="Admission No"
            field="admission_no"
            value={formData.admission_no}
            onChange={handleFormChange}
          />
          <TextFieldComponent
            label="Phone No"
            field="phone"
            value={formData.phone}
            onChange={handleFormChange}
          />
          <TextFieldComponent
            label="Email"
            field="email"
            value={formData.email}
            onChange={handleFormChange}
          />
          <TextFieldComponent
            label="Country"
            field="country"
            value={formData.country}
            onChange={handleFormChange}
          />
          <TextFieldComponent
            label="City"
            field="city"
            value={formData.city}
            onChange={handleFormChange}
          />
          <TextFieldComponent
            label="Postal Code"
            field="postal_code"
            value={formData.postal_code}
            onChange={handleFormChange}
          />
          {/* <
          <DatePicker
            selected={formData.date_enrolled}
            onChange={(value) => handleFormChange("date_enrolled", value)}
            dateFormat="yyyy-MM-dd"
            customInput={<TextFieldComponent label="Date Enrolled" />}
          /> */}
          <SelectField
            label="Gender"
            value={formData.gender}
            options={genderOptions}
            onChange={(value) => handleFormChange("gender", value)}
          />
          <TextField
            fullWidth
            type="file"
            accept="image/*"
            onChange={handleImageChange}
          />
          <TextFieldComponent
            label="Exam Body (e.g Knec)"
            field="exam_body"
            value={formData.exam_body}
            onChange={handleFormChange}
          />
          <TextFieldComponent
            label="National Identification (ID) / Passport"
            field="passport"
            value={formData.passport}
            onChange={handleFormChange}
          />
        </div>
        {formData.errorMessage && (
          <div style={{ color: "red", marginTop: "10px" }}>
            {formData.errorMessage}
          </div>
        )}
      </CustomDialog>
    </div>
  );
};

export default EnrollStudent;
