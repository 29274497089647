import React, { useEffect } from "react";
import { DefaultContext, UserContext } from "../context/context";
import { Outlet } from "react-router-dom";
import { useStateContext } from "../context/ContextProvider";
import api from "../api/axios";
import Pannels from "./user/header";

const GuestLayout = () => {
    return (
        <div>
            <DefaultContext />
            <Outlet />
        </div>
    );
};

const UserLayout = () => {
    const {token } = useStateContext();

    if (!token) {
        return <UserContext />;
    }

    GetUser();
    return (
        <div>
            <UserContext />
            <Pannels />
        </div>
    );
};

const GetUser = () => {
    const { setUser } = useStateContext();

    useEffect(() => {
        api.get("/user").then(({ data }) => {
            setUser(data);
        });
    }, [setUser]);
    return null;
};

export { GuestLayout, UserLayout };
