import React from "react";
import Notifications from "../../components/user/notificationsComponents";

const NotificationsPage = () => {
    return (
        <div>
            <Notifications />
        </div>
    )
}

export default NotificationsPage;
