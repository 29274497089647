import React, { useState, useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import { Autocomplete, TextField } from "@mui/material";

import {
	AiOutlineFolder,
	AiOutlineClockCircle,
	AiOutlineCalendar,
	AiOutlinePaperClip,
	AiOutlineDoubleRight,
	AiOutlineEnvironment,
	AiOutlineBars,
} from "react-icons/ai";

const SearchBar = () => {
	const [searchQuery, setSearchQuery] = useState("");
	const [searchResults, setSearchResults] = useState([]);
	const [noOptionsMessage, setNoOptionsMessage] = useState("");

	const handleInputChange = (event, value) => {
		// setSearchQuery(value);
		// const filteredResults = searchLinks.reduce((acc, category) => {
		//     const categoryResults = Object.entries(category).filter(([name]) =>
		//         name.toLowerCase().includes(value.toLowerCase())
		//     );
		//     return acc.concat(categoryResults);
		// }, []);
		// setSearchResults(filteredResults);
		// setNoOptionsMessage(
		//     filteredResults.length === 0 ? "No options available for the above query" : ""
		// );
	};

	const handleResultClick = (name, path) => {
		// for (const routesObj of searchLinks) {
		//     if (routesObj[name]) {
		//         const routePath = routesObj[name];
		//         navigate(routePath);
		//         setSearchQuery("");
		//         break;
		//     }
		// }
	};

	const handleSearchOnEnter = (event) => {
		if (event.key === "Enter") {
			event.preventDefault();
			if (searchResults.length === 0) {
				console.log("No options available for the above query");
				setNoOptionsMessage("No options available for the above query");
			} else {
				const [name, path] = searchResults[0];
				handleResultClick(name, path);
			}
		}
	};

	return (
		<div>
			<Autocomplete
				freeSolo
				className="searchbar"
				options={searchResults.map(([name, path]) => name)}
				inputValue={searchQuery}
				autoComplete={false}
				onInputChange={handleInputChange}
				renderOption={(props, option) => <li {...props}>{option}</li>}
				renderInput={(params) => (
					<TextField
						{...params}
						placeholder="Search for..."
						variant="outlined"
						fullWidth
						// autoComplete={false}
						onKeyPress={handleSearchOnEnter}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<button type="submit">
									<FaSearch
										className="icon"
										size={15}
										color="blue"
									/>
								</button>
							),
							style: {
								borderRadius: "19px",
								width: "200px",
							},
						}}
					/>
				)}
			/>
			{noOptionsMessage && <p>{noOptionsMessage}</p>}
		</div>
	);
};

const CalendarPannel = ({ userTasksData }) => {
	const [activeId, setActiveId] = useState("calendar");
	const [pannelOpen, setPannelOpen] = useState(false);
	const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

	const handleItemClick = (id) => {
		setActiveId(id);
		console.log(id);
	};

	const togghePannelState = () => {
		setPannelOpen(!pannelOpen);
	};

	const handleCheckboxChange = (e, value) => {
		const isChecked = e.target.checked;
		if (isChecked) {
			setSelectedCheckboxes((prevState) => [...prevState, value]);
		} else {
			setSelectedCheckboxes((prevState) =>
				prevState.filter((item) => item !== value)
			);
		}
	};

	const sum = (...args) => args.reduce((acc, val) => acc + val, 0);

	const eventsCount = userTasksData.events ? userTasksData.events.length : 0;
	const remindersCount = userTasksData.reminders
		? userTasksData.reminders.length
		: 0;
	const campaignsCount = userTasksData.campaigns
		? userTasksData.campaigns.length
		: 0;
	const tasksCount = userTasksData.tasks ? userTasksData.tasks.length : 0;
	const teamProjectsCount = userTasksData.team_projects
		? userTasksData.team_projects.length
		: 0;
	const personalProjectsCount = userTasksData.personal_projects
		? userTasksData.personal_projects.length
		: 0;
	const calendar = sum(
		eventsCount,
		remindersCount,
		campaignsCount,
		tasksCount,
		teamProjectsCount,
		personalProjectsCount
	);

    const upcoming = () => {
        let itemCount = 0;
    
        for (const property in userTasksData) {
            if (userTasksData.hasOwnProperty(property)) {
                const tasksOfType = userTasksData[property];
                tasksOfType.forEach((task) => {
                    const taskDate = new Date(task.start_date);
                    const currentDate = new Date();
                    if (
                        taskDate.getDate() >= currentDate.getDate() &&
                        taskDate.getMonth() === currentDate.getMonth() &&
                        taskDate.getFullYear() === currentDate.getFullYear()
                    ) {
                        itemCount++;
                    }
                });
            }
        }
    
        return itemCount;
    };
    
    const pending = () => {
        let pendingCount = 0;
    
        for (const property in userTasksData) {
            if (userTasksData.hasOwnProperty(property)) {
                const tasksOfType = userTasksData[property];
                tasksOfType.forEach((task) => {
                    if (task.status !== 'pending') {
                        pendingCount++;
                    }
                });
            }
        }
    
        return pendingCount;
    };
    
    const today = () => {
        let todayCount = 0;
    
        const currentDate = new Date();
    
        for (const property in userTasksData) {
            if (userTasksData.hasOwnProperty(property)) {
                const tasksOfType = userTasksData[property];
                tasksOfType.forEach((task) => {
                    const taskDate = new Date(task.start_date);
                    if (
                        taskDate.getDate() === currentDate.getDate() &&
                        taskDate.getMonth() === currentDate.getMonth() &&
                        taskDate.getFullYear() === currentDate.getFullYear()
                    ) {
                        todayCount++;
                    }
                });
            }
        }
    
        return todayCount;
    };

	return (
		<aside
			className={`aside-container h-100 ${!pannelOpen ? "closed" : ""}`}
		>
			<header className="d-flex flex-column align-items-start">
				<div className="d-flex flex-row align-items-center justify-content-between w-100">
					<h1 className={!pannelOpen ? "d-none" : ""}>Calendar</h1>
					<AiOutlineBars
						className="ml-2"
						size={18}
						onClick={togghePannelState}
						cursor={"pointer"}
					/>
				</div>
				<input
					placeholder="Search"
					className={`search-input ${!pannelOpen ? "d-none" : ""}`}
				/>
			</header>
			<section className="tasks-section">
				<h2>Tasks</h2>
				<ul>
					<li
						className={`d-flex flex-row justify-content-between ${
							activeId === "upcoming" ? "active" : ""
						}`}
						onClick={() => handleItemClick("upcoming")}
					>
						<label
							htmlFor="task1"
							className="d-flex flex-row align-items-center"
						>
							<AiOutlineDoubleRight className="icon" />{" "}
							<h6>Upcoming</h6>
						</label>
						<span style={{ opacity: upcoming <= 0 ? 0 : 1 }}>
                            {upcoming()}
                        </span>
					</li>
					<li
						className={`d-flex flex-row justify-content-between ${
							activeId === "today" ? "active" : ""
						}`}
						onClick={() => handleItemClick("today")}
					>
						<label
							htmlFor="task2"
							className="d-flex flex-row align-items-center"
						>
							<AiOutlineFolder className="icon" /> <h6>Today</h6>
						</label>
						<span style={{ opacity: today <= 0 ? 0 : 1 }}>
                            {today()}
                        </span>
					</li>
					<li
						className={`d-flex flex-row justify-content-between ${
							activeId === "calendar" ? "active" : ""
						}`}
						onClick={() => handleItemClick("calendar")}
					>
						<label
							htmlFor="task3"
							className="d-flex flex-row align-items-center"
						>
							<AiOutlineCalendar className="icon" />{" "}
							<h6>Calendar</h6>
						</label>
						<span style={{ opacity: calendar <= 0 ? 0 : 1 }}>
							{calendar}
						</span>
					</li>
					<li
						className={`d-flex flex-row justify-content-between ${
							activeId === "pending" ? "active" : ""
						}`}
						onClick={() => handleItemClick("pending")}
					>
						<label
							htmlFor="task4"
							className="d-flex flex-row align-items-center"
						>
							<AiOutlineClockCircle className="icon" />{" "}
							<h6>Pending</h6>
						</label>
						<span style={{ opacity: pending <= 0 ? 0 : 1 }}>
                            {pending()}
                        </span>
					</li>
					<li
						className={`d-flex flex-row justify-content-between ${
							activeId === "reminders" ? "active" : ""
						}`}
						onClick={() => handleItemClick("reminders")}
					>
						<label
							htmlFor="task5"
							className="d-flex flex-row align-items-center"
						>
							<AiOutlinePaperClip className="icon" />{" "}
							<h6>Sticky wall</h6>
						</label>
						<span style={{ opacity: eventsCount <= 0 ? 0 : 1 }}>
							{remindersCount}
						</span>
					</li>
					<li
						className={`d-flex flex-row justify-content-between ${
							activeId === "events" ? "active" : ""
						}`}
						onClick={() => handleItemClick("events")}
					>
						<label
							htmlFor="task6"
							className="d-flex flex-row align-items-center"
						>
							<AiOutlineEnvironment className="icon" />{" "}
							<h6>Events</h6>
						</label>
						<span style={{ opacity: eventsCount <= 0 ? 0 : 1 }}>
							{eventsCount}
						</span>
					</li>
				</ul>
			</section>
			<section className="personal-tasks-section">
				<h2>Personal</h2>
				<ul>
					<li className="justify-content-between">
						<label className="d-flex flex-row align-items-center">
							<input
								type="checkbox"
								id="task7"
								className="checkbox-input"
								onChange={(e) =>
									handleCheckboxChange(e, "Campaigns")
								}
							/>
							<h6>Campaigns</h6>
						</label>
						<span style={{ opacity: eventsCount <= 0 ? 0 : 1 }}>
							{campaignsCount}
						</span>
					</li>
					<li className="justify-content-between">
						<label className="d-flex flex-row align-items-center">
							<input
								type="checkbox"
								id="task8"
								className="checkbox-input"
								onChange={(e) =>
									handleCheckboxChange(e, "Personal Tasks")
								}
							/>
							<h6>Personal Tasks</h6>
						</label>
						<span style={{ opacity: eventsCount <= 0 ? 0 : 1 }}>
							{tasksCount}
						</span>
					</li>
					<li className="justify-content-between">
						<label className="d-flex flex-row align-items-center">
							<input
								type="checkbox"
								id="task9"
								className="checkbox-input"
								onChange={(e) =>
									handleCheckboxChange(e, "Personal Projects")
								}
							/>
							<h6>Personal Projects</h6>
						</label>
						<span style={{ opacity: eventsCount <= 0 ? 0 : 1 }}>
							{personalProjectsCount}
						</span>
					</li>
					<li className="justify-content-between">
						<label className="d-flex flex-row align-items-center">
							<input
								type="checkbox"
								id="task10"
								className="checkbox-input"
								onChange={(e) =>
									handleCheckboxChange(e, "Team Projects")
								}
							/>
							<h6>Team Projects</h6>
						</label>
						<span style={{ opacity: eventsCount <= 0 ? 0 : 1 }}>
							{teamProjectsCount}
						</span>
					</li>
				</ul>
			</section>
		</aside>
	);
};

export default CalendarPannel;
