import React, { useState, useEffect } from "react";
import { FaCloudUploadAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import api from "../api/axios";

const defaultProfile = require("../assets/images/profile.png");

const CompleteRegistration = ({ onNextStep }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [user, setUser] = useState(null);
  const [formData, setFormData] = useState();
  const allowedImageFormats = ["jpg", "jpeg", "png"];
  const navigate = useNavigate();

  useEffect(() => {
    api
      .get(`/ferxity-user/`)
      .then(({ data: userDetailData }) => {
        setUser(userDetailData.user);
        setFormData({...userDetailData.user, file: null});
        console.log("User Data => ", userDetailData.user);
      })
      .catch((error) => {
        console.error("Error fetching data", error.response);
      });
  }, []);

  const isImageFormatValid = (fileName) => {
    const extension = fileName.split(".").pop().toLowerCase();
    return allowedImageFormats.includes(extension);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file && isImageFormatValid(file.name)) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
      setFormData((params) => ({...params, "file": file}))
    } else {
      console.error(
        "Invalid image format. Please choose a JPG, JPEG, or PNG file."
      );
    }
  };

  const handleSkip = () => {
    onNextStep(1);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!selectedFile) {
      console.error("No file selected");
      return;
    }

    // setFormData((params) => ({ ...params, file: selectedFile }));

    console.log("Data", formData);

    api
      .post(`/ferxity-user/?_method=PUT`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          navigate("/dashboard");
        } else {
          console.log(
            "Something went wrong, please try again later:",
            response.data
          );
        }
      })
      .catch((error) => {
        console.error("Error uploading image:", error);
      });
  };

  return (
    <form
      className="contact-form w-100 validate-form"
      onSubmit={handleSubmit}
      encType="multipart/form-data"
    >
      <div className="position-absolute back-to-home">
        <a href="/">{"> finish up later"}</a>
      </div>
      <span className="contact-form-title pb-3">Finishing Up!</span>

      <div
        className="profile-image d-flex justify-content-center w-100"
        style={{ height: "150px" }}
      >
        {selectedImage ? (
          <img
            src={selectedImage}
            className="rounded-circle"
            style={{ height: "120px", width: "120px" }}
            alt="Profile"
          />
        ) : (
          <img
            src={defaultProfile}
            className="rounded-circle"
            style={{ height: "120px", width: "120px" }}
            alt="Profile"
          />
        )}
      </div>
      <div className="input-group d-flex justify-content-center">
        <div className="upload-file">
          <input
            type="file"
            id="upload"
            name="file"
            onChange={handleImageChange}
          />
          <label htmlFor="upload">
            <FaCloudUploadAlt color="blue" /> Choose file
          </label>
        </div>
      </div>
      <div className="d-flex dlex-row justify-content-between w-100">
        <button
          type="button"
          className="nav-sign left-btn"
          onClick={handleSkip}
        >
          Back
        </button>
        <button type="submit" className="nav-sign active">
          Continue
        </button>
      </div>
    </form>
  );
};

export default CompleteRegistration;
