import DashboardPage from "../../components/user/dashboardComponents";
import { GetUser } from "../../utils/user";

function Dashboard() {
  GetUser();

  return (
    <div>
      <DashboardPage />
    </div>
  );
}

export default Dashboard;
