import React from "react";
import Events from "../../components/user/eventspage";

const EventsPage = () => {
    return (
        <div>
            <Events />
        </div>
    )
}

export default EventsPage;