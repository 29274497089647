import React, { useState } from "react";
import { FAQs } from "../../constants/FAQs";

const SupportList = ({ title, question }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);

    const handleClick = () => {
        setIsAnimating(true);
        setTimeout(() => {
            setIsOpen(!isOpen);
            setIsAnimating(false);
        }, 300);
    };

    return (
        <div className="faq-item">
            <div className={`question ${isOpen ? "open" : ""} ${isAnimating ? "animating" : ""}`} onClick={handleClick}>
                <h3>{title}</h3>
            </div>
            <div className={`answer ${isOpen ? "open" : ""} ${isAnimating ? "animating" : ""}`}>
                <p>{question}</p>
            </div>
        </div>
    );
};

const SupportBody = () => {
    const [openQuestion, setOpenQuestion] = useState(null);

    const handleQuestionClick = (title) => {
        setOpenQuestion((prevTitle) => (prevTitle === title ? null : title));
    };

    return (
        <div className="support-body wow animation">
            <div className="container">
                <div className="row header">
                    <h2>Frequently asked questions.</h2>
                </div>
                <div className="row">
                    {/* <div className="col-lg-12 d-flex flex-column justify-content-center align-self-center">
                        <ol style={{ "--length": FAQs.length }}>
                            {FAQs.map((faq, index) => (
                                <li style={{ "--i": index }}>
                                    <SupportList
                                        title={faq.question}
                                        question={faq.answer}
                                        openQuestion={openQuestion}
                                        handleQuestionClick={handleQuestionClick}
                                    />
                                </li>
                            ))}
                        </ol>
                    </div> */}
                </div>
                <div className="row">
                    {/* <div className="col-lg-12 d-flex flex-column justify-content-center align-items-center">
                        <h3>Have any other questions</h3>
                        <p className="align-self-center">Don't hesitate to contact our support team for assistance.</p>
                        <a href="/contact-us" className="btn btn-sign">
                            Contact Us
                        </a>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export { SupportBody };
