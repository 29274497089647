import React from "react";
import Messages from "../../components/user/messageComponents";

const MessagesPage = () => {
    return (
        <div>
            <Messages />
        </div>
    )
}

export default MessagesPage;