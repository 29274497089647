import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUpload,
  faTrash,
  // faTimes
} from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import api from "../../api/axios";
import { webIcons } from "../../constants/icons";

const defaultProfile = require("../../assets/icons/user48.png");

const LoginHistory = () => {
  return null;
};

const ProfileTab = () => {
  const [user, setUser] = useState({});
  const [formData, setFormData] = useState({});
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);

  useEffect(() => {
    api
      .get(`/ferxity-user/`)
      .then(({ data: userDetailData }) => {
        setUser(userDetailData.user);
        setFormData(userDetailData.user);
        setFormData((prevState) => ({
          ...prevState,
          ["user_name"]: `${userDetailData.user.first_name} ${userDetailData.user.last_name}`,
        }));
      })
      .catch((error) => {
        console.error("Error fetching data", error.response);
      });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    try {
      const response = api.put(`/ferxity-user/${user.id}`, formData);
      console.log("User data updated successfully:", response.data);
    } catch (error) {
      console.error("Unable to update user details", error);
    }
  };

  const handleUpload = async () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = async (event) => {
      const file = event.target.files[0];

      const fileSizeInMB = file.size / (1024 * 1024);
      if (fileSizeInMB > 10) {
        alert(
          "File size exceeds the maximum limit of 10MB. Please choose a smaller file."
        );
        return;
      }

      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await api.post(`/upload/image/${user.id}`, formData);
        setUser(response.data.user);
        console.log("Profile image uploaded successfully:", response.data);
      } catch (error) {
        console.error("Unable to upload profile image", error);
      }
    };
    input.click();
  };

  const handleRemove = async () => {
    if (window.confirm("Are you sure you want to remove the profile image?")) {
      try {
        const response = await api.delete(
          `/ferxity-user/${user.id}/delete-profile-image`
        );
        setUser(response.data.user);
        console.log("Profile image removed successfully:", response.data);
      } catch (error) {
        console.error("Unable to remove profile image", error);
      }
    }
  };

  const handleCloseUploadDialog = () => {
    setOpenUploadDialog(false);
  };

  const handleCloseRemoveDialog = () => {
    setOpenRemoveDialog(false);
  };

  const handleConfirmUpload = async (file) => {
    setOpenUploadDialog(false);
  };

  const handleConfirmRemove = async () => {
    setOpenRemoveDialog(false);
  };

  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <h2>Profile</h2>
          <p>Customize your profile with your preferred options</p>
        </div>
        <div className="col-lg-12">
          <div className="content-header">
            <h3>User Profile Information</h3>
          </div>
          <div className="content-card d-flex flex-row flex-wrap">
            <div className="profile-card d-flex flex-column justify-content-around">
              <h6 className="profile-header">Your Avatar</h6>
              <div className="rounded-circle d-block align-items-center justify-content-center mb-2">
                <div className="upload-icon" onClick={handleUpload}>
                  {webIcons.picture}
                </div>
                <img
                  src={
                    user?.profile_image
                      ? `${process.env.REACT_APP_WEB_URL}/storage/${user.profile_image}`
                      : defaultProfile
                  }
                  alt="profile"
                />
              </div>
            </div>
            <div className="profile-details d-flex flex-column justify-content-around">
              <h2>
                {user?.first_name || ""}
                {user?.last_name ? " " : ""}
                {user?.last_name || ""}
              </h2>
              <h6>{user?.email || ""}</h6>
              <p>
                {user?.city || ""}
                {user?.country ? ", " : ""}
                {user?.country || ""}
              </p>
            </div>
            <div className="profile-details d-none flex-column justify-content-end">
              <div className="btn-container">
                <div className="container-contact-form-btn position-relative">
                  <button
                    className="btn upload-btn"
                    type="button"
                    onClick={handleUpload}
                  >
                    <span style={{ color: "white" }}>
                      Upload
                      <FontAwesomeIcon
                        icon={faUpload}
                        className="ml-2"
                        color="white"
                      />
                    </span>
                  </button>
                </div>
                {/* <p>Please upload images of the following types - JPEGS, PNGs (under 10mb)</p> */}
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-12">
          <div className="content-header">
            <h3>User Personal Information</h3>
          </div>

          <div className="content-card d-flex flex-row flex-wrap">
            <form onSubmit={handleSubmit}>
              <div className="d-flex flex-row flex-wrap w-100">
                <div className="d-flex flex-column wrap-input rs1-wrap-input validate-input">
                  <span className="label-input">Username</span>
                  <input
                    id="user_name"
                    name="user_name"
                    value={formData.user_name || "N/A"}
                    onChange={handleInputChange}
                  />
                  <span className="focus-input"></span>
                </div>
              </div>
              <div className="d-flex flex-row flex-wrap w-100">
                <div className="d-flex flex-column wrap-input rs1-wrap-input validate-input">
                  <span className="label-input">First Name</span>
                  <input
                    id="first_name"
                    name="first_name"
                    value={formData.first_name || "N/A"}
                    onChange={handleInputChange}
                  />
                  <span className="focus-input"></span>
                </div>
                <div className="d-flex flex-column wrap-input rs1-wrap-input validate-input">
                  <span className="label-input">Last Name</span>
                  <input
                    id="last_name"
                    name="first_name"
                    value={formData.last_name || "N/A"}
                    onChange={handleInputChange}
                  />
                  <span className="focus-input"></span>
                </div>
              </div>
              <div className="d-flex flex-row flex-wrap w-100">
                <div className="d-flex flex-column wrap-input rs1-wrap-input validate-input">
                  <span className="label-input">Email Address</span>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    value={formData.email || "N/A"}
                    onChange={handleInputChange}
                  />
                  <span className="focus-input"></span>
                </div>

                <div className="d-flex flex-column wrap-input rs1-wrap-input validate-input">
                  <span className="label-input">Phone</span>
                  <input
                    id="contact"
                    name="contact"
                    value={formData.contact || "N/A"}
                    onChange={handleInputChange}
                  />
                  <span className="focus-input"></span>
                </div>
              </div>
              <div className="d-flex flex-row flex-wrap w-100">
                <div className="d-flex flex-column wrap-input rs1-wrap-input validate-input">
                  <span className="label-input">Bio</span>
                  <input
                    id="bio"
                    name="bio"
                    type="text"
                    value={formData.bio || "N/A"}
                    onChange={handleInputChange}
                  />
                  <span className="focus-input"></span>
                </div>

                <div className="d-flex flex-column wrap-input rs1-wrap-input validate-input">
                  <span className="label-input">Title</span>
                  <input
                    id="title"
                    name="title"
                    value={formData.title || "N/A"}
                    onChange={handleInputChange}
                  />
                  <span className="focus-input"></span>
                </div>
              </div>

              <div className="submit-group mt-3 ml-5">
                <button type="submit" className="btn upload-btn">
                  Save Changes
                </button>
                {/* <button type="button" className="btn remove-btn ml-5">
                                    Cancel
                                </button> */}
              </div>
            </form>
          </div>
        </div>

        <div className="col-lg-12">
          <div className="content-header">
            <h3>User Contact Information</h3>
          </div>

          <div className="content-card d-flex flex-row flex-wrap">
            <form onSubmit={handleSubmit}>
              <div className="d-flex flex-row flex-wrap w-100">
                <div className="d-flex flex-column wrap-input rs1-wrap-input validate-input">
                  <span className="label-input">Country</span>
                  <input
                    label="Country"
                    name="state"
                    value={formData.state || "N/A"}
                    onChange={handleInputChange}
                  />
                  <span className="focus-input"></span>
                </div>

                <div className="d-flex flex-column wrap-input rs1-wrap-input validate-input">
                  <span className="label-input">City</span>
                  <input
                    id="city"
                    name="city"
                    value={formData.city || "N/A"}
                    onChange={handleInputChange}
                  />
                  <span className="focus-input"></span>
                </div>
              </div>
              <div className="d-flex flex-row flex-wrap w-100">
                <div className="d-flex flex-column wrap-input rs1-wrap-input validate-input">
                  <span className="label-input">Region</span>
                  <input
                    id="region"
                    name="region"
                    value={formData.region || "N/A"}
                    onChange={handleInputChange}
                  />
                  <span className="focus-input"></span>
                </div>
                <div className="d-flex flex-column wrap-input rs1-wrap-input validate-input">
                  <span className="label-input">Address</span>
                  <input
                    id="address"
                    name="address"
                    value={formData.address || "N/A"}
                    onChange={handleInputChange}
                  />
                  <span className="focus-input"></span>
                </div>
              </div>

              <div className="submit-group mt-3 ml-5">
                <button type="submit" className="btn upload-btn">
                  Save Changes
                </button>
                {/* <button type="button" className="btn remove-btn ml-5">
                                    Cancel
                                </button> */}
              </div>
            </form>
          </div>
        </div>

        <div className="col-lg-12">
          <LoginHistory />
        </div>
      </div>

      {/* Upload Dialog */}
      <Dialog open={openUploadDialog} onClose={handleCloseUploadDialog}>
        <DialogTitle>Upload Image</DialogTitle>
        <DialogContent>
          {/* Add your file input and upload logic here */}
          {/* e.g., <input type="file" onChange={(e) => handleConfirmUpload(e.target.files[0])} /> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUploadDialog}>Cancel</Button>
          <Button onClick={() => handleConfirmUpload(/* pass file here */)}>
            Confirm Upload
          </Button>
        </DialogActions>
      </Dialog>

      {/* Remove Dialog */}
      <Dialog open={openRemoveDialog} onClose={handleCloseRemoveDialog}>
        <DialogTitle>Remove Image</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to remove the profile image?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRemoveDialog}>Cancel</Button>
          <Button onClick={handleConfirmRemove}>Confirm Removal</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const TermsConditionsTab = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <h2>Terms & Conditions</h2>
        </div>
        <div className="col-lg-12"></div>
      </div>
    </div>
  );
};

const NotificationsTab = () => {
  const [allowAll, setAllowAll] = useState(true);
  const [fromCoworkers, setFromCoworkers] = useState(false);
  const [fromTeamMembers, setFromTeamMembers] = useState(false);
  const [fromCompanyNewsletters, setFromCompanyNewsletters] = useState(false);
  const [receiveViaEmail, setReceiveViaEmail] = useState(false);

  const handleSwitchChange = (option) => {
    switch (option) {
      case "allowAll":
        setAllowAll(!allowAll);
        break;
      case "fromCoworkers":
        setFromCoworkers(!fromCoworkers);
        break;
      case "fromTeamMembers":
        setFromTeamMembers(!fromTeamMembers);
        break;
      case "fromCompanyNewsletters":
        setFromCompanyNewsletters(!fromCompanyNewsletters);
        break;
      case "receiveViaEmail":
        setReceiveViaEmail(!receiveViaEmail);
        break;
      default:
        break;
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <h2>Notifications</h2>
          <p>Customize the manner in which your Notifications are handled.</p>
        </div>
        <div className="col-lg-12">
          <div className="form-check">
            <label className="form-check-label">
              Allow all notifications
              <input
                type="checkbox"
                className="form-check-input"
                checked={allowAll}
                onChange={() => handleSwitchChange("allowAll")}
              />
            </label>
          </div>

          <div className="form-check">
            <label className="form-check-label">
              From coworkers
              <input
                type="checkbox"
                className="form-check-input"
                checked={fromCoworkers}
                onChange={() => handleSwitchChange("fromCoworkers")}
              />
            </label>
          </div>

          <div className="form-check">
            <label className="form-check-label">
              From team members
              <input
                type="checkbox"
                className="form-check-input"
                checked={fromTeamMembers}
                onChange={() => handleSwitchChange("fromTeamMembers")}
              />
            </label>
          </div>

          <div className="form-check">
            <label className="form-check-label">
              From company newsletters
              <input
                type="checkbox"
                className="form-check-input"
                checked={fromCompanyNewsletters}
                onChange={() => handleSwitchChange("fromCompanyNewsletters")}
              />
            </label>
          </div>

          <div className="form-check">
            <label className="form-check-label">
              Receive via email
              <input
                type="checkbox"
                className="form-check-input"
                checked={receiveViaEmail}
                onChange={() => handleSwitchChange("receiveViaEmail")}
              />
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

const PrivacySecurityTab = () => {
  return (
    <div className="container">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h2>Privacy & Security</h2>
            <p>Optimize the manner in which others view your data</p>
          </div>
          <div className="col-lg-12"></div>
        </div>
      </div>
    </div>
  );
};

export { ProfileTab, TermsConditionsTab, NotificationsTab, PrivacySecurityTab };
