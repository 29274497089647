import React, { useState } from "react";
import CreateTaskButton from "./createTask";
import { FaEllipsisV, FaRegWindowClose } from "react-icons/fa";

const CustomDialog = ({ isOpen, onClose, task }) => {
	if (!isOpen) return null;

	return (
		<div className="dialog-overlay">
			<div className="dialog-background" onClick={onClose}></div>
			<div className="dialog-content">
				<div className="d-flex flex-row justify-content-between">
					<strong>{task.type}</strong>
					<FaRegWindowClose onClick={onClose}>x</FaRegWindowClose>
				</div>
				<div className="row dialog-details mt-3 mb-3">
					{Object.entries(task).map(([key, value]) => {
						if (
							key === "id" ||
							key === "user_id" ||
							key === "organization_id" ||
							key === "updated_at" ||
							key === "type"
						)
							return null;
						return (
							<div
								key={key}
								className={`${
									key === "description"
										? "col-lg-12"
										: "col-lg-6"
								} d-flex flex-column mb-3`}
							>
								<strong className="mb-1">{key}: </strong>
								<div>{value}</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

const PriorityCards = ({ task, taskType, taskTitle, taskDescription }) => {
	const [isExpanded, setExpanded] = useState(false);
	const [isDialogOpen, setDialogOpen] = useState(false);

	const handleCardClick = () => {
		setExpanded(!isExpanded);
	};

	const handleIconClick = (e) => {
		e.stopPropagation();
		setDialogOpen(true);
	};

	const handleCloseDialog = () => {
		setDialogOpen(false);
	};

	return (
		<div>
			<div
				className={`priority-card ${taskType}`}
				onClick={handleCardClick}
			>
				<div className="card-header">
					<div
						className="card-title"
						style={{ maxWidth: "90%", overflowX: "hidden" }}
					>
						{taskTitle}
					</div>
					<div className="mt-2">
						<FaEllipsisV
							className="ai-icon"
							size={13}
							onClick={handleIconClick}
						/>
					</div>
				</div>
				<div className={`card-details ${isExpanded ? "show" : ""}`}>
					<p>{taskDescription}</p>
				</div>
			</div>
			<CustomDialog
				isOpen={isDialogOpen}
				onClose={handleCloseDialog}
				task={task}
			/>
		</div>
	);
};

const CalendarWeekView = ({ userTasksData, currentWeekDays, hours }) => {
	return (
		<div className="calendar-weekly">
			<div className="row header-row">
				<div className="cell time-col"></div>
				{currentWeekDays.map((day, index) => (
					<div key={index} className="cell header-cell">
						<div className="d-flex flex-row justify-content-center w-100">
							<h6>
								{day.toLocaleDateString("en-US", {
									weekday: "short",
								})}{" "}
							</h6>
							<h6>{day.getDate()}</h6>
						</div>
					</div>
				))}
			</div>
			{hours.map((hour, hourIndex) => (
				<div key={hourIndex} className="row calendar-row">
					<div className="cell time-col">
						<p>{hour}</p>
					</div>
					{currentWeekDays.map((day, dayIndex) => {
						const tasksAtHour = [];
						for (const taskTypeKey in userTasksData) {
							if (userTasksData.hasOwnProperty(taskTypeKey)) {
								const tasksOfType = userTasksData[taskTypeKey];
								tasksOfType.forEach((task) => {
									const taskDate = new Date(task.start_date);
									const taskHour = taskDate.getHours();
									const taskDay =
										taskDate.toLocaleDateString("en-US");

									const adjustedHour = taskHour % 24;
									task["type"] = taskTypeKey;

									if (
										taskDay ===
											day.toLocaleDateString("en-US") &&
										adjustedHour === hourIndex
									) {
										tasksAtHour.push(task);
									}
								});
							}
						}

						return (
							<div
								key={dayIndex}
								className="cell"
								style={{ maxWidth: "140px" }}
							>
								{tasksAtHour.map((item, itemIndex) => (
									<div key={itemIndex}>
										<PriorityCards
											task={item}
											taskType={item.type}
											taskTitle={item.name}
											taskDescription={item.description}
										/>
									</div>
								))}
							</div>
						);
					})}
				</div>
			))}
		</div>
	);
};
const CalendarMonthlyView = ({ currentMonthDates, userTasksData }) => {
	const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

	return (
		<div className="calendar-monthly">
			<div className="row header-row">
				{daysOfWeek.map((day, index) => (
					<div key={index} className="cell header-cell">
						<div className="d-flex flex-row justify-content-center w-100">
							<h6>{day}</h6>
						</div>
					</div>
				))}
			</div>

			<div className="row calendar-row">
				{currentMonthDates.map((date, index) => {
					const tasksAtDate = [];
					for (const taskTypeKey in userTasksData) {
						if (userTasksData.hasOwnProperty(taskTypeKey)) {
							const tasksOfType = userTasksData[taskTypeKey];
							tasksOfType.forEach((task) => {
								const taskDate = new Date(task.start_date);
								if (
									taskDate.getDate() === date.getDate() &&
									taskDate.getMonth() === date.getMonth() &&
									taskDate.getFullYear() ===
										date.getFullYear()
								) {
									tasksAtDate.push(task);
								}
							});
						}
					}

					return (
						<div key={index} className="cell">
							<p>{date.getDate()}</p>
							{tasksAtDate.map((item, itemIndex) => (
								<div key={itemIndex}>
									<PriorityCards
										taskId={item.id}
										taskType={item.type}
										taskTitle={item.name}
										taskDescription={item.description}
									/>
								</div>
							))}
						</div>
					);
				})}
			</div>
		</div>
	);
};

const Calendar = ({
	currentDate,
	viewMode,
	userTasksData,
	setUserTasksData,
}) => {
	const getCurrentMonthDates = () => {
		const year = currentDate.getFullYear();
		const month = currentDate.getMonth();
		const firstDayOfMonth = new Date(year, month, 1);
		const lastDayOfMonth = new Date(year, month + 1, 0);
		const monthDates = [];

		for (
			let d = new Date(firstDayOfMonth);
			d <= lastDayOfMonth;
			d.setDate(d.getDate() + 1)
		) {
			monthDates.push(new Date(d));
		}

		const precedingMonth = new Date(firstDayOfMonth);
		precedingMonth.setDate(0);

		const succeedingMonth = new Date(lastDayOfMonth);
		succeedingMonth.setDate(lastDayOfMonth.getDate() + 1);

		while (monthDates[0].getDay() !== 1) {
			monthDates.unshift(new Date(precedingMonth));
			precedingMonth.setDate(precedingMonth.getDate() - 1);
		}

		while (monthDates[monthDates.length - 1].getDay() !== 0) {
			monthDates.push(new Date(succeedingMonth));
			succeedingMonth.setDate(succeedingMonth.getDate() + 1);
		}

		return monthDates;
	};

	const getCurrentWeekDays = () => {
		const days = [];
		const currentDayOfWeek = currentDate.getDay();
		const difference = currentDayOfWeek - 1;

		const startOfWeek = new Date(currentDate);
		startOfWeek.setDate(currentDate.getDate() - difference);

		for (let i = 0; i < 7; i++) {
			const day = new Date(startOfWeek);
			day.setDate(startOfWeek.getDate() + i);
			days.push(day);
		}

		return days;
	};

	const currentWeekDays = getCurrentWeekDays();
	const monthDates = getCurrentMonthDates();
	const hours = Array.from({ length: 24 }, (_, i) => {
		const hour = i + 5;
		return `${hour % 12 === 0 ? 12 : hour % 12} ${hour < 12 ? "AM" : "PM"}`;
	});

	return (
		<div className="calendar-section">
			<div className="container">
				<div className="table">
					{viewMode === "week" ? (
						<CalendarWeekView
							hours={hours}
							currentWeekDays={currentWeekDays}
							userTasksData={userTasksData}
						/>
					) : (
						<CalendarMonthlyView
							currentMonthDates={monthDates}
							userTasksData={userTasksData}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

const PlannerHeader = () => {
	return (
		<div className="planner-header d-flex align-items-end mb-3">
			<div className="d-flex flex-row justify-content-center">
				<CreateTaskButton />
			</div>
		</div>
	);
};

export { PlannerHeader, Calendar };
