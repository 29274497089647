import React, { useEffect, useState } from "react";

import Paginate from "../paginateBtns";
import api from "../../../api/axios";
import Preloader from "../../preloader";
import Table from "../table";
import CreateAdvertButton from "./createAdvertButton";


const ResutltsTable = ({ advertisements }) => {
	const route = "results";
	const columnNames = [
		"first_name",
		"last_name",
		"course",
		"Year",
		"Percentage",
	];

	const columnValues = [
		"first_name",
		"last_name",
		"course",
		"Year",
		"Percentage",
	];

	const args = {
		columnNames: columnNames,
		columnValues: columnValues,
		route: route,
	};

	return (
		<div>
			{advertisements ? (
				<div className="row">
					<Table {...args} data={advertisements.advertisements} />
				</div>
			) : (
				<p>You have currently not registered an organization.</p>
			)}
		</div>
	);
};

const Results = () => {
	const [user, setUser] = useState();
	const [view, setView] = useState({});
	const [load, setLoad] = useState(true);

	useEffect(() => {
		api.get("/user")
			.then(({ data: userData }) => {
				setUser(userData.id);
			})
			.catch((error) => {
				console.error("Error fetching data", error.response);
			});
	}, []);

	useEffect(() => {
		const handleDataFetch = async () => {
			try {
				const response = await api.get(
					`/users-data/advertisements/${user}`
				);
				setView(response.data.data[0]);
				console.log("Ads", response.data.data[0]);
			} catch (error) {
				console.error("Error fetching data", error.response);
			} finally {
				setLoad(false);
			}
		};

		if (user) {
			handleDataFetch();
		}
	}, [user]);

	const onPageChange = () => {
		console.log("Get next items");
	};

	return load ? (
		<Preloader />
	) : (
		<div className="campaigns-section">
			<div className="container">
				<div className="d-flex flex-row">
					<h4 className="mr-1">Academic</h4>
					<h4 className="mr-1">{">"}</h4>
					<h4 className="mr-1">
						<b>Results</b>
					</h4>
				</div>
				<div className="row">
					<div
						className="col-lg-12 d-flex flex-row justify-content-between align-items-center"
						style={{ margin: "15px 0px 15px 0px" }}
					>
						<h2 className="page-titles">Results</h2>
						<div>
							{/* <CsvUploader
								columns={advertisements}
								columnLabels={advertisementLabels}
								user={user}
								route={"advertisements"}
							/> */}
							<CreateAdvertButton userId={user} />
						</div>
					</div>
					<div className="col-lg-12">
						<ResutltsTable advertisements={view} />
					</div>
				</div>
				<div className="row float-right mb-4">
					<Paginate
						totalPages={1}
						currentPage={1}
						onPageChange={onPageChange}
					/>
				</div>
			</div>
		</div>
	);
};

export default Results;
