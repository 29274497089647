import React, { useState, useEffect } from "react";

import api from "../../../api/axios";
import { useStateContext } from "../../../context/ContextProvider";
import EditCompanyInfo from "./companysection";

const defaultOrganization = require("../../../assets/icons/company.png");

const LoginHistory = () => {
	return null;
};

const logoStyle = {
	container: {
		padding: "10px",
		borderRadius: "50%",
		border: "1px solid #ccc",
		height: "100px",
		width: "100px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	img: {
		borderRadius: "5%",
		border: "0",
		height: "auto",
		width: "85%",
	},
};

const OrganizationTab1 = () => {
	const [companyInformation, setCompanyInformation] = useState({});
	const { user, setUser } = useStateContext();
	const [userData, setUserData] = useState(null);

	useEffect(() => {
		api.get("/user")
			.then((userData) => {
				setUser(userData.data);
			})
			.catch((error) => {
				console.error("Error fetching data", error.response);
			});
	}, [setUser]);

	useEffect(() => {
		const fetchUserAndCompanies = async () => {
			try {
				const response = await api.get(
					`/organizations/user-organization/${user.id}`
				);
				setUserData(response.data);
			} catch (error) {
				console.error(
					"Error fetching user and organizations",
					error.response
				);
			}
		};

		if (user && user.id) {
			fetchUserAndCompanies();
		}
	}, [user, setUserData]);

	useEffect(() => {
		if (userData && userData.organizations) {
			setCompanyInformation(userData.organizations[0]);
		}
	}, [userData, setCompanyInformation]);

	return (
		<div>
			<div className="row">
				<div className="col-lg-12">
					<h2>Organziation</h2>
					<p>View and Modify your company details.</p>
				</div>

				<div className="col-lg-12">
					<div className="content-header">
						<h3>Company Profile</h3>
					</div>
					<div className="content-card d-flex flex-row flex-wrap">
						<div className="profile-card d-flex flex-column justify-content-around">
							<h6 className="profile-header">Company Logo</h6>
							<div
								style={
									companyInformation?.logo_path
										? {}
										: logoStyle.container
								}
							>
								<img
									style={
										companyInformation?.logo_path
											? {}
											: logoStyle.img
									}
									src={
										companyInformation?.logo_path
											? `${process.env.REACT_APP_WEB_URL}/storage/${user.profile_image}`
											: defaultOrganization
									}
									alt="profile"
								/>
							</div>
						</div>
						<div className="profile-details d-flex flex-column justify-content-around">
							<h2>{companyInformation?.name || ""}</h2>
							<h6>{companyInformation?.slogan || ""}</h6>
							<p>{companyInformation?.email || ""}</p>
						</div>
						<div className="profile-details w-20 position-relative">
							<EditCompanyInfo
								organization={companyInformation}
								userId={user.id}
							/>
						</div>
					</div>
				</div>

				<OrganizationForms
					companyInformation={companyInformation}
					setCompanyInformation={setCompanyInformation}
				/>
			</div>
		</div>
	);
};

const OrganizationForms = ({ companyInformation, setCompanyInformation }) => {
	const handleSubmit = async () => {
		const requiredFields = [
			"name",
			"slogan",
			"industry",
			"contact",
			"email",
		];

		const hasEmptyFields = requiredFields.some(
			(field) => !companyInformation[field]
		);

		if (hasEmptyFields) {
			setCompanyInformation((prevData) => ({
				...prevData,
				errorMessage: "Please fill all fields.",
			}));
			return;
		}
		try {
			const response = await api.put(
				`/organization/${companyInformation.organization_id}`,
				{
					...companyInformation,
				}
			);

			console.log(
				"Organization data updated successfully:",
				response.data
			);
		} catch (error) {
			console.error("Unable to data updated organization", error);
		}
	};

	const handleInputChange = (field, value) => {
		setCompanyInformation((prevData) => ({ ...prevData, [field]: value }));
	};

	return (
		<div>
			<div className="col-lg-12">
				<div className="content-header">
					<h3>Company Information</h3>
				</div>

				<div className="content-card d-flex flex-row flex-wrap">
					<form onSubmit={handleSubmit}>
						<div className="d-flex flex-row flex-wrap w-100">
							<div className="d-flex flex-column wrap-input rs1-wrap-input validate-input">
								<span className="label-input">
									Company Name
								</span>
								<input
									id="user_name"
									name="user_name"
									value={companyInformation.name || "N/A"}
									onChange={handleInputChange}
								/>
								<span className="focus-input"></span>
							</div>
						</div>
						<div className="d-flex flex-row flex-wrap w-100">
							<div className="d-flex flex-column wrap-input rs1-wrap-input validate-input">
								<span className="label-input">Slogan</span>
								<input
									id="bio"
									name="bio"
									type="text"
									value={companyInformation.slogan || "N/A"}
									onChange={handleInputChange}
								/>
								<span className="focus-input"></span>
							</div>
							<div className="d-flex flex-column wrap-input rs1-wrap-input validate-input">
								<span className="label-input">Size</span>
								<input
									id="last_name"
									name="first_name"
									value={companyInformation.size || "N/A"}
									onChange={handleInputChange}
								/>
								<span className="focus-input"></span>
							</div>
						</div>
						<div className="d-flex flex-row flex-wrap w-100">
							<div className="d-flex flex-column wrap-input rs1-wrap-input validate-input">
								<span className="label-input">
									Email Address
								</span>
								<input
									id="email"
									name="email"
									type="email"
									value={companyInformation.email || "N/A"}
									onChange={handleInputChange}
								/>
								<span className="focus-input"></span>
							</div>

							<div className="d-flex flex-column wrap-input rs1-wrap-input validate-input">
								<span className="label-input">Phone</span>
								<input
									id="contact"
									name="contact"
									value={companyInformation.contact || "N/A"}
									onChange={handleInputChange}
								/>
								<span className="focus-input"></span>
							</div>
						</div>
						<div className="d-flex flex-row flex-wrap w-100">
							<div className="d-flex flex-column wrap-input rs1-wrap-input validate-input">
								<span className="label-input">Slogan</span>
								<input
									id="bio"
									name="bio"
									type="text"
									value={companyInformation.slogan || "N/A"}
									onChange={handleInputChange}
								/>
								<span className="focus-input"></span>
							</div>

							<div className="d-flex flex-column wrap-input rs1-wrap-input validate-input">
								<span className="label-input">industry</span>
								<input
									id="title"
									name="title"
									value={companyInformation.industry || "N/A"}
									onChange={handleInputChange}
								/>
								<span className="focus-input"></span>
							</div>
						</div>

						<div className="submit-group mt-3 ml-5">
							<button type="submit" className="btn upload-btn">
								Save Changes
							</button>
						</div>
					</form>
				</div>
			</div>

			<div className="col-lg-12">
				<div className="content-header">
					<h3>Address Information</h3>
				</div>

				<div className="content-card d-flex flex-row flex-wrap">
					<form onSubmit={handleSubmit}>
						<div className="d-flex flex-row flex-wrap w-100">
							<div className="d-flex flex-column wrap-input rs1-wrap-input validate-input">
								<span className="label-input">Country</span>
								<input
									label="Country"
									name="state"
									value={companyInformation.state || "N/A"}
									onChange={handleInputChange}
								/>
								<span className="focus-input"></span>
							</div>

							<div className="d-flex flex-column wrap-input rs1-wrap-input validate-input">
								<span className="label-input">City</span>
								<input
									id="city"
									name="city"
									value={companyInformation.city || "N/A"}
									onChange={handleInputChange}
								/>
								<span className="focus-input"></span>
							</div>
						</div>
						<div className="d-flex flex-row flex-wrap w-100">
							<div className="d-flex flex-column wrap-input rs1-wrap-input validate-input">
								<span className="label-input">Timezone</span>
								<input
									id="region"
									name="region"
									value={companyInformation.timezone || "N/A"}
									onChange={handleInputChange}
								/>
								<span className="focus-input"></span>
							</div>
							<div className="d-flex flex-column wrap-input rs1-wrap-input validate-input">
								<span className="label-input">Postal Code</span>
								<input
									id="address"
									name="address"
									value={
										companyInformation.postal_code || "N/A"
									}
									onChange={handleInputChange}
								/>
								<span className="focus-input"></span>
							</div>
						</div>

						<div className="submit-group mt-3 ml-5">
							<button type="submit" className="btn upload-btn">
								Save Changes
							</button>
						</div>
					</form>
				</div>
			</div>

			<div className="col-lg-12">
				<div className="content-header">
					<h3>Miscallenous</h3>
				</div>

				<div className="content-card d-flex flex-row flex-wrap">
					<form onSubmit={handleSubmit}>
						<div className="d-flex flex-row flex-wrap w-100">
							<div className="d-flex flex-column wrap-input rs1-wrap-input validate-input">
								<span className="label-input">Website</span>
								<input
									label="Country"
									name="state"
									value={companyInformation.website || "N/A"}
									onChange={handleInputChange}
								/>
								<span className="focus-input"></span>
							</div>

							<div className="d-flex flex-column wrap-input rs1-wrap-input validate-input">
								<span className="label-input">Started In?</span>
								<input
									id="city"
									name="city"
									value={
										companyInformation.date_added || "N/A"
									}
									onChange={handleInputChange}
								/>
								<span className="focus-input"></span>
							</div>
						</div>
						<div className="d-flex flex-row flex-wrap w-100">
							<div className="d-flex flex-column wrap-input rs1-wrap-input validate-input">
								<span className="label-input">Revenue</span>
								<input
									label="Revenue"
									name="state"
									value={
										` Ksh. ${companyInformation.revenue}` ||
										"N/A"
									}
									onChange={handleInputChange}
								/>
								<span className="focus-input"></span>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};
export default OrganizationTab1;
