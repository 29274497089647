import React from "react";
import Login from "../../components/default/loginForm";

const LoginPage = () => {
    return (
        <>
            <Login />
        </>
    );
}

export default LoginPage;