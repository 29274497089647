// src/components/FormFields.js
import React from 'react';
import { Autocomplete, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

export const AutocompleteField = ({ label, options, getOptionLabel, valueFinder, onChange }) => (
    <Autocomplete
        fullWidth
        options={options}
        getOptionLabel={getOptionLabel}
        value={options.find(valueFinder) || null}
        onChange={(e, newValue) => onChange(newValue)}
        renderInput={(params) => <TextField {...params} label={label} />}
    />
);

export const TextFieldComponent = ({ label, field, value, onChange, maxRows = 1 }) => (
    <TextField
        rows={maxRows}
        label={label}
        fullWidth
        value={value}
        onChange={(e) => onChange(field, e.target.value)}
    />
);

export const NumberField = ({ label, field, value, onChange }) => (
    <TextField
        label={label}
        fullWidth
        type="number"
        value={value}
        onChange={(e) => onChange(field, e.target.value)}
    />
);

export const SelectField = ({ label, value, options, onChange }) => (
    <FormControl fullWidth>
        <InputLabel>{label}</InputLabel>
        <Select
            value={value}
            onChange={(e) => onChange(e.target.value)}
        >
            {options.map(option => (
                <MenuItem key={option} value={option}>
                    {option}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
);
