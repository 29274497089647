import React, { useState, useEffect } from "react";
import api from "../../api/axios";
import Preloader from "../preloader";

const ActivityOverview = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12">{/* Content of TaskViewer */}</div>
      </div>
    </div>
  );
};

const EmployeeActivity = () => {
  return <div></div>;
};

const DepartmentActivity = () => {
  return (
    <div className="row">
      <div className="col-lg-6"></div>
      <div className="col-lg-6"></div>
    </div>
  );
};

const DashboardPage = () => {
  const [userData, setUserData] = useState({});
  const [greeting, setGreeting] = useState("");
  const [load, setLoad] = useState(true);

  useEffect(() => {
    api
      .get(`/ferxity-user`)
      .then(({ data: userDetailData }) => {
        setUserData(userDetailData.user);
        setGreeting(getGreeting());
      })
      .catch((error) => {
        console.error("Error fetching data", error.response);
      })
      .finally(() => {
        setLoad(false);
      });
  }, []);

  const getGreeting = () => {
    const currentHour = new Date().getHours();
    if (currentHour >= 5 && currentHour < 12) {
      return "Good morning";
    } else if (currentHour >= 12 && currentHour < 18) {
      return "Good afternoon";
    } else {
      return "Good evening";
    }
  };

  return (
    <>
      {load ? (
        <Preloader />
      ) : (
        <div className="dashboard-section">
          <div className="container">
            <div className="row">
              <h2>
                {greeting} {userData.first_name}
              </h2>
              <p>Welcome to your Dashboard!</p>
            </div>
            <div className="row">
              <ActivityOverview />
            </div>
            <div className="row"></div>
            <div className="row"></div>
          </div>
        </div>
      )}
    </>
  );
};

export default DashboardPage;
