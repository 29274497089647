import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { routes } from "../../constants/routes";

const appLogo = require("../../assets/images/favicon.png");

const NavBar = () => {
    const location = useLocation();
    const pathname = location.pathname;
    const [scrolled, setScrolled] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const viewportHeight = window.innerHeight;
            const scrollThreshold = viewportHeight / 2;

            setScrolled(scrollPosition > scrollThreshold);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const headerStyle = {
        visibility: "visible",
        WebkitAnimationDuration: "0.75s",
        MozAnimationDuration: "0.75s",
        animationDuration: "0.75s",
        WebkitAnimationDelay: ".2s",
        MozAnimationDelay: ".2s",
        animationDelay: ".2s",
        height: "80px",
        background: scrolled ? "white" : "transparent",
        transition: "all 0.5s ease-in-out",
    };

    return (
        <header
            className="header-area header-sticky wow slideInDown animated"
            data-wow-duration="0.75s"
            data-wow-delay=".5s"
            style={{ ...headerStyle, position: "sticky", top: "0" }}
        >
            <div className="w-100 h-100 display-grid align-items-center">
                <div className="row align-items-center">
                    <div className="col-lg-12">
                        <nav className={isOpen ? "main-nav menu-bg" : "main-nav"}>
                            <a href={routes.Home} className="app-logo d-flex align-items-center">
                                <img src={appLogo} alt="cremimic logo" />
                            </a>
                            <div className={isOpen ? "col-7 menu-open" : "col-7 menu-items"}>
                                <ul
                                    className={
                                        pathname === "/about-us" && !scrolled
                                            ? "nav nav-light text-center"
                                            : "nav nav-light text-center"
                                    }
                                >
                                    <li>
                                        <a href="" className={pathname === "/" ? "active" : ""} data-scroll-nav="0">
                                            Home
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className={pathname === "/about-us" ? "active" : ""}
                                            data-scroll-nav="1">
                                            About
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className={pathname === "/services" ? "active" : ""} data-scroll-nav="2">
                                            Services
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className={pathname === "/contact-us" ? "active" : ""} data-scroll-nav="3">
                                            Contact
                                        </a>
                                    </li>
                                    <li className={isOpen ? "" : "nav-hidden"}>
                                        <a href={routes.Signup}>Sign Up</a>
                                    </li>
                                    <li className={isOpen ? "" : "nav-hidden"}>
                                        <a href={routes.Login}>Sign In</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-3 text-right">
                                <div className="menu-trigger">
                                    <button
                                        className={`hamburger hamburger--collapse ${isOpen && "is-active"}`}
                                        type="button"
                                        onClick={toggleMenu}
                                    >
                                        <span className="hamburger-box">
                                            <span className="hamburger-inner"></span>
                                        </span>
                                    </button>
                                </div>
                                <div className="button-container">
                                    <a href={routes.Login} className="nav-sign">
                                        Sign In
                                    </a>
                                    <a href={routes.Signup} className="nav-sign active">
                                        Sign Up
                                    </a>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default NavBar;
