import React from "react";
import Signup from "../../components/default/signupForm";

const SignupPage = () => {
	return (
		<>
            <Signup />
		</>
	);
};

export default SignupPage;
