import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import api from "../../../api/axios";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, Button, Typography, Paper } from '@mui/material';
import { InputLabel, Select, MenuItem } from "@mui/material";

const AccessControlHeader = ({ companyInformation, fetchData }) => {
    const buttonStyle = {
        marginTop: "10px",
        border: "1px solid lightblue",
        width: "100%",
        minWidth: '200px',
        borderRadius: "12px",
    };

    const handleOnSubmit = async (e) => {
        e.preventDefault();

        const tokenData = {
            organization_id: companyInformation.organization_id,
            permission: e.target.elements.permission.value,
        };

        try {
            const response = await api.post("/access-tokens/", tokenData);
            console.log("New token has been generated successfully:", response.data);
            fetchData();
        } catch (error) {
            console.error("Error submitting organization data:", error);
        }
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-6" style={{ margin: "15px 0px" }}>
                    <h2>Access Control</h2>
                    <p>
                        Access tokens are used to register new users with different roles into the
                        company. You can generate a new access token by clicking the button below.
                    </p>
                </div>
                <div className="col-lg-6" style={{ paddingRight: "20px"}}>
                    <form
                        onSubmit={handleOnSubmit}
                        className="d-flex flex-column justify-content-center align-items-center"
                    >
                        <div className="input-container flex-wrap" style={{width: '70vw', maxWidth: '35S0px'}}>
                            <InputLabel id="permission" style={{ marginBottom: "8px" }}>
                                Choose Permission
                            </InputLabel>
                            <Select
                                labelId="permission"
                                name="permission"
                                fullWidth
                                defaultValue="employee"
                                style={{ borderRadius: "12px" }}
                            >
                                <MenuItem value="employee">Employee</MenuItem>
                                <MenuItem value="manager">Manager</MenuItem>
                                <MenuItem value="vice_dmin">Vice Admin</MenuItem>
                                <MenuItem value="admin">Admin</MenuItem>
                            </Select>
                            <Button type="submit" style={buttonStyle}>
                                Generate Access Token
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

const AccessControlTable = ({ registrationTokens, handleTokenClose, handleCheckboxChange, selectedTokenIds }) => {
    const formatDate = (dateTimeString) => {
        const options = {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: true,
        };
      
        const formattedDate = new Date(dateTimeString).toLocaleDateString(undefined, options);
        return formattedDate;
      };

    return (
        <div>
            {registrationTokens.map((organizationData) => (
                <div key={organizationData.organization}>
                    <Typography variant="h6" component="div" mt={3} mb={3}>
                        {organizationData.organization} Tokens
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>&nbsp;</TableCell>
                                    <TableCell>Access Token</TableCell>
                                    <TableCell>Organization</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Expiry Date</TableCell>
                                    <TableCell>Permissions</TableCell>
                                    <TableCell>&nbsp;</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {organizationData.tokens.map((token) => (
                                    <TableRow key={token.id} className="alert" role="alert">
                                        <TableCell>
                                            <Checkbox
                                                checked={selectedTokenIds.includes(token.id)}
                                                onChange={() => handleCheckboxChange(token.id)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            {token && token.access_token ? token.access_token : "N/A"}
                                        </TableCell>
                                        <TableCell>
                                            {token && token.organization ? token.organization : "N/A"}
                                        </TableCell>
                                        <TableCell>
                                            {token && token.status
                                                ? token.status 
                                                : "N/A"}
                                        </TableCell>
                                        <TableCell>
                                            {token && token.expiry_date ? formatDate(token.expiry_date) : "N/A"}
                                        </TableCell>
                                        <TableCell>{token && token.permission ? token.permission : "N/A"}</TableCell>
                                        <TableCell>
                                            <Button
                                                variant="outlined"
                                                color="secondary"
                                                onClick={() => handleTokenClose(token.id)}
                                            >
                                                <FontAwesomeIcon icon={faTimes} />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            ))}
        </div>
    );
};

const AccessControl = () => {
    const [registrationTokens, setRegistrationTokens] = useState([]);
    const [companyInformation, setCompanyInformation] = useState({});
    const [selectedTokenIds, setSelectedTokenIds] = useState([]);
    const [pagination, setPagination] = useState({});
    const [userData, setUserData] = useState(null);
    const [user, setUser] = useState(null);

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const response = await api.get("/user");
                setUser(response.data);
            } catch (error) {
                console.error("Error fetching data", error.response);
            }
        };

        fetchUserDetails();
    }, []);

    useEffect(() => {
        const fetchUserAndCompanies = async () => {
            try {
                const response = await api.get(`/organizations/user-organization/${user.id}`);
                setUserData(response.data);
            } catch (error) {
                console.error("Error fetching user and organizations", error.response);
            }
        };

        if (user && user.id) {
            fetchUserAndCompanies();
        }
    }, [user]);

    useEffect(() => {
        const fetchData = async () => {
            if (userData && userData.organizations) {
                setCompanyInformation(userData.organizations[0]);
    
                try {
                    const response = await api.get(`/access-control/tokens/${user.id}`);
                    setRegistrationTokens(response.data.data);
                    setPagination(response.data.pagination);
                    console.log("tokens", response.data.data);
                } catch (error) {
                    console.error("Error fetching data", error.response);
                }
            }
        };

        fetchData();
    }, [userData]);

    const fetchTokens = () => {
        if (userData && userData.organizations) {
            try {
                const response = api.get(`/access-control/tokens/${user.id}`);
                setRegistrationTokens(response.data.data);
                setPagination(response.data.pagination);
                console.log("tokens", response.data.data);
            } catch (error) {
                console.error("Error fetching data", error);
            }
        }
    };

    const handleTokenClose = async (tokenId) => {
        try {
            await api.delete(`/access-control/tokens/${tokenId}`);
            const updatedTokens = registrationTokens.filter((token) => token.id !== tokenId);
            setRegistrationTokens(updatedTokens);
            console.error(updatedTokens);
        } catch (error) {
            console.error("Error deleting token", error.response);
        }
    };

    const handleCheckboxChange = (tokenId) => {
        const updatedSelectedTokenIds = [...selectedTokenIds];

        if (selectedTokenIds.includes(tokenId)) {
            updatedSelectedTokenIds.splice(updatedSelectedTokenIds.indexOf(tokenId), 1);
        } else {
            updatedSelectedTokenIds.push(tokenId);
        }

        setSelectedTokenIds(updatedSelectedTokenIds);
    };

    const handlePageChange = async (url) => {
        try {
            const response = await api.get(url);
            setRegistrationTokens(response.data.data);
            setPagination(response.data.pagination);
        } catch (error) {
            console.error("Error fetching page", error.response);
        }
    };

    return (
        <div className="container access-section">
            <div className="row">
                <div className="col-lg-12 access-header">
                    <AccessControlHeader companyInformation={companyInformation} fetchData={fetchTokens} />
                </div>
                <div className="col-md-12">
                    <div className="table-wrap">
                        <AccessControlTable
                            registrationTokens={registrationTokens}
                            handleTokenClose={handleTokenClose}
                            selectedTokenIds={selectedTokenIds}
                            handleCheckboxChange={handleCheckboxChange}
                            />
                    </div>

                    <div className="pagination mt-3 d-flex justify-content-center">
                        {pagination.prev_page_url && (
                            <button
                                onClick={() => handlePageChange(pagination.prev_page_url)}
                                className="btn btn-secondary"
                            >
                                Previous
                            </button>
                        )}

                        {pagination.next_page_url && (
                            <button
                                onClick={() => handlePageChange(pagination.next_page_url)}
                                className="btn btn-secondary ml-2"
                            >
                                Next
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccessControl;
