import React from "react";
import Enrollments from "../../components/user/enrollmentsComponents";

const EnrollmentsPage = () => {
    return (
        <div>
            <Enrollments />
        </div>
    )
}

export default EnrollmentsPage;