import React from "react";

const ExploreSection = () => {
  return (
    <div className="explore-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="explore-content">
              <h2>Explore the Future of Educational Management</h2>
              <p>
                with EduManage, your trusted partner in institutional success.
              </p>
              <a href="/signup" className="learn-more-button">
                Get Started
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExploreSection;
