import React from "react";
import Results from "../../components/user/admin/results";

const ResultsPage = () => {
    return (
        <div>
            <Results />
        </div>
    )
}

export default ResultsPage;