import React, { useEffect, useState } from "react";
import api from "../../api/axios";
import Preloader from "../preloader";
import Table from "./table";
import { useParams } from "react-router-dom";
import { FaRegWindowClose } from "react-icons/fa";
import { webIcons } from "../../constants/icons";

const CustomDialog = ({ isOpen, onClose, event, user }) => {
	const [formData, setFormData] = useState({
		organization_id: event.organization_id,
		event: event.id,
		user_id: user.id || null,
		first_name: "",
		last_name: "",
		phone: "",
		email: "",
	});

	if (!isOpen) return null;

	const handleFormChange = (field, value) => {
		setFormData({
			...formData,
			[field]: value,
		});
	};

	const registerEvent = async (e) => {
		e.preventDefault();
		try {
			const response = await api.post("/activities", formData);
			console.log(response.data);
			onClose();
		} catch (error) {
			console.error("Error registering for event", error.response);
		}
	};

	return (
		<div className="dialog-overlay">
			<div className="dialog-background" onClick={onClose}></div>
			<div className="dialog-content">
				<div className="d-flex flex-row justify-content-between">
					<strong>{event.type}</strong>
					<FaRegWindowClose onClick={onClose}>x</FaRegWindowClose>
				</div>
				<div className="row dialog-details mt-4 mb-3">
					<form onSubmit={registerEvent}>
						<div
							className="wrap-input rs1-wrap-input validate-input"
							data-validate="Please enter first name"
						>
							<span className="label-input">First Name</span>
							<input
								name="first_name"
								type="text"
								className="input"
								required
								onChange={(e) =>
									handleFormChange(
										"first_name",
										e.target.value
									)
								}
								placeholder="Enter your first name"
							/>
							<span className="focus-input"></span>
						</div>

						<div
							className="wrap-input rs1-wrap-input validate-input"
							data-validate="Please enter last name"
						>
							<span className="label-input">Last Name</span>
							<input
								name="last_name"
								type="text"
								className="input"
								required
								onChange={(e) =>
									handleFormChange(
										"last_name",
										e.target.value
									)
								}
								placeholder="Enter your last name"
							/>
							<span className="focus-input"></span>
						</div>

						<div
							className="wrap-input rs1-wrap-input validate-input"
							data-validate="Please enter email"
						>
							<span className="label-input">Your Email</span>
							<input
								name="email"
								type="email"
								className="input"
								required
								onChange={(e) =>
									handleFormChange("email", e.target.value)
								}
								placeholder="Enter your email"
							/>
							<span className="focus-input"></span>
						</div>

						<div
							className="wrap-input rs1-wrap-input validate-input"
							data-validate="Please enter phone"
						>
							<span className="label-input">Your Phone</span>
							<input
								name="phone"
								type="text"
								className="input"
								required
								onChange={(e) =>
									handleFormChange("phone", e.target.value)
								}
								placeholder="e.g +254 7123 45678"
							/>
							<span className="focus-input"></span>
						</div>
						<div className="d-flex flex-row justify-content-between w-100">
							<button
								type="button"
								className="nav-sign left-btn"
								onClick={onClose}
							>
								Cancel
							</button>
							<button type="submit" className="nav-sign active">
								Submit
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

const EventHeader = ({ event, user }) => {
	const [isDialogOpen, setDialogOpen] = useState(false);

	const handleOpenDialog = () => {
		setDialogOpen(true);
	};

	const handleCloseDialog = () => {
		setDialogOpen(false);
	};

	return (
		<div className="row">
			<div className="col-lg-12 d-flex justify-content-between">
				<div></div>
				<button className="upload-btn" onClick={handleOpenDialog}>
					Register
				</button>
			</div>
			<div className="col-lg-12">
				<div className="events-header">
					<h2 className="page-title">{event.name}</h2>
				</div>
			</div>
			<CustomDialog
				isOpen={isDialogOpen}
				onClose={handleCloseDialog}
				event={event}
				user={user}
			/>
		</div>
	);
};

const EventContent = ({ event }) => {
	return (
		<div className="row" style={{paddingLeft: '10px'}}>
			<div className="col-lg-12">
				<div className="card-title mt-4">
					<h2>About This Event</h2>
				</div>
				<div>
					<p>{event.description}</p>
				</div>
			</div>
			<div className="col-lg-12 mt-4">
				<div className="card-title">
					<h2>Date and Time</h2>
				</div>
				<div className="d-flex flex-row">
					<div className="event-icon">{webIcons.events_time}</div>
					<div>
						<h3>{event.start_date}</h3>
						<p>{event.start_time}</p>
					</div>
				</div>
			</div>
			<div className="col-lg-12 mt-4">
				<div className="card-title">
					<h2>Location</h2>
				</div>
				<div className="d-flex flex-row">
					<div className="event-icon">{webIcons.events_location}</div>
					<div>
						<h3>
							{event.location} {event.address}
						</h3>
						<p>{event.venue}</p>
					</div>
				</div>
			</div>
		</div>
	);
};

const ActivitiesTable = ({ events }) => {
	const route = "activities";
	const columnNames = [
		"date",
		"from",
		"to",
		"venue",
		"description",
		"challenges",
		"solutions",
		"sales",
		"remarks",
	];

	const columnValues = [
		"date",
		"from",
		"to",
		"venue",
		"description",
		"challenges",
		"solutions",
		"sales",
		"remarks",
	];

	const args = {
		columnNames: columnNames,
		columnValues: columnValues,
		route: route,
	};

	return (
		<div>
			<Table {...args} data={events} />
		</div>
	);
};

const EventInfo = () => {
	const { eventId } = useParams();
	const [user, setUser] = useState();
	const [activities, setActivities] = useState([]);
	const [event, setEvent] = useState({});
	const [load, setLoad] = useState(true);

	useEffect(() => {
		api.get("/user")
			.then(({ data: userData }) => {
				setUser(userData.id);
			})
			.catch((error) => {
				console.error("Error fetching data", error.response);
			});
	}, []);

	useEffect(() => {
		const handleDataFetch = async () => {
			try {
				const response = await api.get(`/events/${eventId}`);
				setEvent(response.data.event);
				setLoad(false);
			} catch (error) {
				console.error("Error fetching data", error.response);
			}
		};

		if (user) {
			handleDataFetch();
		}
	}, [user]);

	useEffect(() => {
		const getActivities = async () => {
			try {
				const response = await api.get(`/activities/${event.id}`);
				setActivities(response.data);
				setLoad(false);
				console.log(response.data);
			} catch (error) {
				console.error("Error fetching data", error.response);
			}
		};

		if (event.id) {
			// getActivities();
		}
	});
	return load ? (
		<Preloader />
	) : (
		<div className="events-section">
			<div className="container">
				<div className="d-flex flex-row">
					<h4 className="mr-1">Calendar</h4>
					<h4 className="mr-1">{">"}</h4>
					<h4 className="mr-1">Events</h4>
					<h4 className="mr-1">{">"}</h4>
					<h4 className="mr-1">
						<b>View</b>
					</h4>
				</div>
				<EventHeader user={user} event={event} />
				<EventContent event={event} />
				<div className="row">
					<div
						className="col-lg-12 d-flex flex-row justify-content-between align-items-center"
						style={{ margin: "15px 0px 15px 0px" }}
					>
						<h2 className="page-titles">Activities</h2>
					</div>
					<div className="col-lg-12">
						<ActivitiesTable events={activities} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default EventInfo;
