import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import api from "../../api/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FaAngleDown, FaSearch } from "react-icons/fa";
import { Autocomplete, Button, TextField } from "@mui/material";

const ContactListItem = ({ contact, user }) => {
    const [contentVisible, setContentVisible] = useState(false);

    const toggleContentVisibility = () => {
        setContentVisible(!contentVisible);
    };

    return (
        <div className="contact-card">
            <div
                className="card-header d-flex flex-row justify-content-between"
                style={{ width: "100%" }}
                onClick={toggleContentVisibility}
            >
                <div className="details">
                    <h5>
                        {contact.first_name} {contact.last_name}
                    </h5>
                    <p>{contact.email}</p>
                </div>
                <div className="d-flex align-items-center">
                    <FaAngleDown size={15} />
                </div>
            </div>
            <div className={contentVisible ? "card-content-visible" : "card-content-hidden"}>
                <div className="contact-detail">
                    <h5>City</h5>
                    <p>{contact.city ? contact.city : ""}</p>
                </div>
                <div className="contact-detail">
                    <h5>State</h5>
                    <p>{contact.state ? contact.state : ""}</p>
                </div>
                <div className="d-flex float-right">
                    <button />
                </div>
            </div>
        </div>
    );
};

const SearchBar = ({ contacts }) => {
    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);

    const handleInputChange = (event, value) => {
        setSearchQuery(value);

        const filteredResults = contacts.reduce((acc, category) => {
            const categoryResults = Object.entries(category).filter(([name]) =>
                name.toLowerCase().includes(value.toLowerCase())
            );
            return acc.concat(categoryResults);
        }, []);
        setSearchResults(filteredResults);

        setShowDropdown(filteredResults.length > 0);
    };

    const handleResultClick = (name, path) => {
        setSearchQuery("");
        setShowDropdown(false);
    };

    return (
        <Autocomplete
            freeSolo
            className="searchbar"
            options={searchResults.map(([name, path]) => name)}
            inputValue={searchQuery}
            onInputChange={handleInputChange}
            renderOption={(props, option) => (
                <li {...props} onClick={() => handleResultClick(option, contacts[option])}>
                    {option}
                </li>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder="Search for..."
                    variant="outlined"
                    fullWidth
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <button type="submit">
                                <FaSearch className="icon" size={15} color="blue" />
                            </button>
                        ),
                        style: {
                            borderRadius: "12px",
                            width: "200px",
                            // left: "16px",
                        },
                    }}
                />
            )}
        />
    );
};

const MessagesContent = ({ organizations }) => {
    return (
        <div className="container">
            {organizations && organizations.length > 0 ? (
                organizations.map((organization) => (
                    <div className="row" key={organization.organization}>
                        <h3>{organization.organization}</h3>
                        <div className="table-responsive">
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Email</th>
                                        <th>Contact</th>
                                        <th>Country</th>
                                        <th>Organization</th>
                                        <th>Role</th>
                                        <th>Title</th>
                                    </tr>
                                </thead>
                                {organization.contacts && organization.contacts.length > 0 ? (
                                    <tbody>
                                        {organization.contacts.map((contacts) => (
                                            <tr key={contacts.id}>
                                                <td>
                                                    {contacts.image ? (
                                                        <img
                                                            className="rounded-circle"
                                                            src={`${process.env.REACT_APP_WEB_URL}/storage/${contacts.logo}`}
                                                            style={{
                                                                height: "35px",
                                                                width: "35px",
                                                            }}
                                                            alt="contacts"
                                                        />
                                                    ) : (
                                                        ""
                                                    )}
                                                </td>
                                                <td>{contacts.first_name}</td>
                                                <td>{contacts.last_name}</td>
                                                <td>{contacts.email}</td>
                                                <td>{contacts.contact}</td>
                                                <td>{contacts.country}</td>
                                                <td>{contacts.organization}</td>
                                                <td>{contacts.title}</td>
                                                <td>{contacts.role}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                ) : (
                                    <tbody>
                                        <td colSpan={9}>
                                            <p>No Contact available for this organization.</p>
                                        </td>
                                    </tbody>
                                )}
                            </Table>
                        </div>
                    </div>
                ))
            ) : (
                <p>Your are currently not registered in an organization.</p>
            )}
        </div>
    );
};

const RightView = ({ userId, toggleView, icon }) => {
    const [contacts, setContacts] = useState([]);
    const [searchResults, setSearchResults] = useState([]);

    useEffect(() => {
        api.get("/ferxity-users")
            .then(({ data: userData }) => {
                if (userData.data) {
                    setContacts(userData.data);
                    console.log("Users -> ", userData.data);
                }
            })
            .catch((error) => {
                console.error("Error fetching contacts", error.response);
            });
    }, []);

    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="right-view-header d-flex flex-row justify-content-between align-items-center">
                        <SearchBar />

                        <FontAwesomeIcon
                            icon={icon}
                            onClick={toggleView}
                            style={{ width: "min-content" }}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                {contacts.map((contact) => (
                    <ContactListItem user={userId} contact={contact} />
                ))}
            </div>
        </div>
    );
};

const Messages = () => {
    const [user, setUser] = useState();
    const [view, setView] = useState({});
    const [icon, setIcon] = useState(faTimes);
    const [rightSectionVisible, setRightSectionVisible] = useState(false);

    useEffect(() => {
        api.get("/user")
            .then(({ data: userData }) => {
                setUser(userData.id);
            })
            .catch((error) => {
                console.error("Error fetching data", error.response);
            });
    }, []);

    useEffect(() => {
        const handleDataFetch = async () => {
            try {
                console.log(user);
                const response = await api.get(`/users-data/messages/${user}`);
                setView(response.data.data);
                console.log(response.data.data);
            } catch (error) {
                console.error("Error fetching messages", error.response);
            }
        };

        if (user) {
            handleDataFetch();
        }
    }, [user]);

    const toggleRightSection = () => {
        setRightSectionVisible(!rightSectionVisible);
        if (!rightSectionVisible) {
            setIcon(faTimes);
        } else {
            setIcon(faArrowAltCircleLeft);
        }
    };

    return (
        <div
            className="contacts-section d-flex flex-row"
            style={{ minHeight: "85vh", paddingLeft: "20px" }}
        >
            <div className={`left-view ${rightSectionVisible ? "left-view-min" : ""}`}>
                <div
                    style={{
                        maxWidth: rightSectionVisible ? "70%" : "100%",
                        overflow: "hidden",
                        transition: "max-width 0.5s ease",
                    }}
                >
                    <div className="row">
                        <div
                            className="col-lg-12 d-flex flex-row justify-content-between"
                            style={{ margin: "15px 0px 25px 0px" }}
                        >
                            <h2>Contacts</h2>
                            <Button onClick={toggleRightSection}>Add new contact</Button>{" "}
                        </div>
                        <div className="col-lg-12">
                            <MessagesContent organizations={view} />
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="right-view"
                style={{
                    opacity: rightSectionVisible ? 1 : 0,
                    width: rightSectionVisible ? "300px" : "0px",
                    position: "fixed",
                    right: "4px",
                    transition: "all 0.5s ease-in-out, width 0.5s ease-in-out",
                }}
            >
                <div className="d-flex flex-column align-items-right" style={{ width: "100%" }}>
                    <RightView userId={user} toggleView={toggleRightSection} icon={icon} />
                </div>
            </div>
        </div>
    );
};

export default Messages;
