import React from "react";

function Preloader() {
    return (
        <div className="loader-container">
            <div className="loader"></div>
        </div>
    );
}

export default Preloader;
