import React, { useState, useEffect } from "react";
import api from "../../api/axios";

const NotificationsContent = ({ notifications }) => {
	return (
		<div className="notifications-content">
			<div className="notifications-header d-flex flex-row">
				<h2>List Notification</h2>
			</div>
			<div className="sub-heading"></div>
			<div className="notifications-container">
				{notifications.map((notification, index) => (
					<div key={index} className="notification-item">
						<h4>{notification.title}</h4>
						<p>{notification.message}</p>
					</div>
				))}
			</div>
		</div>
	);
};

const Notifications = () => {
	const [notifications, setNotifications] = useState(null);
	const [userId, setUserId] = useState(null);

	useEffect(() => {
		api.get("/user")
			.then(({ data: userData }) => {
				if (userData) {
					setUserId(userData.id);
					api.get(`/notifications/user/${userData.id}`)
						.then(({ data: userNotifications }) => {
							setNotifications(userNotifications.data);
						})
						.catch((error) => {
							console.error(
								"Error fetching user notifications -> ",
								error.response
							);
						});
				}
			})
			.catch((error) => {
				console.error("Error fetching data", error.response);
			});
	}, []);

	return (
		<div className="container notifications-section">
			<div className="row">
				{notifications ? (
					<NotificationsContent notifications={notifications} />
				) : (
					<p>You currently have no notifications.</p>
				)}
			</div>
		</div>
	);
};

export default Notifications;
