import React, { useState, useEffect } from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Checkbox,
	Button,
	Typography,
	Paper,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	TextField,
	DialogActions,
} from "@mui/material";
import api from "../../api/axios";

const tableStyle = {
	body: {
		borderRadius: "12px",
		border: "1px solid #FC7703",
		padding: "0 10px",
	},
};

const TeamTab = () => {
	const [teams, setTeams] = useState([]);
	const [user, setUser] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [teamsPerPage] = useState(1);
	const [selectedUserIds, setSelectedUserIds] = useState([]);
	const [selectAllChecked, setSelectAllChecked] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const user = await api.get("/user");
				const response = await api.get(`/users-data/teams/${user.data.id}`);
				setUser(user.data.id);
				setTeams(response.data.data);
			} catch (error) {
				console.error("Error fetching data", error.response);
			}
		};
		fetchData();
	}, [setTeams, user]);

	const handleCheckboxChange = (userId) => {
		const isSelected = selectedUserIds.includes(userId);

		if (isSelected) {
			setSelectedUserIds((prevIds) =>
				prevIds.filter((id) => id !== userId)
			);
		} else {
			setSelectedUserIds((prevIds) => [...prevIds, userId]);
		}
	};

	const handleSelectAllCheckboxChange = () => {
		if (!selectAllChecked) {
			const allUserIds = teams.flatMap((team) =>
				team.team_members.map((member) => member.id)
			);
			setSelectedUserIds(allUserIds);
		} else {
			setSelectedUserIds([]);
		}
		setSelectAllChecked((prev) => !prev);
	};

	const formatDate = (dateTimeString) => {
		const options = {
			year: "numeric",
			month: "short",
			day: "numeric",
			hour: "numeric",
			minute: "numeric",
			second: "numeric",
			hour12: true,
		};

		const formattedDate = new Date(dateTimeString).toLocaleDateString(
			undefined,
			options
		);
		return formattedDate;
	};

	return (
		<div className="container">
			<h2 style={{ margin: "20px 0px" }}>Your Teams</h2>
			<div className="row">
				<div className="col-md-12">
					{teams.length > 0 ? (
						teams.map((team, index) => (
							<div key={index} className="table-wrap">
								<h3>{team.team.team_name}</h3>
								<p>
									Number of Team Members:{" "}
									{team.team_members.length}
								</p>
								<TableContainer
									component={Paper}
									style={tableStyle.body}
								>
									<Table>
										<TableHead>
											<TableRow>
												<TableCell>
													<Checkbox
														checked={
															selectAllChecked
														}
														onChange={
															handleSelectAllCheckboxChange
														}
													/>
												</TableCell>
												<TableCell>profile</TableCell>
												<TableCell>Name</TableCell>
												<TableCell>Role</TableCell>
												<TableCell>Title</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{team.team_members.map((member) => (
												<TableRow key={member.id}>
													<TableCell>
														<Checkbox
															checked={selectedUserIds.includes(
																member.id
															)}
															onChange={() =>
																handleCheckboxChange(
																	member.id
																)
															}
														/>
													</TableCell>
													<TableCell>
														<img
															className="rounded-circle"
															src={`${process.env.REACT_APP_WEB_URL}/storage/${member.profile_image}`}
															style={{
																height: "35px",
																width: "35px",
															}}
															alt="Profile"
														/>
													</TableCell>
													<TableCell>
														{member.nsmr}
													</TableCell>
													<TableCell>
														{member.role}
													</TableCell>
													<TableCell>
														{member.title}
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							</div>
						))
					) : (
						<div className="table-wrap">
							<h3>No Teams Available</h3>
							<p>You are currently not assigned to a team.</p>
							<CreateTeam user={user} />
						</div>
					)}
					{/* Pagination goes here */}
				</div>
			</div>
		</div>
	);
};

const CreateTeam = ({ user }) => {
	const [open, setOpen] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div className="row">
			<div className="col-lg-12 mt-4">
				<button
					onClick={handleOpen}
					className="btn-sign"
					style={{ borderRadius: "8px" }}
				>
					Create Your Team
				</button>
				<CreateTeamDialog
					open={open}
					handleClose={handleClose}
					user={user}
				/>
			</div>
		</div>
	);
};

const CreateTeamDialog = ({ open, handleClose, user }) => {
	const [formData, setFormData] = useState({
		created_by: user,
		team_name: "",
		goal: "",
		start_date: "",
		due_date: "",
	});

	useEffect(() => {setFormData({ ...formData, ['created_by']: user})}, [user]);

	const handleChange = (event) => {
		const { name, value } = event.target;
		setFormData({ ...formData, [name]: value });
		// setFormData({ ...formData, ['created_by']: user });
		console.log(formData);
	};

	const handleCreateTeam = async () => {
		try {
			const response = await api.post(`/teams`, formData);
			console.log(response.data);
		} catch (error) {
			console.error(
				"Failed to create your team, please try again later!",
				error
			);
		}

		handleClose();
	};

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>Create a new team</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Organize your team to manage and complete tasks more
					efficiently
				</DialogContentText>
				{/* Form inputs */}
				<TextField
					autoFocus
					margin="dense"
					id="team-name"
					name="team_name"
					label="Team Name"
					type="text"
					fullWidth
					value={formData.team_name}
					onChange={handleChange}
				/>
				<TextField
					margin="dense"
					id="goal"
					name="goal"
					label="Goal"
					type="text"
					fullWidth
					value={formData.goal}
					onChange={handleChange}
				/>
				<TextField
					margin="dense"
					id="start-date"
					name="start_date"
					label="Start Date"
					type="date"
					fullWidth
					InputLabelProps={{
						shrink: true,
					}}
					value={formData.start_date}
					onChange={handleChange}
				/>
				<TextField
					margin="dense"
					id="end-date"
					name="due_date"
					label="End Date"
					type="date"
					fullWidth
					InputLabelProps={{
						shrink: true,
					}}
					value={formData.due_date}
					onChange={handleChange}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={handleClose}
					style={{ color: "orange", border: "1px solid orange" }}
				>
					Cancel
				</Button>
				<Button
					onClick={handleCreateTeam}
					style={{ backgroundColor: "orange", color: "white" }}
				>
					Create Team
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default TeamTab;
