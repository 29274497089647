import React from 'react';

const timezones = [
    '(UTC-11:00) Samoa',
    '(UTC-11:00) Coordinated Universal Time-11',
    '(UTC-10:00) Hawaii',
    '(UTC-09:00) Alaska',
    '(UTC-08:00) Tijuana, Baja California',
    '(UTC-08:00) Pacific Time (US & Canada)',
    '(UTC-07:00) Mountain Time (US & Canada)',
    '(UTC-07:00) Mazatlan',
    '(UTC-07:00) Chihuahua, La Paz, Mazatlan',
    '(UTC-07:00) Arizona',
    '(UTC-06:00) Saskatchewan',
    '(UTC-06:00) Monterrey',
    '(UTC-06:00) Mexico City',
    '(UTC-06:00) Central Time (US & Canada)',
    '(UTC-06:00) Central America',
    '(UTC-05:00) Rio Branco',
    '(UTC-05:00) Lima',
    '(UTC-05:00) Indiana (East)',
    '(UTC-05:00) Eastern Time (US & Canada)',
    '(UTC-05:00) Bogota, Lima, Quito',
    '(UTC-04:30) Caracas',
    '(UTC-04:00) Santiago',
    '(UTC-04:00) Manaus',
    '(UTC-04:00) La Paz',
    '(UTC-04:00) Cuiaba',
    '(UTC-04:00) Atlantic Time (Canada)',
    '(UTC-04:00) Asuncion',
    '(UTC-03:30) Newfoundland',
    '(UTC-03:00) Montevideo',
    '(UTC-03:00) Greenland',
    '(UTC-03:00) Buenos Aires',
    '(UTC-03:00) Brasilia',
    '(UTC-02:00) Mid-Atlantic',
    '(UTC-01:00) Cape Verde Is.',
    '(UTC-01:00) Azores',
    '(UTC) Casablanca',
    '(UTC) Coordinated Universal Time, Greenwich Mean Time',
    '(UTC) London, Edinburgh, Dublin, Lisbon',
    '(UTC) Monrovia, Reykjavik',
    '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
    '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
    '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris',
    '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
    '(UTC+01:00) West Central Africa',
    '(UTC+02:00) Amman',
    '(UTC+02:00) Athens, Bucharest',
    '(UTC+02:00) Beirut',
    '(UTC+02:00) Cairo',
    '(UTC+02:00) Harare',
    '(UTC+02:00) Helsinki, Riga, Sofia, Tallinn, Vilnius',
    '(UTC+02:00) Istanbul',
    '(UTC+02:00) Jerusalem',
    '(UTC+02:00) Minsk',
    '(UTC+02:00) Windhoek',
    '(UTC+03:00) Baghdad',
    '(UTC+03:00) Kuwait, Riyadh',
    '(UTC+03:00) Moscow, Volgograd',
    '(UTC+03:00) Nairobi',
    '(UTC+03:30) Tehran',
    '(UTC+04:00) Abu Dhabi, Muscat',
    '(UTC+04:00) Baku',
    '(UTC+04:00) Tbilisi',
    '(UTC+04:00) Yerevan',
    '(UTC+05:00) Islamabad, Karachi',
    '(UTC+05:00) Tashkent',
    '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
    '(UTC+05:30) Sri Jayawardenepura',
    '(UTC+05:45) Kathmandu',
    '(UTC+06:00) Almaty',
    '(UTC+06:00) Dhaka',
    '(UTC+06:00) Ekaterinburg',
    '(UTC+06:30) Yangon (Rangoon)',
    '(UTC+07:00) Bangkok, Jakarta',
    '(UTC+07:00) Novosibirsk',
    '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
    '(UTC+08:00) Krasnoyarsk',
    '(UTC+08:00) Kuala Lumpur, Singapore',
    '(UTC+08:00) Perth',
    '(UTC+08:00) Taipei',
    '(UTC+08:00) Ulaan Bataar',
    '(UTC+09:00) Irkutsk',
    '(UTC+09:00) Seoul',
    '(UTC+09:00) Tokyo',
    '(UTC+09:30) Adelaide',
    '(UTC+09:30) Darwin',
    '(UTC+10:00) Brisbane',
    '(UTC+10:00) Canberra, Melbourne, Sydney',
    '(UTC+10:00) Guam, Port Moresby',
    '(UTC+10:00) Hobart',
    '(UTC+10:00) Vladivostok',
    '(UTC+10:00) Yakutsk',
    '(UTC+11:00) Solomon Is., New Caledonia',
    '(UTC+12:00) Auckland',
    '(UTC+12:00) Fiji',
    '(UTC+12:00) Kamchatka',
    '(UTC+12:00) Magadan',
    '(UTC+12:00) Marshall Is.',
    '(UTC+12:00) Wellington',
    '(UTC+13:00) Nukualofa'
  ];

  export default timezones;