import React, { createRef, useState, useEffect, useRef } from "react";
import { Link, Navigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { useStateContext } from "../../context/ContextProvider.js";
import api from "../../api/axios.js";

function LoginForm() {
    const emailRef = createRef();
    const passwordRef = createRef();
    const { setUser, setToken } = useStateContext();
    const [errorMessage, setErrorMessage] = useState("");
    const [rememberMe, setRememberMe] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);

    const csrfTokenRef = useRef();

    useEffect(() => {
        csrfTokenRef.current = document.head.querySelector('meta[name="csrf-token"]').content;
    }, []);

    const onSubmit = async (ev) => {
        ev.preventDefault();

        if (!emailRef.current || !emailRef.current.value.trim()) {
            setErrorMessage("Email cannot be empty");
            return;
        }

        if (!passwordRef.current || !passwordRef.current.value.trim()) {
            setErrorMessage("Password cannot be empty");
            return;
        }

        if (!emailRef.current || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailRef.current.value.trim())) {
            setErrorMessage("Invalid email, Please enter a valid email!");
            return;
        }

        try {
            const payload = {
                email: emailRef.current.value,
                password: passwordRef.current.value,
            };

            const response = await api.post("/login", payload, {
                headers: {
                    Accept: "application/json",
                    "X-CSRF-TOKEN": csrfTokenRef.current,
                },
            });

            setUser(response.data.user);
            setToken(response.data.token);

            return <Navigate to="/dashboard" />;
        } catch (error) {
            const response = error.response;
            if (response && response.status === 422) {
                setErrorMessage(response.data.message);
            } else {
                setErrorMessage("An error occurred, please try again.");
                console.error(response ? response.data.message : error.message);
            }
        }
    };

    return (
        <div className="d-flex justify-content-center mt-5 mb-5">
            <div className="form-section">
                <form className="contact-form w-100 validate-form " onSubmit={onSubmit}>
                    <div className="position-absolute back-to-home">
                        <a href="/">{'< to home '}</a>
                    </div>
                    <span className="contact-form-title">Sign In</span>
                    <div
                        className="wrap-input rs1-wrap-input validate-input"
                        data-validate="Valid email or username is required!"
                    >
                        <span className="label-input">Enter Email</span>
                        <input
                            className="input"
                            ref={emailRef}
                            id="email"
                            type="text"
                            name="email"
                            placeholder="Enter your email"
                            style={{
                                borderColor:
                                    errorMessage && (!emailRef.current || !emailRef.current.value.trim()) ? "red" : "",
                            }}
                        />
                        <span className="focus-input"></span>
                    </div>
                    <div className="d-flex flex-column wrap-input rs1-wrap-input validate-input">
                        <span className="label-input">Enter Password</span>
                        <input
                            ref={passwordRef}
                            id="password"
                            type={passwordVisible ? "text" : "password"}
                            name="password"
                            className="input"
                            placeholder="Enter your password"
                        />
                        <span
                            className="position-absolute align-self-end mr-2 password-toggle-icon"
                            style={{ top: "30px" }}
                            onClick={() => setPasswordVisible(!passwordVisible)}
                        >
                            <FontAwesomeIcon icon={passwordVisible ? faEye : faEyeSlash} />
                        </span>
                        <span className="focus-input"></span>
                    </div>

                    <div className="mb-3 col-lg-12 d-flex flex-row w-100 justify-content-between">
                        <div className="d-flex align-items-center">
                            <input
                                type="checkbox"
                                id="rememberMe"
                                className="sign-checkbox"
                                checked={rememberMe}
                                onChange={() => setRememberMe(!rememberMe)}
                                style={{ margin: "0px 10px" }}
                            />
                            <label className="ml-1 remember-me">Remember me</label>
                        </div>
                        <Link to="/login" className="forgot-password-link">
                            Forgot password?
                        </Link>
                    </div>
                    <div className="position-relative">
                        {errorMessage && (
                            <div className="alert alert-danger">
                                <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
                                {errorMessage}
                            </div>
                        )}
                    </div>

                    <div className="container-contact-form-btn position-relative">
                        <button className="contact-form-btn" type="submit">
                            <span>
                                Submit
                                <i className="fa fa-long-arrow-right m-l-7" aria-hidden="true"></i>
                            </span>
                        </button>
                    </div>

                    <span className="contact-more mt-2 d-flex flex-row justify-content-center w-100 ">
                        Not registered?:{" "}
                        <span className="contact-more-highlight">
                            <Link to="/signup">Create an account</Link>
                        </span>
                    </span>
                </form>
            </div>
        </div>
    );
}

const Login = () => {
    return (
        <div className="registration-form">
            <div className="row">
                <LoginForm />
            </div>
        </div>
    );
};

export default Login;
