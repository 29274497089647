import React, { useState } from "react";

import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
// import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
// import NotificationsIcon from "@mui/icons-material/Notifications";
// import LaptopIcon from "@mui/icons-material/Laptop";
// import ContactMailIcon from "@mui/icons-material/ContactMail";
// import BusinessIcon from "@mui/icons-material/Business";
// import HandshakeIcon from "@mui/icons-material/Handshake";
// import LocalShippingIcon from "@mui/icons-material/LocalShipping";
// import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
// import LibraryBooksOutlined from "@mui/icons-material/LibraryBooksOutlined";

import {
	// FaCheck,
	// FaFacebookMessenger,
	// FaPowerOff,
	// FaBox,
	// FaHandHolding,
	// FaBullhorn,
	// FaRegCalendarAlt,
	// FaLeaf,
	FaRegUser,
	FaPowerOff,
} from "react-icons/fa";

import {
	AiOutlineBell,
	AiOutlineCalendar,
	AiOutlineCar,
	AiOutlineCheckCircle,
	AiOutlineClockCircle,
	AiOutlineDashboard,
	AiOutlineEnvironment,
	AiOutlineFundView,
	AiOutlineIdcard,
	AiOutlineMessage,
	AiOutlineNotification,
	AiOutlinePicture,
	AiOutlineRead,
	AiOutlineSchedule,
	AiOutlineSetting,
	AiOutlineShop,
	AiOutlineTeam,
	AiOutlineUnlock,
	AiOutlineUsergroupAdd,
} from "react-icons/ai";

import { MdTrendingUp } from "react-icons/md";

const sidebarIcons = {
	toggle: <MenuIcon className="menu-icon" />,
	advertisements: <AiOutlineFundView className="menu-icon" size={18} />,
	departments: <AiOutlineTeam className="menu-icon" size={18} />,
	dashboard: <AiOutlineDashboard className="menu-icon" size={18} />,
	calendar: <AiOutlineCalendar className="menu-icon" size={18} />,
	messages: <AiOutlineMessage className="menu-icon" size={18} />,
	campaigns: <AiOutlineNotification className="menu-icon" size={18} />,
	leads: <AiOutlineUsergroupAdd className="menu-icon" size={18} />,
	contacts: <AiOutlineIdcard className="menu-icon" size={18} />,
	organizations: <AiOutlineShop className="menu-icon" size={18} />,
	opportunities: <AiOutlineUnlock className="menu-icon" size={18} />,
	orders: <AiOutlineCar className="menu-icon" size={18} />,
	sales: <MdTrendingUp className="menu-icon" size={18} />,
	services: <AiOutlineCheckCircle className="menu-icon" size={18} />,
	products: <AiOutlineShop className="menu-icon" size={18} />,
	invoices: <AiOutlineSchedule className="menu-icon" size={18} />,
	vendors: <AiOutlineRead className="menu-icon" size={18} />,
	settings: <AiOutlineSetting className="menu-icon" size={18} />,
	enrollments: <AiOutlineUsergroupAdd className="menu-icon" size={18} />,
	attendance: <AiOutlineCheckCircle className="menu-icon" size={18} />,
	results: <AiOutlineRead className="menu-icon" size={18} />,
	feeArrears: <MdTrendingUp className="menu-icon" size={18} />,
	courses: <AiOutlineRead className="menu-icon" size={18} />,
	lecturers: <AiOutlineIdcard className="menu-icon" size={18} />,
	admissions: <AiOutlineUsergroupAdd className="menu-icon" size={18} />
};

const topbarIcons = {
	notifications: <AiOutlineBell size={20} />,
	profile_link: <FaRegUser className="i" />,
	notifications_link: <FaRegUser className="i" />,
	settings_link: <SettingsOutlinedIcon className="i" fontSize="14" />,
	logout_link: <FaPowerOff className="i" />,
};

const webIcons = {
	events_time: <AiOutlineClockCircle size={20} />,
	events_location: <AiOutlineEnvironment size={20} />,
	picture: <AiOutlinePicture size={20} />,

};

const EllipsisIcon = () => {
	const [isOpen, setIsOpen] = useState(false);

	const toggleMenu = () => {
		setIsOpen(!isOpen);
	};

	const handleOptionClick = (option) => {
		console.log("Clicked:", option);
		setIsOpen(false);
	};

	return (
		<div className="vertical-ellipsis">
			<MoreVertIcon onClick={toggleMenu} />
			{isOpen && (
				<ul className="dropdown-menu">
					<li onClick={() => handleOptionClick("View")}>View</li>
					<li onClick={() => handleOptionClick("Edit")}>Edit</li>
				</ul>
			)}
		</div>
	);
};

export { sidebarIcons, topbarIcons, EllipsisIcon, webIcons };
