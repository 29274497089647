import React, { useState, useEffect } from "react";
import { usePapaParse } from "react-papaparse";
import parseISO from "date-fns/parseISO";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import api from "../api/axios";
import { FaRegWindowClose } from "react-icons/fa";

const CsvUploader = ({ columns, columnLabels, user, route }) => {
	const [csvData, setCsvData] = useState([]);
	const [organization, setOrganization] = useState({});
	const selectedColumns = Object.keys(columns);
	const [missingColumns, setMissingColumns] = useState([]);
	const [open, setOpen] = useState(false);
	const { readString } = usePapaParse();

	const handleFileUpload = (event) => {
		const file = event.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onload = () => {
				const csvString = reader.result;
				readString(csvString, {
					header: true,
					complete: (results) => {
						const filteredData = results.data.filter((row) =>
							Object.values(row).some(
								(value) => value !== null && value !== ""
							)
						);
						const mappedData = filteredData.map((row) =>
							mapRowData(row)
						);
						const missingCols = Object.keys(columns).filter(
							(col) => !mappedData[0].hasOwnProperty(col)
						);
						setMissingColumns(missingCols);
						setCsvData(mappedData);
						setOpen(true);
					},
				});
			};
			reader.readAsText(file);
		}
	};

	const mapRowData = (row) => {
		const mappedRow = {};
		for (const [standardCol, labels] of Object.entries(columnLabels)) {
			const matchingLabel = labels.find((label) =>
				Object.keys(row).includes(label)
			);
			if (matchingLabel) {
				mappedRow[standardCol] = convertValue(
					row[matchingLabel],
					columns[standardCol]
				);
			} else {
				mappedRow[standardCol] = null;
			}
		}
		mappedRow["user_id"] = user;
		mappedRow["organization_id"] = organization;
		return mappedRow;
	};

	const convertValue = (value, type) => {
		if (value === undefined || value === null) return null;
		switch (type) {
			case "string":
				return String(value);
			case "integer":
				return parseInt(value, 10);
			case "float":
				return parseFloat(value);
			case "date":
				return parseISO(value).toISOString().split("T")[0];
			default:
				return value;
		}
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleSubmit = async () => {
		console.log(`${route} data :`, csvData);
		try {
			for (let i = 0; i < csvData.length; i += 50) {
				const batchData = csvData.slice(i, i + 50);
				const response = await api.post(`/${route}`, batchData);
				console.log(
					`Batch ${i} submitted successfully:`,
					response.data
				);
			}
		} catch (error) {
			console.error("Unable to submit data", error);
			return;
		} finally {
			setOpen(false);
		}
	};

	const handleButtonClick = () => {
		document.getElementById("csv-upload").click();
	};

	const CustomDialog = ({ isOpen, onClose, children }) => {
		if (!isOpen) return null;

		return (
			<div className="dialog-overlay">
				<div className="dialog-background" onClick={onClose}></div>
				<div className="dialog-content">
					<div className="d-flex flex-row justify-content-between">
						<strong>CSV Data</strong>
						<FaRegWindowClose onClick={onClose} />
					</div>
					<div className="dialog-details mt-3 mb-3">{children}</div>
				</div>
			</div>
		);
	};

	return (
		<div>
			<button className="upload-btn" onClick={handleButtonClick}>
				Import
			</button>
			<input
				type="file"
				accept=".csv"
				onChange={handleFileUpload}
				style={{ display: "none" }}
				id="csv-upload"
			/>
			<CustomDialog isOpen={open} onClose={handleClose}>
				<TableContainer>
					<Table>
						<TableHead>
							<TableRow>
								{selectedColumns.map((col) => (
									<TableCell
										key={col}
										style={{
											color: missingColumns.includes(col)
												? "red"
												: "inherit",
										}}
									>
										{col}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{csvData.slice(0, 10).map((row, index) => (
								<TableRow key={index}>
									{selectedColumns.map((col) => (
										<TableCell key={col}>
											{row[col] !== null
												? row[col].toString()
												: ""}
										</TableCell>
									))}
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
				{missingColumns.length > 0 && (
					<Typography color="error">
						Missing Columns: {missingColumns.join(", ")}
					</Typography>
				)}
				<button className="upload-btn" onClick={handleSubmit}>
					Submit
				</button>
				<button className="upload-btn" onClick={handleClose}>
					Close
				</button>
			</CustomDialog>
		</div>
	);
};

export default CsvUploader;
