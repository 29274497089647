import React from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { routes } from "../../constants/routes";

const appLogo = require("../../assets/images/favicon.png");

const Footer = () => {
    return (
        <footer>
            <div className="container">
                <div className="row align-items-center align-items-stretch mb-5">
                    <div className="col-md-4 py-4 py-md-5 aside-stretch d-flex align-items-center">
                        <div className="w-100 pt-2">
                            <span className="subheading">Subscribe to our</span>
                            <h3 className="heading-section">Newsletter</h3>
                        </div>
                    </div>
                    <div className="col-md-8 py-4 py-md-5 d-flex align-items-center pl-md-5 aside-stretch-right">
                        <form action="#" className="subscribe-form w-100">
                            <div className="form-group d-flex">
                                <input
                                    type="text"
                                    className="form-control rounded-left"
                                    placeholder="Enter email address"
                                />
                                <button type="submit" className="form-control submit">
                                    <span>Submit</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="row pt-4">
                    <div className="col-md-3 col-lg-6 order-md-last">
                        <div className="row justify-content-end">
                            <div className="company-info col-md-12 col-lg-9 text-md-right mb-md-0 mb-4">
                                <h2 className="footer-heading">WeRelic</h2>
                                <p>
                                WeRelic transforms educational management by streamlining institutional operations. It offers comprehensive data management, efficient academic planning, real-time reporting, and actionable insights for enhanced decision-making.
                                </p>
                                <p className="copyright mt-5">
                                    Copyright © 2024 All rights reserved |{" "}
                                    <a href="https://werelic.com" target="_blank">
                                        WeRelic.co
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-9 col-lg-6">
                        <div className="row">
                            <div className="col-md-4 mb-md-0 mb-4">
                                <h2 className="footer-heading">Information</h2>
                                <ul className="list-unstyled">
                                    <li>
                                        <a href="#" className="py-1 d-flex flex-row align-items-center">
                                            <AiOutlineCheckCircle className="mr-2" />
                                            Our Company
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className="py-1 d-flex flex-row align-items-center">
                                            <AiOutlineCheckCircle className="mr-2" />
                                            Data
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className="py-1 d-flex flex-row align-items-center">
                                            <AiOutlineCheckCircle className="mr-2" />
                                            Pricing
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-4 mb-md-0 mb-4">
                                <h2 className="footer-heading">Products</h2>
                                <ul className="list-unstyled">
                                    <li>
                                        <a href="#" className="py-1 d-flex flex-row align-items-center">
                                            <AiOutlineCheckCircle className="mr-2" />
                                            werelic Solution
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className="py-1 d-flex flex-row align-items-center">
                                            <AiOutlineCheckCircle className="mr-2" />
                                            werelic Pro
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className="py-1 d-flex flex-row align-items-center">
                                            <AiOutlineCheckCircle className="mr-2" />
                                            werelic Analytics
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <a href={routes.Home} className="app-logo d-flex align-items-center">
                    <img src={appLogo} alt="cremimic logo" />
                </a>
            </div>
        </footer>
    );
};

export default Footer;
