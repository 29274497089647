import React from 'react';
import mainBannerImage from '../../assets/images/bannerImage.jpg';
import "../../styles/styles.css";

const MainBanner = () => {
    return (
        <div className="main-banner wow fadeIn animated" id="top" data-wow-duration="1s" data-wow-delay="0.5s" data-scroll-index="0">
            <div className="container h-100">
                <div className="row h-100 d-flex align-items-center">
                    <div className="col-lg-12 pt-3">
                        <div className="d-flex flex-row align-items-center justify-content-around">
                            <div className="left-content show-up header-text wow fadeInLeft animated" data-wow-duration="1s" data-wow-delay="1s">
                                <div className="row">
                                    <h6>WELCOME TO WERELIC</h6>
                                    <h2>Transforming Educational Management</h2>
                                    <p>
                                        WeRelic simplifies academic and administrative processes by automating student enrollment, faculty coordination, attendance tracking, and resource management. Streamline communication, scheduling, and reporting with our advanced institute management solution.
                                    </p>
                                    <div className="col-lg-12 mt-4">
                                        <a href="/signup" className="btn-sign">Get Started</a>
                                    </div>
                                </div>
                            </div>
                            <div className="rightImage wow fadeInRight animated" data-wow-duration="1s" data-wow-delay="0.5s">
                                <img className="banner-image" src={mainBannerImage} alt="Marketing" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MainBanner;
