const enrollments = {
	admission_no: "string",
	first_name: "string",
	surname: "date",
    phone: "string",
    email: "string",
    city: "string",
	course_enrolled: "date",
	date_enrolled: "date",
	exam_body: "string",
    passport: "string",
    gender: "boolean"
};

const enrollmentsLabels = {
    admission_no: ["admission_no"],
    first_name: ["first_name"],
    surname: ["surname"],
    phone: ["phone"],
    email: ["email"],
    city: ["city"],
    course_enrolled: ["course_enrolled"],
    date_enrolled: ["date_enrolled"],
    exam_body: ["exam_body"],
    passport: ["passport"],
    gender: ["gender"]
};

const advertisements = {
    source: "string",
    item: "string",
    type: "string",
    from: "date",
    to: "date",
    budget: "integer",
    cost: "integer",
    net_earnings: "integer",
    remarks: "string",
};

const advertisementLabels = {
    source: ["source", "Source", "Advertising Source"],
    item: ["item", "Item", "Advertising Item"],
    type: ["type", "Type", "Advertising Type"],
    from: ["from", "From", "Start Date", "Begin Date"],
    to: ["to", "To", "End Date"],
    budget: ["budget", "Budget", "Advertising Budget"],
    cost: ["cost", "Cost", "Advertising Cost"],
    net_earnings: ["net_earnings", "Net Earnings", "Net Profit"],
    remarks: ["remarks", "Remarks", "Notes", "Comments"],
};

const contacts = {
	first_name: "string",
	last_name: "string",
	email: "string",
	phone: "string",
	country: "string",
	location: "string",
	address: "string",
	organization: "string",
	role: "string",
	title: "string",
	notes: "string",
};

const contactLabels = {
    first_name: ["first_name", "First Name"],
    last_name: ["last_name", "Last Name"],
    email: ["email", "Email"],
    phone: ["phone", "Phone"],
    country: ["country", "Country"],
    location: ["location", "Location"],
    address: ["address", "Address"],
    organization: ["organization", "Organization"],
    role: ["role", "Role"],
    title: ["title", "Title"],
    notes: ["notes", "Notes"]
};

const opportunities = {
    title: "string",
    description: "string",
    expected_revenue: "float",
    close_date: "date",
    probability: "integer",
    status: "string",
};

const opportunityLabels = {
    title: ["title", "Title", "Opportunity Title", "Name"],
    description: ["description", "Description", "Details", "Opportunity Description"],
    expected_revenue: ["expected_revenue", "Expected Revenue", "Revenue", "Projected Revenue"],
    close_date: ["close_date", "Close Date", "Closing Date", "Expected Close Date"],
    probability: ["probability", "Probability", "Success Probability", "Win Probability"],
    status: ["status", "Status", "Opportunity Status", "Current Status"],
};

const products = {
    product_name: "string",
    price: "float",
    product_code: "string",
    product_category: "string",
    manufacturer: "string",
    qty_per_unit: "float",
    unit_price: "float",
    weight: "float",
    pack_size: "integer",
    sales_start_date: "date",
    sales_end_date: "date",
    start_date: "date",
    expiry_date: "date",
    cost_factor: "integer",
    commissionrate: "float",
    commissionmethod: "string",
    status: "string",
    usageunit: "string",
    reorderlevel: "integer",
    website: "string",
    taxclass: "string",
    mfr_part_no: "string",
    vendor_part_no: "string",
    serialno: "string",
    qtyinstock: "integer",
    productsheet: "string",
    qtyindemand: "integer",
    glacct: "string",
    vendor_id: "string",
    currency: "string",
    is_subproducts_viewable: true,
};

const productLabels = {
    product_name: ["product_name", "Product Name", "Name", "Item Name"],
    price: ["price", "Price", "Product Price", "Cost"],
    product_code: ["product_code", "Product Code", "Code", "Item Code"],
    product_category: ["product_category", "Product Category", "Category", "Item Category"],
    manufacturer: ["manufacturer", "Manufacturer", "Maker", "Producer"],
    qty_per_unit: ["qty_per_unit", "Quantity per Unit", "Quantity", "Qty/Unit"],
    unit_price: ["unit_price", "Unit Price", "Price per Unit", "Cost per Unit"],
    weight: ["weight", "Weight", "Product Weight", "Mass"],
    pack_size: ["pack_size", "Pack Size", "Package Size", "Package Quantity"],
    sales_start_date: ["sales_start_date", "Sales Start Date", "Start Date", "Sale Start"],
    sales_end_date: ["sales_end_date", "Sales End Date", "End Date", "Sale End"],
    start_date: ["start_date", "Start Date", "Beginning Date", "Commencement Date"],
    expiry_date: ["expiry_date", "Expiry Date", "Expiration Date", "End Date"],
    cost_factor: ["cost_factor", "Cost Factor", "Factor", "Cost Element"],
    commissionrate: ["commissionrate", "Commission Rate", "Rate", "Commission Percentage"],
    commissionmethod: ["commissionmethod", "Commission Method", "Method", "Commission Type"],
    status: ["status", "Status", "Product Status", "Current Status"],
    usageunit: ["usageunit", "Usage Unit", "Unit", "Usage"],
    reorderlevel: ["reorderlevel", "Reorder Level", "Level", "Reorder Point"],
    website: ["website", "Website", "Site", "Web Page"],
    taxclass: ["taxclass", "Tax Class", "Class", "Tax Category"],
    mfr_part_no: ["mfr_part_no", "Manufacturer Part Number", "Mfr Part No", "Part Number"],
    vendor_part_no: ["vendor_part_no", "Vendor Part Number", "Vendor Part No", "Vendor Number"],
    serialno: ["serialno", "Serial Number", "Serial No", "Serial"],
    qtyinstock: ["qtyinstock", "Quantity in Stock", "Stock Quantity", "Stock"],
    productsheet: ["productsheet", "Product Sheet", "Sheet", "Info Sheet"],
    qtyindemand: ["qtyindemand", "Quantity in Demand", "Demand Quantity", "Demand"],
    glacct: ["glacct", "GL Account", "Account", "General Ledger Account"],
    vendor_id: ["vendor_id", "Vendor ID", "Vendor", "Supplier ID"],
    currency: ["currency", "Currency", "Money", "Currency Type"],
    is_subproducts_viewable: ["is_subproducts_viewable", "Subproducts Viewable", "Viewable", "Subproduct Visibility"],
};

const services = {
    name: "string",
    sales_start_date: "date",
    sales_end_date: "date",
    description: "string",
    category: "string",
    price: "float",
    image: "string",
    status: "string",
};

const serviceLabels = {
    name: ["name", "Name", "Service Name", "Service"],
    sales_start_date: ["sales_start_date", "Sales Start Date", "Start Date", "Sale Start"],
    sales_end_date: ["sales_end_date", "Sales End Date", "End Date", "Sale End"],
    description: ["description", "Description", "Details", "Service Description"],
    category: ["category", "Category", "Service Category", "Type"],
    price: ["price", "Price", "Service Price", "Cost"],
    image: ["image", "Image", "Picture", "Service Image"],
    status: ["status", "Status", "Service Status", "Current Status"],
};

const lecturers = {
	first_name: "string",
	last_name: "string",
	contact: "string",
	email: "string",
	started_in: "string",
	location: "string",
	city: "string",
	address: "string",
	postalcode: "string",
	country: "string",
	description: "string",
};

const lecturersLabels = {
    first_name: ["first_name", "First Name", "Given Name", "Forename"],
    last_name: ["last_name", "Last Name", "Surname", "Family Name"],
    contact: ["contact", "Contact", "Contact Number", "Phone"],
    email: ["email", "Email", "Email Address", "Electronic Mail"],
    started_in: ["started_in"],
    location: ["location", "Location", "Place", "Area"],
    city: ["city", "City", "Town", "Municipality"],
    address: ["address", "Address", "Street Address", "Location"],
    postalcode: ["postalcode", "Postal Code", "ZIP Code", "Postcode"],
    country: ["country", "Country", "Nation", "State"],
    description: ["description", "Description", "Details", "Vendor Description"],
};

const activities = {};

export {
	enrollments,
	advertisements,
	contacts,
	opportunities,
	products,
	services,
	lecturers,
	activities,

    enrollmentsLabels,
    advertisementLabels,
    contactLabels,
    opportunityLabels,
    productLabels,
    serviceLabels,
    lecturersLabels
};
