import React from "react";
import { Link } from "react-router-dom";

const SideBarHeader = ({title}) => {
  return <li className="menu-title">{title}</li>;
};

const SideBarItem = ({text, icon, route, pathname})=> {
  return (
    <li className={pathname === route ? "active" : ""}>
      <Link to={route}>
        {icon}
        {text}
      </Link>
    </li>
  );
};

export { SideBarHeader, SideBarItem };