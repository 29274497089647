import {useEffect, useState} from "react";
import api from "../api/axios";
import { useStateContext } from "../context/ContextProvider";

const GetUser = () => {
    const {setUser} = useStateContext(null);

    useEffect(() => {
        api.get("/user")
            .then((userData) => {
                setUser(userData.data);
            })
            .catch((error) => {
                console.error("Error fetching data", error.response);
            });
    }, [setUser]);
}

const GetUserRoles = ({ organization_id }) => {
    const [userRoles, setUserRoles] = useState([]);

    useEffect(() => {
        const fetchUserRoles = async () => {
            try {
                const response = await api.get(`/organizations/${organization_id}/user-roles`);
                setUserRoles(response.data.user_roles);
            } catch (error) {
                console.error("Error fetching user roles", error.response);
            }
        };

        fetchUserRoles();
    }, [setUserRoles, organization_id]);

    return userRoles;
};


const GetCompanies = () => {
    const { user, setUser } = useStateContext();
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        api.get("/user")
            .then((userData) => {
                setUser(userData.data);
            })
            .catch((error) => {
                console.error("Error fetching data", error.response);
            });
    }, [setUser]);

    useEffect(() => {
        const fetchUserAndCompanies = async () => {
            try {
                const response = await api.get(`/organizations/user-organization/${user.id}`);
                setUserData(response.data);
            } catch (error) {
                console.error("Error fetching user and organizations", error.response);
            }
        };

        fetchUserAndCompanies();
    }, [user.id, setUserData]);

    return userData;
};

const StoreUserRole = ({ organization_id }) => {
    const { user } = useStateContext();

    const data = new FormData();
    data.append("user_id", user.id);
    data.append("organization_id", organization_id);
    data.append("role", "employee");
    data.append("title", "CEO");

    api.post('/organizations/user-roles', data)
        .then((response) => {
            console.log(response.data); // Handle success
        })
        .catch((error) => {
            console.error("Error storing user role", error.response); // Handle error
        });
}

const useUserAndCompanyInformation = () => {
    const [companyInformation, setCompanyInformation] = useState({});
    const { user, setUser } = useStateContext();
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const response = await api.get("/user");
                setUser(response.data);
            } catch (error) {
                console.error("Error fetching data", error.response);
            }
        };

        fetchUserDetails();
    }, [setUser]);

    useEffect(() => {
        const fetchUserAndCompanies = async () => {
            try {
                const response = await api.get(`/organizations/user-organization/${user.id}`);
                setUserData(response.data);
            } catch (error) {
                console.error("Error fetching user and organizations", error.response);
            }
        };

        if (user && user.id) {
            fetchUserAndCompanies();
        }
    }, [user]);

    useEffect(() => {
        if (userData && userData.organizations) {
            console.log("Your Organisation is:", userData.organizations[0]);
            setCompanyInformation(userData.organizations[0]);
        }
    }, [userData, setCompanyInformation]);

    return { companyInformation, user, userData };
};

export {GetUser, GetUserRoles, GetCompanies, StoreUserRole, useUserAndCompanyInformation};