import React, { useEffect, useState } from "react";
import EnrollStudent from "./enrollStudent";
import api from "../../api/axios";
import Preloader from "../preloader";
import Table from "./table";
import CsvUploader from "../../utils/csvreader";
import { enrollments, enrollmentsLabels } from "../../constants/tableFields";

const EnrollmentsTable = ({ enrollments }) => {
	const route = "enrollments";
	const columnNames = [
		"admission_no",
		"first_name",
		"surname",
		"phone",
		"email",
		"city",
		"course enrolled",
		"date enrolled",
		"exam_body",
		"passport",
		"gender",
	];
	const columnValues = [
		"admission_no",
		"first_name",
		"surname",
		"phone",
		"email",
		"city",
		"course",
		"created_at",
		"exam_body",
		"passport",
		"gender",
	];

	const headingStyle = {
		fontSize: "medium",
		fontWeight: "600",
		fontFamily: "Arial, Helvetica, sans-serif",
		margin: "10px 0",
	};

	const args = {
		columnNames: columnNames,
		columnValues: columnValues,
		route: route,
	};

	return (
		<div>
			{enrollments ? (
				<div className="row">
					<p style={headingStyle}>Enrollments</p>
					<Table {...args} data={enrollments} />
				</div>
			) : (
				<p>You have no enrollments.</p>
			)}
		</div>
	);
};

const Enrollments = () => {
	const [user, setUser] = useState();
	const [view, setView] = useState();
	const [load, setLoad] = useState(true);

	useEffect(() => {
		api.get("/user")
			.then(({ data: userData }) => {
				setUser(userData.id);
			})
			.catch((error) => {
				console.error("Error fetching data", error.response);
			});
	}, []);

	useEffect(() => {
		const handleDataFetch = async () => {
			try {
				const response = await api.get(`/enrollments`);
				setView(response.data.enrollments.data);
			} catch (error) {
				console.error("Error fetching data", error.response);
			} finally {
				setLoad(false);
			}
		};

		if (user) {
			handleDataFetch();
		}
	}, [user]);

	const onPageChange = () => {
		console.log("Get next items");
	};

	return load ? (
		<Preloader />
	) : (
		<div className="campaigns-section">
			<div className="container">
				<div className="d-flex flex-row">
					<h4 className="mr-1">Analysis</h4>
					<h4 className="mr-1">{">"}</h4>
					<h4 className="mr-1">
						<b>Enrollments</b>
					</h4>
				</div>
				<div className="row">
					<div
						className="col-lg-12 d-flex flex-row justify-content-between align-items-center"
						style={{ margin: "15px 0px 15px 0px" }}
					>
						<h2 className="page-titles">Enrollments</h2>
						<div className="d-flex flex-row">
							<EnrollStudent userId={user} />
						</div>
					</div>
					<div className="col-lg-12">
						<EnrollmentsTable enrollments={view} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Enrollments;
