import CreateOpportunityButton from "./createOpportunities";
import React, { useEffect, useState } from "react";
import api from "../../api/axios";
import Preloader from "../preloader";
import Table from "./table";
import CsvUploader from "../../utils/csvreader";
import { opportunities, opportunityLabels } from "../../constants/tableFields";

const FinancialsTable = ({ organizations }) => {
	const route = "opportunities";
	const columnNames = [
		"Image",
		"Title",
		"Description",
		"Expected Revenue",
		"Close Date",
		"Probability",
		"Status",
	];
	const columnValues = [
		"image",
		"title",
		"description",
		"expected_revenue",
		"close_date",
		"probability",
		"status",
	];

	const headingStyle = {
		fontSize: "medium",
		fontWeight: "600",
		fontFamily: "Arial, Helvetica, sans-serif",
		margin: "10px 0",
	};

	const args = {
		columnNames: columnNames,
		columnValues: columnValues,
		route: route,
	};

	return (
		<div>
			{organizations && organizations.length > 0 ? (
				organizations.map((organization) => (
					<div className="row" key={organization.id}>
						<p style={headingStyle}>{organization.organization}</p>
						<Table {...args} data={organization.opportunities} />
					</div>
				))
			) : (
				<p>You are currently not registered in an organization.</p>
			)}
		</div>
	);
};

const Financials = () => {
	const [user, setUser] = useState();
	const [view, setView] = useState({});
	const [load, setLoad] = useState(true);

	useEffect(() => {
		api.get("/user")
			.then(({ data: userData }) => {
				setUser(userData.id);
			})
			.catch((error) => {
				console.error("Error fetching data", error.response);
			});
	}, []);

	useEffect(() => {
		const handleDataFetch = async () => {
			try {
				const response = await api.get(
					`/users-data/opportunities/${user}`
				);
				setView(response.data.data);
				console.log(response.data.data);
			} catch (error) {
				console.error("Error fetching data", error.response);
			} finally {
				setLoad(false);
			}
		};

		if (user) {
			handleDataFetch();
		}
	}, [user]);

	const onCreateopportunities = () => {
		console.log("Lead being created!");
	};

	const onPageChange = () => {
		console.log("Get next items");
	};

	return load ? (
		<Preloader />
	) : (
		<div className="campaigns-section">
			<div className="container">
				<div className="d-flex flex-row">
					<h4 className="mr-1">Inventory</h4>
					<h4 className="mr-1">{">"}</h4>
					<h4 className="mr-1">
						<b>Opportunities</b>
					</h4>
				</div>
				<div className="row">
					<div
						className="col-lg-12 d-flex flex-row justify-content-between align-items-center"
						style={{ margin: "15px 0px 15px 0px" }}
					>
						<h2 className="page-titles">Opportunities</h2>
						<div className="d-flex flex-row">
							<CsvUploader
								columns={opportunities}
								columnLabels={opportunityLabels}
								user={user}
								route={"opportunities"}
							/>
							<CreateOpportunityButton userId={user} />
						</div>
					</div>
					<div className="col-lg-12">
						<FinancialsTable organizations={view} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Financials;
