import React, { useState, useEffect } from "react";
import timezones from "../constants/timezones";
import api from "../api/axios";

import { Autocomplete, TextField } from "@mui/material";

const UserInformation = ({ onNextStep }) => {
    const [user, setUser] = useState({});
    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        city: "",
        country: "",
        postal_code: "",
    });

    useEffect(() => {
        api.get(`/ferxity-user/`)
        .then(({ data: userDetailData }) => {
            setUser(userDetailData.user);
            setFormData(userDetailData.user);
        })
        .catch((error) => {
            console.error("Error fetching data", error.response);
        });
    }, []);

    const handleSkip = () => {
        onNextStep(1);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await api.put(`/ferxity-user/`, formData);
            console.log("User data updated successfully:", response.data);

            onNextStep(1);
        } catch (error) {
            console.error("Unable to update user details", error);
        }
    };

    const handleFormChange = (field, value) => {
        setFormData((prevData) => ({ ...prevData, [field]: value }));
    };

    return (
        <form className="contact-form w-100 validate-form" onSubmit={handleSubmit}>
            <div className="position-absolute back-to-home">
                <a href="/">{"> finish up later"}</a>
            </div>
            <span className="contact-form-title pb-3">Your Personal Information</span>
            <div
                className="wrap-input rs1-wrap-input validate-input"
                data-validate="Please enter a username: eg. company-user"
            >
                <span className="label-input">Enter First Name</span>
                <input
                    className="input"
                    id="first_name"
                    name="first_name"
                    type="text"
                    value={formData.first_name}
                    onChange={(e) => handleFormChange("first_name", e.target.value)}
                    required
                    placeholder="Enter first name"
                />
                <span className="focus-input"></span>
            </div>
            <div
                className="wrap-input rs1-wrap-input validate-input"
                data-validate="Please enter a username: eg. company-user"
            >
                <span className="label-input">Enter Last Name</span>
                <input
                    className="input"
                    id="last_name"
                    name="last_name"
                    type="text"
                    value={formData.last_name}
                    onChange={(e) => handleFormChange("last_name", e.target.value)}
                    required
                    placeholder="Enter last name"
                />
                <span className="focus-input"></span>
            </div>
            <div
                className="wrap-input rs1-wrap-input validate-input"
                data-validate="Please enter a username: eg. company-user"
            >
                <span className="label-input">Enter Communication Email</span>
                <input
                    className="input"
                    id="email"
                    type="email"
                    value={formData.email}
                    onChange={(e) => handleFormChange("email", e.target.value)}
                    required
                    placeholder="Enter your email"
                />
                <span className="focus-input"></span>
            </div>
            <div
                className="wrap-input rs1-wrap-input validate-input"
                data-validate="Please enter a valid contact: eg. +254 7123 45678"
            >
                <span className="label-input">Enter Phone</span>
                <input
                    className="input"
                    id="phone"
                    name="phone"
                    type="text"
                    value={formData.phone}
                    onChange={(e) => handleFormChange("phone", e.target.value)}
                    placeholder="Enter your phone"
                />
                <span className="focus-input"></span>
            </div>
            <div className="d-flex flex-row w-100" style={{ gap: "20px" }}>
                <div
                    className="wrap-input rs1-wrap-input validate-input"
                    data-validate="Please enter a city: eg. Nairobi"
                >
                    <span className="label-input">Enter City</span>
                    <input
                        className="input"
                        id="city"
                        name="city"
                        type="text"
                        value={formData.city}
                        onChange={(e) => handleFormChange("city", e.target.value)}
                        placeholder="Enter your city"
                    />
                    <span className="focus-input"></span>
                </div>
                <div
                    className="wrap-input rs1-wrap-input validate-input"
                    data-validate="Please enter postal code: eg. 00000"
                >
                    <span className="label-input">Enter Postal Code</span>
                    <input
                        className="input"
                        id="postal_code"
                        name="postal_code"
                        type="text"
                        value={formData.postal_code}
                        onChange={(e) => handleFormChange("postal_code", e.target.value)}
                        placeholder="Enter your postal code"
                    />
                    <span className="focus-input"></span>
                </div>
            </div>
            <div className="wrap-input rs1-wrap-input validate-input">
                <span className="label-input">Select a timezome</span>
                <Autocomplete
                    fullWidth
                    options={timezones}
                    defaultValue={"(UTC+03:00) Nairobi"}
                    getOptionLabel={(timezone) => timezone}
                    value={timezones.find((timezone) => timezone === formData.timezone) || null}
                    onChange={(e, newValue) => handleFormChange("timezone", newValue ? newValue : "")}
                    style={{ border: "none" }}
                    sx={{
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                            border: "none",
                        },
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            className="input"
                            name="timezone"
                            InputProps={{
                                ...params.InputProps,
                                className: "",
                                style: {
                                    ...params.InputProps.style,
                                },
                            }}
                        />
                    )}
                />
            </div>
            <div className="d-flex dlex-row justify-content-between w-100">
                <button type="button" className="nav-sign left-btn" onClick={handleSkip}>
                    Skip
                </button>
                <button type="submit" className="nav-sign active">
                    Continue
                </button>
            </div>
        </form>
    );
};

export default UserInformation;
