import React, { useState } from "react";
import { TextField, Autocomplete } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FaSearch } from "react-icons/fa";

const SearchBar = () => {
	const searchLinks = {};
	const [searchQuery, setSearchQuery] = useState("");
	const [searchResults, setSearchResults] = useState([]);
	const [noOptionsMessage, setNoOptionsMessage] = useState("");

	const navigate = useNavigate();

	const handleInputChange = (event, value) => {
		setSearchQuery(value);

		// const filteredResults = searchLinks.reduce((acc, category) => {
		// 	const categoryResults = Object.entries(category).filter(([name]) =>
		// 		name.toLowerCase().includes(value.toLowerCase())
		// 	);
		// 	return acc.concat(categoryResults);
		// }, []);
		// setSearchResults(filteredResults);

		// setNoOptionsMessage(
		// 	filteredResults.length === 0
		// 		? "No options available for the above query"
		// 		: ""
		// );
	};

	const handleResultClick = (name, path) => {
		for (const routesObj of searchLinks) {
			if (routesObj[name]) {
				const routePath = routesObj[name];
				navigate(routePath);
				setSearchQuery("");
				setSearchResults([]);
				return;
			}
		}
	};

	const handleSearchOnEnter = (event) => {
		if (event.key === "Enter") {
			event.preventDefault();
			if (searchResults.length === 0) {
				console.log("No options available for the above query");
				setNoOptionsMessage("No options available for the above query");
			} else {
				const [name, path] = searchResults[0];
				handleResultClick(name);
			}
		}
	};

	return (
		<div>
			<Autocomplete
				freeSolo
				className="searchbar"
				options={searchResults.map(([name, path]) => name)}
				inputValue={searchQuery}
				autoComplete={false}
				onInputChange={handleInputChange}
				renderOption={(props, option) => (
					<li
						{...props}
						onClick={() =>
							handleResultClick(option, searchLinks[option])
						}
					>
						{option}
					</li>
				)}
				renderInput={(params) => (
					<TextField
						{...params}
						placeholder="Search for..."
						variant="outlined"
						fullWidth
						// autoComplete={false}
						onKeyPress={handleSearchOnEnter}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<button type="submit">
									<FaSearch
										className="icon"
										size={15}
										color="orange"
									/>
								</button>
							),
							style: {
								borderRadius: "20px",
								minWidth: "35vw",
							},
						}}
					/>
				)}
			/>
			{noOptionsMessage && (
				<p className="position-fixed">{noOptionsMessage}</p>
			)}
		</div>
	);
};

const TableTop = () => {
	return (
		<div className="col-lg-12">
			<div className="d-flex flex-row justify-content-between">
				<div>
					<SearchBar />
				</div>
				<div
					className="d-flex flex-row justify-content-between"
					style={{ width: "200px" }}
				>
					<div className="card-btn">
						<p>filter</p>
					</div>
					<div className="card-btn">
						<p>Newest First</p>
					</div>
				</div>
			</div>
		</div>
	);
};

const TableContent = ({ data, columnNames, columnValues, route }) => {
	const navigate = useNavigate();
	const numColumns = columnNames.length;
	const imageWidth = 70;
	const minWidth = 120;

	const handleViewProduct = (itemId) => {
		navigate(`/${route}/${itemId}`);
	};

	const getCellWidth = () => {
		const availableWidth = window.innerWidth;
		const calculatedWidth = Math.min(availableWidth / (numColumns + 1), minWidth);
		return calculatedWidth;
	};

	return (
		<div className="col-lg-12 mt-3">
			<table style={{ width: "85vw" }}>
				<thead className="table-header-row flex-column">
					<tr className="d-flex justify-content-between">
						{columnNames.map((name, index) => (
							<React.Fragment key={index}>
								{name === "Image" ? (
									<th
										key={index}
										style={{
											width: `${imageWidth + 30}px`,
										}}
									>
										{name}
									</th>
								) : (
									<th
										key={index}
										style={{
											minWidth: `${minWidth}px`,
											width: `${getCellWidth()}px`,
										}}
									>
										{name}
									</th>
								)}
							</React.Fragment>
						))}
						<th
							style={{
								width: `100px`,
							}}
						>
							Actions
						</th>
					</tr>
				</thead>
				{data && data.length > 0 ? (
					<tbody className="w-100 table-body-row ml-3">
						{data.map((item, rowIndex) => (
							<tr
								className="d-flex justify-content-between"
								key={rowIndex}
							>
								{columnValues.map((col, colIndex) => (
									<React.Fragment key={colIndex}>
										{col === "image" ? (
											<td
												className="d-flex justify-content-center align-items-center"
												style={{
													width: `${
														imageWidth + 20
													}px`,
													minWidth: `${
														imageWidth + 20
													}px`,
												}}
											>
												{item.image ? (
													<img
														className="rounded-circle"
														src={`${process.env.REACT_APP_WEB_URL}/storage/${item.image}`}
														style={{
															height: "32px",
															width: "32px",
															position:
																"relative",
														}}
														alt=""
													/>
												) : (
													""
												)}
											</td>
										) : (
											<td
												style={{
													minWidth: `${minWidth}px`,
													width: `${getCellWidth()}px`,
												}}
											>
												{item[col]}
											</td>
										)}
									</React.Fragment>
								))}
								<td
									style={{
										minWidth: `${minWidth}px`,
										width: `${getCellWidth()}px`,
										marginRight: '30px'
									}}
								>
									<button
										className="btn"
										onClick={() =>
											handleViewProduct(item.id)
										}
									>
										View
									</button>
								</td>
							</tr>
						))}
					</tbody>
				) : (
					<tbody>
						<tr>
							<td colSpan={columnNames.length + 1}>
								<p>No data available.</p>
							</td>
						</tr>
					</tbody>
				)}
			</table>
		</div>
	);
};

const Table = (args) => {
	return (
		<div className="table-card">
			<div className="row table-top">
				<TableTop />
			</div>
			<div className="row table-content">
				<TableContent {...args} />
			</div>
		</div>
	);
};

export default Table;
