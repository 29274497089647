import React from "react";
import { useStateContext } from "./ContextProvider";
import { Navigate, useLocation } from "react-router-dom";

function DefaultContext() {
    const { token } = useStateContext();
    const location = useLocation();

    if ((location.pathname === "/login" || location.pathname === '/') && token) {
        return <Navigate to="/dashboard" />;
    }

    return null;
}

function UserContext() {
    const { token } = useStateContext();

    if (!token) {
        return <Navigate to="/login" />;
    }

    return null;
}

export { DefaultContext, UserContext };
