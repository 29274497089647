import React, { useState, useEffect } from "react";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";

import { useAside } from "../../context/asideContext";
import { userRoutes, searchLinks } from "../../constants/routes";
import { useStateContext } from "../../context/ContextProvider";
import api from "../../api/axios";

import { TextField, Autocomplete } from "@mui/material";

import { sidebarIcons, topbarIcons } from "../../constants/icons";
import { FaCheck, FaSearch } from "react-icons/fa";
import { SideBarHeader, SideBarItem } from "./sideBarItem";

const defaulProfile = require("../../assets/images/profile.png");
const logo = require("../../assets/images/cremimic_logo.png");

const NotificationCard = ({ notifications }) => {
  return (
    <div>
      {Array.isArray(notifications) && notifications.length > 0 ? (
        notifications.map((notification) => (
          <div className="notification-card " key={notification.id}>
            <p>{notification.message}</p>
          </div>
        ))
      ) : (
        <p>You currently have 0 Notifications</p>
      )}
    </div>
  );
};

const SearchBar = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [noOptionsMessage, setNoOptionsMessage] = useState("");

  const navigate = useNavigate();

  const handleInputChange = (event, value) => {
    setSearchQuery(value);

    const filteredResults = searchLinks.reduce((acc, category) => {
      const categoryResults = Object.entries(category).filter(([name]) =>
        name.toLowerCase().includes(value.toLowerCase())
      );
      return acc.concat(categoryResults);
    }, []);
    setSearchResults(filteredResults);

    setNoOptionsMessage(
      filteredResults.length === 0 ? "Query does not exist" : ""
    );
  };

  const handleResultClick = (name, path) => {
    for (const routesObj of searchLinks) {
      if (routesObj[name]) {
        const routePath = routesObj[name];
        navigate(routePath);
        setSearchQuery("");
        setSearchResults([]);
        return;
      }
    }
  };

  const handleSearchOnEnter = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (searchResults.length === 0) {
        console.log("No options available for the above query");
        setNoOptionsMessage("No options available for the above query");
      } else {
        const [name, path] = searchResults[0];
        handleResultClick(name);
      }
    }
  };

  return (
    <div>
      <Autocomplete
        freeSolo
        className="searchbar"
        options={searchResults.map(([name, path]) => name)}
        inputValue={searchQuery}
        autoComplete={false}
        onInputChange={handleInputChange}
        renderOption={(props, option) => (
          <li
            {...props}
            onClick={() => handleResultClick(option, searchLinks[option])}
          >
            {option}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search for..."
            variant="outlined"
            fullWidth
            // autoComplete={false}
            onKeyPress={handleSearchOnEnter}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <button type="submit">
                  <FaSearch className="icon" size={15} color="orange" />
                </button>
              ),
              style: {
                borderRadius: "20px",
                width: "35vw",
              },
            }}
          />
        )}
      />
      {noOptionsMessage && <p className="position-fixed">{noOptionsMessage}</p>}
    </div>
  );
};

const Sidebar = ({ isClosed, shouldRenderText }) => {
  const location = useLocation();
  const pathname = location.pathname;
  const [isAdmin, setIsAdmin] = useState(false);
  const renderText = (text) => (shouldRenderText ? text : null);

  useEffect(() => {
    api
      .get(`/user-roles`)
      .then((response) => {
        if (response.data.is_admin == true) {
          setIsAdmin(true);
        }
      })
      .catch((error) => {
        console.error("Error fetching data", error.response);
      });
  }, []);

  return (
    <aside
      id="left-pannel"
      className={`left-pannel align-self-center ${
        isClosed ? "closed" : "open"
      }`}
    >
      <nav className="sidebar">
        <div id="main-menu" className="main-menu">
          <ul className="sidebar-nav d-flex flex-column">
            <li
              className="mb-3"
              style={{ position: "relative", bottom: "10px" }}
            >
              <div className="menu-toggle-wrapper d-flex flex-row justify-content-center align-items-center hide-logo">
                {/* {!isClosed && (
                  <Link to={userRoutes.Dashboard} className="logo col-md-11">
                    <img
                      src={logo}
                      alt="Cremimic IT Management"
                      width={"55%"}
                      style={{ marginLeft: "20px" }}
                    />
                  </Link>
                )} */}
              </div>
            </li>

            <SideBarItem
              text={renderText("Dashboard")}
              icon={sidebarIcons.dashboard}
              route={userRoutes.Dashboard}
              pathname={pathname}
            />
{/* 
            <SideBarItem
              text={renderText("Calendar")}
              icon={sidebarIcons.calendar}
              route={userRoutes.Planner}
              pathname={pathname}
            />
 */}
            {isAdmin ? (
              <>
                <SideBarHeader title={renderText("Analysis")} />
                <SideBarItem
                  text={renderText("Courses")}
                  icon={sidebarIcons.courses}
                  route={userRoutes.Courses}
                  pathname={pathname}
                />
                <SideBarItem
                  text={renderText("Lecturers")}
                  icon={sidebarIcons.lecturers}
                  route={userRoutes.Lecturers}
                  pathname={pathname}
                />
                <SideBarItem
                  text={renderText("Admissions")}
                  icon={sidebarIcons.admissions}
                  route={userRoutes.Admissions}
                  pathname={pathname}
                />
              </>
            ) : (
              ""
            )}
            <SideBarHeader title={renderText("Admissions")} />
            <SideBarItem
              text={renderText("Enrollments")}
              icon={sidebarIcons.enrollments}
              route={userRoutes.Enrollments}
              pathname={pathname}
            />

            {/* <SideBarHeader title={renderText("Academic")} />
            <SideBarItem
              text={renderText("Attendance")}
              icon={sidebarIcons.attendance}
              route={userRoutes.Attendance}
              pathname={pathname}
            />
            <SideBarItem
              text={renderText("Results")}
              icon={sidebarIcons.results}
              route={userRoutes.Results}
              pathname={pathname}
            />

            <SideBarHeader title={renderText("Financials")} />
            <SideBarItem
              text={renderText("Fee Arrears")}
              icon={sidebarIcons.feeArrears}
              route={userRoutes.Financials}
              pathname={pathname}
            /> */}

            <SideBarHeader title={renderText("Settings")} />
            <SideBarItem
              text={renderText("Settings")}
              icon={sidebarIcons.settings}
              route={userRoutes.Settings}
              pathname={pathname}
            />
          </ul>
        </div>
      </nav>
    </aside>
  );
};

const TopBar = ({ handleToggleMenu }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const { setUser, setToken } = useStateContext();
  const [notifications, setNotifications] = useState(null);
  const [user, setUserData] = useState();

  useEffect(() => {
    api
      .get(`/ferxity-user`)
      .then(({ data: userDetailData }) => {
        setUserData(userDetailData.user);
      })
      .catch((error) => {
        console.error("Error fetching data", error.response);
      });
    api
      .get(`/notifications`)
      .then(({ data: userNotifications }) => {
        setNotifications(userNotifications.data);
      })
      .catch((error) => {
        console.error("Error fetching user notifications -> ", error.response);
      });
  }, []);

  const profileImage = user?.image
    ? `${process.env.REACT_APP_WEB_URL}${user.image}`
    : null;
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleLogout = async (e) => {
    e.preventDefault();

    api.post("/logout").then(() => {
      setUser({});
      setToken(null);
    });
  };

  return (
    <header
      id="header"
      className="top-bar d-flex flex-row justify-content-between"
    >
      <div className="d-flex flex-row align-self-center">
        <button className="mt-2" id="menuToggle" onClick={handleToggleMenu}>
          {sidebarIcons.toggle}
        </button>
      </div>
      <div className="top-center d-flex flex-row align-self-center">
        <SearchBar />
      </div>
      <div className="top-right d-flex flex-row align-self-end">
        <div className="top-bar-menu">
          <div className="top-bar-left">
            <div className="dropdown for-notification">
              <button
                className="btn dropdown-toggle"
                type="button"
                id="notification"
                onFocus={() => setShowMenu(!showMenu)}
                onBlur={() => setShowMenu(false)}
                data-toggle="dropdown"
                aria-expanded="false"
              >
                {topbarIcons.notifications}
                <span className="count bg-danger">
                  {notifications ? notifications.length : 0}
                </span>
              </button>
              <div
                className={showMenu ? "dropdown-menu show" : "dropdown-menu"}
                aria-labelledby="notification"
              >
                <p className="red">
                  {notifications ? notifications.length : 0} Notifications
                </p>
                <div className="w-80 flex flex-column wrap">
                  {Array.isArray(notifications) && notifications.length > 0 ? (
                    notifications.map((notification) => (
                      <button className="dropdown-item media rounded-lg" href="#">
                        <FaCheck size={25} className="i" />
                        <div className="text-wrap ml-2" key={notification.id}>
                        <p>{notification.message}</p>
                      </div>
                    </button>
                    ))
                  ) : (
                    <p>You currently have 0 Notifications</p>
                  )}
                </div>


              </div>
            </div>
          </div>

          <div
            className="user-area dropdown float-right"
            onClick={toggleDropdown}
          >
            <button
              className="dropdown-toggle active"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img
                className="user-avatar rounded-circle"
                src={user?.image ? profileImage : defaulProfile}
                alt="User"
              />
            </button>
            {showDropdown && (
              <div
                className="user-menu dropdown-menu show"
                x-placement="bottom-start"
                style={{
                  position: "absolute",
                  willChange: "transform",
                  transform: "translate3d(-50%, 50px, 0px)",
                }}
              >
                <a href="/settings" className="nav-link">
                  {topbarIcons.profile_link}
                  My Profile
                </a>

                <a className="nav-link" href="/notifications">
                  {topbarIcons.notifications_link}
                  Notifications <span className="count">0</span>
                </a>

                <a className="nav-link" href="/settings">
                  {topbarIcons.settings_link}
                  Settings
                </a>

                <a className="nav-link" onClick={handleLogout} href="/">
                  {topbarIcons.logout_link}
                  Logout
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

const Pannels = () => {
  const { isClosed, toggleMenu } = useAside();
  const [shouldRenderText, setShouldRenderText] = useState(false);

  const handleToggleMenu = () => {
    toggleMenu();
    setShouldRenderText(false);
    setTimeout(() => {
      setShouldRenderText(isClosed);
    }, 500);
  };

  return (
    <div>
      <div className={`left-pannel ${isClosed ? "closed" : "open"}`}>
        <Sidebar
          isClosed={isClosed}
          handleToggleMenu={handleToggleMenu}
          shouldRenderText={shouldRenderText}
        />
      </div>
      <div className={`right-pannel ${!isClosed ? "right-pannel-min" : ""}`}>
        <TopBar handleToggleMenu={handleToggleMenu} />
        <div className="contents">
          <div>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pannels;
