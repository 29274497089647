import React from "react";
import {
  AiOutlineDatabase,
  AiOutlineSchedule,
  AiOutlineLineChart,
  AiOutlineRobot,
} from "react-icons/ai";

const FeatureCard = ({ icon, title, content }) => {
  return (
    <div className="content-card d-flex flex-column active">
      <div className="card-icon">{icon}</div>
      <div className="card-title">
        <h2>{title}</h2>
      </div>
      <div className="card-content">
        <p>{content}</p>
      </div>
    </div>
  );
};

const featureItems = [
  {
    icon: <AiOutlineDatabase />,
    title: "Comprehensive Student & Faculty Data Management",
    content:
      "Centralize and manage all student and faculty data, including personal details, academic records, class schedules, attendance, performance, and communication history.",
  },
  {
    icon: <AiOutlineSchedule />,
    title: "Efficient Academic & Administrative Planning",
    content:
      "Streamline scheduling with a modern planner for academic calendars, exam schedules, faculty timetables, and institutional events, ensuring optimized resource allocation.",
  },
  {
    icon: <AiOutlineLineChart />,
    title: "Real-Time Reporting & Analytics",
    content:
      "Generate real-time reports on student performance, attendance, faculty workload, and financials, providing actionable insights for informed decision-making.",
  },
  // {
  //     icon: <AiOutlineRobot />,
  //     title: "AI-Driven Insights:",
  //     content:
  //         "Harness the power of AI to provide strategic insights, comparing planned activities with actual performance, analyzing reports, and offering valuable recommendations for enhanced customer satisfaction and increased sales.",
  // },
];

const OurFeatures = () => {
  return (
    <div id="features" className="our-features" data-scroll-index="2">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-12 align-self-center wow fadeInRight animated">
                <div className="about-content">
                  <h3>Our Features</h3>
                  <h2>
                    Providing Solutions for Streamlined Education Management
                  </h2>
                  <p>
                    Unlock your institution's potential with EduManage: from
                    admissions to alumni tracking, all powered by smart
                    automation and data-driven insights. Simplify operations and
                    enhance academic performance. It's not just what you want,
                    it's what your institution needs.
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex flex-row justify-content-around flex-wrap">
              {featureItems.map((item, index) => (
                <div className="col-lg-3 ml-3 mr-3" key={index}>
                  <FeatureCard
                    icon={item.icon}
                    title={item.title}
                    content={item.content}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurFeatures;
