import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import CustomDialog from "../dialog";
import { TextFieldComponent, SelectField, NumberField } from "../formFields";

import { TextField } from "@mui/material";

import api from "../../../api/axios";

const CreateCourseButton = ({ userId }) => {
  const [open, setOpen] = useState(false);
  const [statusOptions] = useState(["active", "inactive"]);
  const [formData, setFormData] = useState({
    name: "",
    code: "",
    price: "",
    duration: "",
    description: "",
    max_capacity: "",
    min_capacity: "",
    capacity: "",
    image: null,
    status: "",
    errorMessage: "",
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFormData((prevData) => ({ ...prevData, errorMessage: "" }));
  };

  const handleFormChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({ ...prevData, file: file }));
  };

  const handleCreateCourse = async () => {
    const requiredFields = ["name", "code", "price", "duration"];
    const hasEmptyFields = requiredFields.some((field) => !formData[field]);

    if (hasEmptyFields) {
      setFormData((prevData) => ({
        ...prevData,
        errorMessage: "Please fill all fields.",
      }));
      return;
    }

    try {
      const response = await api.post(`/courses`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("Course created successfully:", response.data);
    } catch (error) {
      console.error("Unable to create course", error);
    }

    handleClose();
  };

  return (
    <div>
      <button className="btn upload-btn" onClick={handleOpen}>
        + Add New Course
      </button>
      <CustomDialog
        isOpen={open}
        onClose={handleClose}
        event={{ type: "Add a Course" }}
        user={{ id: userId }}
        handleSubmit={handleCreateCourse}
      >
        <div className="d-flex flex-column" style={{ gap: "30px" }}>
          <TextFieldComponent
            label="Course Name"
            field="name"
            value={formData.name}
            onChange={handleFormChange}
          />
          <TextFieldComponent
            label="Course Code"
            field="code"
            value={formData.code}
            onChange={handleFormChange}
          />
          <TextFieldComponent
            label="Course Cost (fees)"
            field="price"
            value={formData.price}
            onChange={handleFormChange}
          />
          <TextFieldComponent
            label="Course Duration (months)"
            field="duration"
            value={formData.duration}
            onChange={handleFormChange}
          />
          <TextFieldComponent
            label="Description"
            field="description"
            maxRows={3}
            value={formData.description}
            onChange={handleFormChange}
          />
          <NumberField
            label="Minimum Capacity"
            field="min_capacity"
            value={formData.min_capacity}
            onChange={handleFormChange}
          />
          <NumberField
            label="Maximum Capacity"
            field="max_capacity"
            value={formData.max_capacity}
            onChange={handleFormChange}
          />
          <SelectField
            label="Status"
            value={formData.status}
            options={statusOptions}
            onChange={(value) => handleFormChange("status", value)}
          />
          <TextField
            fullWidth
            type="file"
            accept="image/*"
            onChange={handleImageChange}
          />
        </div>
        {formData.errorMessage && (
          <div style={{ color: "red", marginTop: "10px" }}>
            {formData.errorMessage}
          </div>
        )}
      </CustomDialog>
    </div>
  );
};

export default CreateCourseButton;
