import React from "react";
import Lecturers from "../../components/user/lecturersComponents";

const LecturersPage = () => {
    return (
        <div>
            <Lecturers />
        </div>
    )
}

export default LecturersPage;