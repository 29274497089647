import React, { useState, useEffect } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox } from '@mui/material';
import api from "../../../api/axios";

const tableStyle = {
    body: {
        borderRadius: '12px',
        border: '1px solid #FC7703',
        padding: '0 10px'

    },
};

const PersonnelHub = ({ companyInformation }) => {
    const [personnel, setPersonnel] = useState({ admin: [], vice_admin: [], manager: [], employee: [] });
    const [selectedRows, setSelectedRows] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get(`/users-data/${companyInformation.organization_id}`);
                console.log(response.data);
                setPersonnel(response.data.data);
            } catch (error) {
                console.error("Error fetching data", error.response);
            }
        };
        fetchData();
    }, [companyInformation, setPersonnel]);

    const formatDate = (dateTimeString) => {
        const options = {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: true,
        };
      
        const formattedDate = new Date(dateTimeString).toLocaleDateString(undefined, options);
        return formattedDate;
    };

    const handleCheckboxChange = (personId) => {
        if (selectedRows.includes(personId)) {
            setSelectedRows(selectedRows.filter(id => id !== personId));
        } else {
            setSelectedRows([...selectedRows, personId]);
        }
    };

    const handleSelectAllCheckboxChange = (event) => {
        if (event.target.checked) {
            const allRows = []
            if (personnel.admin) allRows.push(...personnel.admin.map((person) => person.id ? person.id : null));
            if (personnel.vice_admin) allRows.push(...personnel.vice_admin.map((person) => person.id ? person.id : null));
            if (personnel.manager) allRows.push(...personnel.manager.map((person) => person.id ? person.id : null));
            if (personnel.employee) allRows.push(...personnel.employee.map((person) => person.id ? person.id : null));
            setSelectedRows(allRows.filter(id => id !== null)); // Filter out null values
        } else {
            setSelectedRows([]);
        }
    };

    return (
        <div>
            <h2 style={{ marginBottom: "5px" }}>Company Personnel</h2>
            <div className="row">
                <div className="col-md-12 position-relative">
                    {Object.keys(companyInformation).length > 0 && (
                        <>
                            <p style={{marginBottom: '30px'}}>{companyInformation.name}</p>
                            {Object.keys(personnel).map((group) => (
                                personnel[group].length > 0 && (
                                    <div key={group} className="table-responsive" style={{paddingRight: '30px'}}>
                                        <h4 style={{marginBottom: '10px'}}>{group.charAt(0).toUpperCase() + group.slice(1)}s</h4>
                                        <TableContainer component={Paper} style={tableStyle.body}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Checkbox
                                                                checked={selectedRows.length === personnel[group].length}
                                                                onChange={handleSelectAllCheckboxChange}
                                                            />
                                                        </TableCell>
                                                        <TableCell>Profile Image</TableCell>
                                                        <TableCell>First Name</TableCell>
                                                        <TableCell>Last Name</TableCell>
                                                        <TableCell>Role</TableCell>
                                                        <TableCell>Title</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {personnel[group].map((person) => (
                                                        <TableRow key={person.id}>
                                                            <TableCell>
                                                                <Checkbox
                                                                    checked={selectedRows.includes(person.id)}
                                                                    onChange={() => handleCheckboxChange(person.id)}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <img
                                                                    className="rounded-circle"
                                                                    src={`${process.env.REACT_APP_WEB_URL}/storage/${person.profile_image}`}
                                                                    style={{
                                                                        height: "35px",
                                                                        width: "35px",
                                                                    }}
                                                                    alt="Profile"
                                                                />
                                                            </TableCell>
                                                            <TableCell>{person.first_name}</TableCell>
                                                            <TableCell>{person.last_name}</TableCell>
                                                            <TableCell>{person.role}</TableCell>
                                                            <TableCell>{person.title}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                )
                            ))}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PersonnelHub;
