import React from "react";
import aboutImage from "../../assets/images/about-section.jpg";

const AboutUs = () => {
  return (
    <div id="about" className="about-section" data-scroll-index="1">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-6">
                <div className="about-left-image wow fadeInLeft animated">
                  <img
                    className="banner-image"
                    src={aboutImage}
                    alt="About Us"
                  />
                </div>
              </div>
              <div className="col-lg-6 align-self-center wow fadeInRight animated">
                <div className="about-right-content">
                  <div className="section-heading">
                    <h3>ABOUT US</h3>
                    <h2>
                      What is <em>WeRelic</em>
                    </h2>
                  </div>
                  <p>
                    WeRelic transforms the way educational institutions
                    operate by addressing the challenges faced in manual student
                    management, faculty coordination, and resource
                    administration. Our AI-powered solution automates academic
                    processes, offering comprehensive data management, efficient
                    scheduling, real-time reporting, and actionable insights.
                    Discover the future of educational management with
                    WeRelic, your trusted partner in streamlining
                    institutional success.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
