import React, { useState } from "react";
import CompleteRegistration from "./completeRegistration";
import { JoinOrganization, OrganisationRegistration, UserRolesSection } from "./organizationDetails";
import UserInformation from "./userDetails";

const ProgressBar = ({ currentStep }) => {
    const steps = ["User Information", "Account Type", "Organization Registration", "Complete Registration"];

    return (
        <div className="d-flex justify-content-center align-items-center">
            <ul id="progressbar">
                {steps.map((step, index) => (
                    <li key={index} className={`${index <= currentStep ? "active" : ""}${index === 0 ? " first" : ""}`}>
                        {step}
                    </li>
                ))}
            </ul>
        </div>
    );
};

const RegistrationPage = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const [userChoice, setUserChoice] = useState(null);

    const handleChoice = (choice) => {
        setUserChoice(choice);
    };
    const handleNextStep = (step) => {
        setCurrentStep(step);
    };

    return (
        <div className="registration-form pb-3">
            <div className="row">
                <div className="d-grid">
                    <div className="col-lg-12 mb-4">
                        <ProgressBar currentStep={currentStep} />
                    </div>
                    <div className="form-section animated justify-self-center fadeInDown mb-5">
                        {currentStep === 0 && (
                            <div>
                                <UserInformation onNextStep={handleNextStep} />
                            </div>
                        )}
                        {currentStep === 1 && (
                            <div>
                                <UserRolesSection onNextStep={handleNextStep} userChoice={handleChoice} />
                            </div>
                        )}
                        {currentStep === 2 && (
                            <div>
                                {userChoice === "create" ? (
                                    <div>
                                        <OrganisationRegistration
                                            onNextStep={handleNextStep}
                                            userChoice={handleChoice}
                                        />
                                    </div>
                                ) : userChoice === "join" ? (
                                    <div>
                                        <JoinOrganization onNextStep={handleNextStep} userChoice={handleChoice} />
                                    </div>
                                ) : null}
                            </div>
                        )}
                        {currentStep === 3 && (
                            <div>
                                <CompleteRegistration onNextStep={handleNextStep} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegistrationPage;
