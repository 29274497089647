import React from "react";
import Courses from "../../components/user/admin/courses";

const CoursesPage = () => {
    return (
        <div>
            <Courses />
        </div>
    )
}

export default CoursesPage;