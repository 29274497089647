import React from "react";
import Admissions from "../../components/user/admissions";

const AdmissionsPage = () => {
	return (
		<>
			<Admissions />
		</>
	);
};

export default AdmissionsPage;
