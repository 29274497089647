import React from "react";
import RegistrationPage from "../../components/registrationDetails";

const CompleteRegistration = () => {
    return (
        <div>
            <RegistrationPage />
        </div>
    );
};

export default CompleteRegistration;
