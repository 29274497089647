import React, { createRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import { useStateContext } from "../../context/ContextProvider";
import api from "../../api/axios";

const SignUpForm = () => {
    const nameRef = createRef();
    const emailRef = createRef();
    const passwordRef = createRef();
    const passwordConfirmationRef = createRef();

    const [errors, setErrors] = useState(null);
    const { setUser, setToken } = useStateContext();
    const [errorMessage, setErrorMessage] = useState("");
    const [agreeToTerms, setAgreeToTerms] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [passwordConfiirmVisible, setPasswordConfirmVisible] = useState(false);

    const navigate = useNavigate();

    const validateForm = () => {
        if (!emailRef.current || !emailRef.current.value.trim()) {
            setErrorMessage("Email cannot be empty");
            return;
        }

        if (!passwordRef.current || !passwordRef.current.value.trim()) {
            setErrorMessage("Password cannot be empty");
            return;
        }

        if (!passwordConfirmationRef.current || !passwordConfirmationRef.current.value.trim()) {
            setErrorMessage("Please Confirm your password. Cannot be empty");
            return;
        }

        if (!emailRef.current || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailRef.current.value.trim())) {
            setErrorMessage("Invalid email, Please enter a valid email!");
            return;
        }
    };
    const onSubmit = (e) => {
        e.preventDefault();
    
        const payload = {
            name: nameRef.current.value,
            email: emailRef.current.value,
            password: passwordRef.current.value,
            password_confirmation: passwordConfirmationRef.current.value,
        };
    
        api.post("/signup", payload)
            .then(({ data }) => {
                setUser(data.user);
                setToken(data.token);
                navigate("/user");
            })
            .catch((err) => {
                const response = err.response;
                if (response && response.status === 422) {
                    setErrors(response.data.errors);
                }
    
                const errorMessage = response?.data?.message || err.message || "An unexpected error occurred.";
                console.error(errorMessage);
                setErrorMessage(errorMessage);
            });
    };

    return (
        <div className="d-flex justify-content-center mt-5 mb-5">
            <div className="form-section">
                <form className="contact-form w-100 validate-form" onSubmit={onSubmit}>
                    <div className="position-absolute back-to-home">
                        <a href="/">{"< to home "}</a>
                    </div>
                    <span className="contact-form-title pb-1">Create Your Account</span>
                    <div className="mb-5">
                        <p>
                            Welcome to Cremimic! Begin your journey towards business excellence by registering below.
                            We're thrilled to have you join our community.
                        </p>
                    </div>
                    <div
                        className="wrap-input rs1-wrap-input validate-input"
                        data-validate="Please enter a username: eg. company-user"
                    >
                        <span className="label-input">Enter Name</span>
                        <input
                            className="input"
                            ref={nameRef}
                            type="text"
                            id="name"
                            name="username"
                            placeholder="Enter your name"
                            required
                            style={{
                                borderColor:
                                    errorMessage && (!emailRef.current || !emailRef.current.value.trim()) ? "red" : "",
                            }}
                        />
                        <span className="focus-input"></span>
                    </div>

                    <div
                        className="wrap-input rs1-wrap-input validate-input"
                        data-validate="Valid email is required: ex@abc.xyz"
                    >
                        <span className="label-input">Enter Email</span>
                        <input
                            className="input"
                            ref={emailRef}
                            id="email"
                            type="email"
                            name="email"
                            placeholder="Enter your email"
                            required
                            style={{
                                borderColor:
                                    errorMessage && (!emailRef.current || !emailRef.current.value.trim()) ? "red" : "",
                            }}
                        />
                        <span className="focus-input"></span>
                    </div>

                    <div className="d-flex flex-column wrap-input rs1-wrap-input validate-input">
                        <span className="label-input">Enter Password</span>
                        <input
                            ref={passwordRef}
                            id="password"
                            type={passwordVisible ? "text" : "password"}
                            name="password"
                            className="input"
                            placeholder="Enter your password"
                            required
                        />
                        <span
                            className="position-absolute align-self-end mr-2 password-toggle-icon"
                            style={{ top: "30px" }}
                            onClick={() => setPasswordVisible(!passwordVisible)}
                        >
                            <FontAwesomeIcon icon={passwordVisible ? faEye : faEyeSlash} />
                        </span>
                        <span className="focus-input"></span>
                    </div>

                    <div className="d-flex flex-column wrap-input rs1-wrap-input validate-input">
                        <span className="label-input">Confirm Password</span>
                        <input
                            ref={passwordConfirmationRef}
                            id="password_confirmation"
                            type={passwordConfiirmVisible ? "text" : "password"}
                            name="password_confirmation"
                            className="input"
                            placeholder="Confirm your password"
                            required
                        />
                        <span
                            className="position-absolute align-self-end mr-2 password-toggle-icon"
                            style={{ top: "30px" }}
                            onClick={() => setPasswordConfirmVisible(!passwordConfiirmVisible)}
                        >
                            <FontAwesomeIcon icon={passwordConfiirmVisible ? faEye : faEyeSlash} />
                        </span>
                        <span className="focus-input"></span>
                    </div>

                    <div className="mb-3 col-lg-12 d-flex flex-row w-100 justify-content-between">
                        <div className="d-flex align-items-center">
                            <input
                                type="checkbox"
                                id="rememberMe"
                                className="sign-checkbox"
                                checked={agreeToTerms}
                                onChange={() => setAgreeToTerms(!agreeToTerms)}
                                style={{ margin: "0px 10px" }}
                            />
                            <label className="ml-1">
                                <Link className="forgot-password-link" to="/signup">
                                    Agree to ferxity Terms and Conditions
                                </Link>
                            </label>
                        </div>
                    </div>
                    <div className="position-relative">
                        {errorMessage && (
                            <div className="alert alert-danger">
                                <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
                                {errorMessage}
                            </div>
                        )}
                    </div>

                    <div className="container-contact-form-btn position-relative">
                        <button className={agreeToTerms ? "contact-form-btn" : "contact-form-btn active"} type="submit">
                            <span>
                                Submit
                                <i className="fa fa-long-arrow-right m-l-7" aria-hidden="true"></i>
                            </span>
                        </button>
                    </div>
                    <span className="contact-more mt-2 d-flex flex-row justify-content-center w-100 ">
                        Already registered?:{" "}
                        <span className="contact-more-highlight">
                            <Link to="/login">Login to your account</Link>
                        </span>
                    </span>
                </form>
            </div>
        </div>
    );
};

const Signup = () => {
    return (
        <div className="registration-form">
            <div className="row">
                <SignUpForm />
            </div>
        </div>
    );
};

export default Signup;
