import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import api from "../../api/axios";
import CustomDialog from "./dialog";
import {
	TextFieldComponent,
	SelectField,
	AutocompleteField,
} from "./formFields";

const CreateOpportunityButton = ({ userId }) => {
	const [open, setOpen] = useState(false);
	const [organizations, setOrganizations] = useState([]);
	const [statusOptions] = useState(["Active", "Pending", "Inactive"]);
	const [formData, setFormData] = useState({
		user_id: userId,
		organization_id: "",
		title: "",
		expected_revenue: "",
		close_date: "",
		probability: "",
		description: "",
		status: "",
	});

	useEffect(() => {
		const getOrgs = () => {
			api.get(`/organizations/user-organization/${userId}`)
				.then(({ data: userData }) => {
					if (
						userData.organizations &&
						userData.organizations.length > 0
					) {
						setFormData((prevData) => ({
							...prevData,
							organization_id:
								userData.organizations[0].organization_id,
						}));
					}
					setOrganizations(userData.organizations);
				})
				.catch((error) => {
					console.error(
						"Error fetching users details",
						error.response
					);
				});
		};
		if (userId) {
			getOrgs();
		}
	}, [userId]);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleFormChange = (field, value) => {
		setFormData((prevData) => ({ ...prevData, [field]: value }));
	};

	const handleCreateOpportunity = async () => {
		handleClose();

		try {
			const response = await api.post(`/opportunities`, [formData]);
			console.log("Opportunity created successfully:", response.data);
		} catch (error) {
			console.error("Unable to create opportunity", error);
		}
	};

	return (
		<div>
			<button className="upload-btn" onClick={handleOpen}>
				New Opportunity
			</button>
			<CustomDialog
				isOpen={open}
				onClose={handleClose}
				event={{ type: "Create Opportunity" }}
				user={{ id: userId }}
				handleSubmit={handleCreateOpportunity}
			>
				<div className="d-flex flex-column" style={{ gap: "30px" }}>
					<AutocompleteField
						label="Organization"
						options={organizations}
						getOptionLabel={(org) => org.name}
						valueFinder={(org) =>
							org.organization_id === formData.organization_id
						}
						onChange={(e, newValue) => {
							handleFormChange(
								"organization_id",
								newValue ? newValue.organization_id : ""
							);
						}}
					/>
					<TextFieldComponent
						label="Title"
						value={formData.title}
						onChange={(e) =>
							handleFormChange("title", e.target.value)
						}
					/>
					<TextFieldComponent
						label="Expected Revenue"
						value={formData.expected_revenue}
						onChange={(e) =>
							handleFormChange("expected_revenue", e.target.value)
						}
					/>
					<DatePicker
						selected={formData.close_date}
						onChange={(value) =>
							handleFormChange("close_date", value)
						}
						dateFormat="yyyy-MM-dd"
						customInput={<TextFieldComponent label="Close Date" />}
					/>
					<TextFieldComponent
						label="Probability"
						value={formData.probability}
						onChange={(e) =>
							handleFormChange("probability", e.target.value)
						}
					/>
					<TextFieldComponent
						label="Description"
						value={formData.description}
						onChange={(e) =>
							handleFormChange("description", e.target.value)
						}
					/>
					<SelectField
						label="Status"
						value={formData.status}
						onChange={(e) =>
							handleFormChange("status", e.target.value)
						}
						options={statusOptions.map((status) => ({
							value: status,
							label: status,
						}))}
					/>
				</div>
			</CustomDialog>
		</div>
	);
};

export default CreateOpportunityButton;
