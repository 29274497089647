import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

import {
	Autocomplete,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@mui/material";
import {
	Select,
	MenuItem,
	FormControl,
	TextField,
	InputLabel,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import parseISO from "date-fns/parseISO";
import api from "../../../api/axios";

const CreateAdvertButton = ({ userId }) => {
	const [open, setOpen] = useState(false);
	const [organizations, setOrganizations] = useState([]);
	const [statusOptions] = useState(["radio", "online", "live"]);
	const [formData, setFormData] = useState({
		organization_id: "",
		source: "online",
		item: null,
		type: "product",
        from: parseISO(new Date().toISOString().split("T")[0]),
        to: parseISO(new Date().toISOString().split("T")[0]),
		budget: 0,
		cost: 0,
		net_earnings: 0,
		remarks: "",
		errorMessage: "",
	});

	useEffect(() => {
		const getOrgs = () => {
			api.get(`/organizations/user-organization/${userId}`)
				.then(({ data: userData }) => {
					if (
						userData.organizations &&
						userData.organizations.length > 0
					) {
						formData.organization_id =
							userData.organizations[0].organization_id;
					}
					setOrganizations(userData.organizations);
					setFormData((prevData) => ({
						...prevData,
						organization_id:
							userData.organizations[0].organization_id,
					}));
				})
				.catch((error) => {
					console.error(
						"Error fetching users details",
						error.response
					);
				});
		};
		if (userId) {
			getOrgs();
		}
	}, [userId]);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setFormData((prevData) => ({ ...prevData, errorMessage: "" }));
	};

	const handleFormChange = (field, value) => {
		setFormData((prevData) => ({ ...prevData, [field]: value }));
	};

	const handleImageChange = (e) => {
		const file = e.target.files[0];
		setFormData((prevData) => ({ ...prevData, image: file }));
	};

	const handleCreateAdvert = async () => {
		const requiredFields = [
			"organization_id",
			"source",
			"item",
			"type",
			"from",
			"to",
		];

		const hasEmptyFields = requiredFields.some((field) => !formData[field]);

		if (formData.end_date <= formData.start_date) {
			setFormData((prevData) => ({
				...prevData,
				errorMessage:
					"The end date should not be same as the start data.",
			}));
			return;
		}
		if (hasEmptyFields) {
			setFormData((prevData) => ({
				...prevData,
				errorMessage: "Please fill all fields.",
			}));
			return;
		}

		try {
			const response = await api.post(`/advertisements`, [formData]);

			console.log(
				"Advertisement created successfully:",
				response.data
			);
		} catch (error) {
			console.error("Unable to create advertisement", error);
		}

		handleClose();
	};

	return (
		<div>
			<button className="btn upload-btn" onClick={handleOpen}>
                + Create Advert
            </button>
			<Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
				<DialogTitle>Create Advertisement</DialogTitle>
				<DialogContent style={{ paddingTop: "15px" }}>
					<Stack spacing={2}>
						<FormControl>
							<InputLabel id="source">Source</InputLabel>
							<Select
								labelId="source"
								label="Source"
								fullWidth
								value={formData.source}
								onChange={(e) =>
									handleFormChange("source", e.target.value)
								}
							>
								{statusOptions.map((source) => (
									<MenuItem key={source} value={source}>
										{source}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<Autocomplete
                            labcel="Item"
                            fullWidth
                            options={organizations}
                            getOptionLabel={(org) => org.name}
                            value={
                                organizations.find(
                                    (item) => item.id === formData.item
                                ) || null
                            }
                            onChange={(e, newValue) =>
                                handleFormChange(
                                    "item",
                                    newValue ? newValue.id : ""
                                )
                            }
                            renderInput={(params) => (
                                <TextField {...params} label="Item ID" />
                            )}
                        />
                        <TextField
							label="Item Type"
							fullWidth
							placeholder=""
							value={formData.type}
							onChange={(e) =>
								handleFormChange("type", e.target.value)
							}
						/>
						<DatePicker
							selected={formData.from}
							onChange={(value) =>
								handleFormChange("from", value)
							}
							dateFormat="yyyy-MM-dd"
							customInput={
								<TextField label="From" fullWidth />
							}
						/>
						<DatePicker
							selected={formData.to}
							onChange={(value) => handleFormChange("to", value)}
							dateFormat="yyyy-MM-dd"
							customInput={<TextField label="To" fullWidth />}
						/>
						<TextField
							label="Budget"
							fullWidth
							value={formData.budget}
							onChange={(e) =>
								handleFormChange("budget", e.target.value)
							}
						/>
						<TextField
							label="Cost"
							fullWidth
							value={formData.cost}
							onChange={(e) =>
								handleFormChange("cost", e.target.value)
							}
						/>
						<TextField
							label="Net Earnings"
							fullWidth
							value={formData.net_earnings}
							onChange={(e) =>
								handleFormChange("net_earnings", e.target.value)
							}
						/>
						<TextField
							label="Remarks"
							fullWidth
							multiline
							rows={4}
							value={formData.remarks}
							onChange={(e) =>
								handleFormChange("remarks", e.target.value)
							}
						/>
					</Stack>
					{formData.errorMessage && (
						<div style={{ color: "red", marginTop: "10px" }}>
							{formData.errorMessage}
						</div>
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button onClick={handleCreateAdvert} color="primary">
						Create
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default CreateAdvertButton;
