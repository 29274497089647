import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import { TextField, InputLabel } from "@mui/material";
import Stack from "@mui/material/Stack";

import industries from "../../../constants/industries";
import timezones from "../../../constants/timezones";
import api from "../../../api/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const EditCompanyInfo = ({ organization }) => {
  const [open, setOpen] = useState(false);
  const [sizeOptions] = useState(["small", "medium", "large"]);
  const [formData, setFormData] = useState({
    name: "",
    slogan: "",
    industry: "",
    contact: "",
    email: "",
    size: "",
    city: "",
    state: "",
    type: "",
    revenue: null,
    logo: null,
    logo_path: null,
    website: "",
    timezone: "",
    postal_code: "",
    errorMessage: "",
  });

  useEffect(() => {
    setFormData({
      name: organization.name || "",
      slogan: organization.slogan || "",
      industry: organization.industry || null,
      contact: organization.contact || "",
      email: organization.email || "",
      size: organization.size || "",
      city: organization.city || "",
      revenue: organization.revenue || "",
      type: organization.type || "",
      state: organization.state || "",
      logo_path: organization.logo_path || "",
      website: organization.website || "",
      timezone: organization.timezone || null,
      postal_code: organization.postal_code || "",
    });
  }, [organization]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFormData((prevData) => ({ ...prevData, errorMessage: "" }));
  };

  const handleFormChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setFormData((prevData) => ({
        ...prevData,
        logo: reader.result,
      }));
    };
    if (file) {
      reader.readAsDataURL(file);
    }
    console.log(file);
    console.log(formData);
  };

  const handleUpdateOrganization = async () => {
    const requiredFields = ["name", "slogan", "industry", "contact", "email"];

    const hasEmptyFields = requiredFields.some((field) => !formData[field]);

    if (hasEmptyFields) {
      setFormData((prevData) => ({
        ...prevData,
        errorMessage: "Please fill all fields.",
      }));
      return;
    }
    try {
      const response = await api.put(
        `/organization/${organization.organization_id}`,
        {
          ...formData,
        }
      );

      console.log("Organization updated successfully:", response.data);
    } catch (error) {
      console.error("Unable to update organization", error);
    }

    handleClose();
  };

  return (
    <div>
      <FontAwesomeIcon
        icon={faEdit}
        size={"1x"}
        onClick={handleOpen}
        cursor={"pointer"}
      />
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Create Campaign</DialogTitle>
        <DialogContent style={{ paddingTop: "15px" }}>
          <Stack spacing={2}>
            <TextField
              label="Organization Name"
              fullWidth
              value={formData.name}
              onChange={(e) => handleFormChange("name", e.target.value)}
            />

            <TextField
              label="Slogan"
              fullWidth
              value={formData.slogan}
              onChange={(e) => handleFormChange("slogan", e.target.value)}
            />

            <Autocomplete
              label="Industry"
              fullWidth
              options={industries}
              getOptionLabel={(industry) => industry}
              value={formData.industry}
              onChange={(e, newValue) => handleFormChange("industry", newValue)}
              renderInput={(params) => (
                <TextField {...params} label="Industry" />
              )}
              virtual="true"
            />

            <TextField
              label="Contact"
              fullWidth
              value={formData.contact}
              onChange={(e) => handleFormChange("contact", e.target.value)}
            />

            <TextField
              label="Email"
              fullWidth
              value={formData.email}
              onChange={(e) => handleFormChange("email", e.target.value)}
            />
            {/* <TextField
                            label="description"
                            fullWidth
                            multiline
                            rows={4}
                            value={formData.description}
                            onChange={(e) => handleFormChange("email", e.target.value)}
                        /> */}
            <TextField
              label="City"
              fullWidth
              value={formData.city}
              onChange={(e) => handleFormChange("city", e.target.value)}
            />

            <Autocomplete
              label="Industry"
              fullWidth
              options={timezones}
              getOptionLabel={(timezone) => timezone}
              value={formData.timezone}
              onChange={(e, newValue) => handleFormChange("timezone", newValue)}
              renderInput={(params) => (
                <TextField {...params} label="Timezone" />
              )}
              virtual="true"
            />
            <TextField
              label="Revenue"
              fullWidth
              type="number"
              value={formData.revenue}
              onChange={(e) => handleFormChange("postal_code", e.target.value)}
            />

            <FormControl>
              <InputLabel id="size">Size</InputLabel>
              <Select
                labelId="size"
                label="size"
                fullWidth
                value={formData.size}
                onChange={(e) => handleFormChange("size", e.target.value)}
              >
                {sizeOptions.map((size) => (
                  <MenuItem key={size} value={size}>
                    {size}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Website"
              fullWidth
              value={formData.website}
              onChange={(e) => handleFormChange("website", e.target.value)}
            />
            <InputLabel htmlFor="organization-logo">
              Organization Logo
            </InputLabel>
            <TextField
              id="organization-logo"
              fullWidth
              type="file"
              accept="image/*"
              onChange={(e) => handleFormChange("logo", e.target.value)}
            />
          </Stack>
          {formData.errorMessage && (
            <div style={{ color: "red", marginTop: "10px" }}>
              {formData.errorMessage}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleUpdateOrganization} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditCompanyInfo;
