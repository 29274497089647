import React from "react";
import NavBar from "../../components/default/navBar";
import Footer from "../../components/default/footer";
import { SupportBody } from "../../components/default/supportComponents";

function Support() {
    return (
        <div>
            <NavBar />
            <SupportBody />
            <Footer />
        </div>
    );
}

export default Support;