import React from "react";

const Paginate = ({ totalPages, currentPage, onPageChange }) => {
    const getPageNumbers = () => {
        const pageNumbers = [];
        const maxButtons = 5;
        const maxPagesToShow = maxButtons - 2;

        if (totalPages <= maxButtons) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            const leftOffset = Math.floor(maxPagesToShow / 2);
            const rightOffset = totalPages - leftOffset;

            if (currentPage <= leftOffset) {
                for (let i = 1; i <= maxPagesToShow; i++) {
                    pageNumbers.push(i);
                }
            } else if (currentPage >= rightOffset) {
                for (let i = totalPages - maxPagesToShow + 1; i <= totalPages; i++) {
                    pageNumbers.push(i);
                }
            } else {
                const startPage = currentPage - leftOffset + 1;
                const endPage = currentPage + leftOffset;

                pageNumbers.push(1, "ellipsis");

                for (let i = startPage; i <= endPage; i++) {
                    pageNumbers.push(i);
                }

                pageNumbers.push("ellipsis", totalPages);
            }
        }

        return pageNumbers;
    };

    const handleClick = (pageNumber) => {
        if (pageNumber === "ellipsis" || pageNumber === currentPage) {
            return;
        }
        onPageChange(pageNumber);
    };

    return (
        <div className="pagination">
            <button className="btn-min" onClick={() => handleClick(1)} disabled={currentPage === 1}>
                {"<<"}
            </button>
            <button
                className="btn-min"
                onClick={() => handleClick(currentPage - 1)}
                disabled={currentPage === 1}
            >
                {"<"}
            </button>
            {getPageNumbers().map((pageNumber, index) => (
                <button
                    key={index}
                    onClick={() => handleClick(pageNumber)}
                    className={pageNumber === currentPage ? "btn-min active" : "btn-min"}
                    disabled={pageNumber === "ellipsis"}
                >
                    {pageNumber}
                </button>
            ))}
            <button
                className="btn-min"
                onClick={() => handleClick(currentPage + 1)}
                disabled={currentPage === totalPages}
            >
                {">"}
            </button>
            <button
                className="btn-min"
                onClick={() => handleClick(totalPages)}
                disabled={currentPage === totalPages}
            >
                {">>"}
            </button>
        </div>
    );
};

export default Paginate;
