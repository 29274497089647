import React from "react";
import Financials from "../../components/user/financialsComponents";

const FinancialsPage = () => {
    return (
        <div>
            <Financials />
        </div>
    )
}

export default FinancialsPage;