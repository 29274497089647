import React from 'react';
import { FaRegWindowClose } from 'react-icons/fa';

const CustomDialog = ({ isOpen, onClose, event, handleSubmit, children }) => {
    if (!isOpen) return null;

    const handleFormSubmit = (e) => {
        e.preventDefault();
        handleSubmit();
    };

    return (
        <div className="dialog-overlay">
            <div className="dialog-background" onClick={onClose}></div>
            <div className="dialog-content">
                <div className="d-flex flex-row justify-content-between">
                    <strong>{event.type}</strong>
                    <FaRegWindowClose onClick={onClose}>x</FaRegWindowClose>
                </div>
                <div className="row dialog-details mt-4 mb-3">
                    <form onSubmit={handleFormSubmit}>
                        {children}
                        <div className="d-flex flex-row justify-content-between w-100 mt-4">
                            <button
                                type="button"
                                className="nav-sign left-btn"
                                onClick={onClose}
                            >
                                Cancel
                            </button>
                            <button type="submit" className="nav-sign active">
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CustomDialog;
