import React from "react";
import Attendance from "../../components/user/attendanceComponents";

const AttendancePage = () => {
    return (
        <div>
            <Attendance />
        </div>
    )
}

export default AttendancePage;