import React, { useState, useEffect } from "react";

import { ProfileTab, NotificationsTab } from "../../components/user/settingsTabs";
import OrganizationTab from "../../components/user/organizationtab";
import OrganizationTab1 from "../../components/user/admin/companyInfo";
import AccessControl from "../../components/user/admin/accessControl";
import PersonnelHub from "../../components/user/admin/personnelHub";
import TeamTab from "../../components/user/teamsComponents";

import { useStateContext } from "../../context/ContextProvider";
import api from "../../api/axios";
import Preloader from "../../components/preloader";

const SettingsPage = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [companyInformation, setCompanyInformation] = useState({});
    const { user, setUser } = useStateContext();
    const [role, setRole] = useState("user");
    const [loadedTabs, setLoadedTabs] = useState([0]);
    const [load, setLoad] = useState(true);

    const defaultTabs = [
        // { label: "Organization Info", component: <OrganizationTab /> },
        { label: "My Profile", component: <ProfileTab /> },
        { label: "Teams", component: <TeamTab /> },
        { label: "Notifications", component: <NotificationsTab /> },
        // { label: "Terms & Conditions", component: <TermsConditionsTab /> },
        // { label: "Privacy & Security", component: <PrivacySecurityTab /> },
    ];

    const adminSettingsTabs = [
        { label: "My Organization", component: <OrganizationTab1 /> },
        { label: "My Profile", component: <ProfileTab /> },
        { label: "Personnel", component: <PersonnelHub companyInformation={companyInformation} /> },
        { label: "Teams", component: <TeamTab /> },
        { label: "Notifications", component: <NotificationsTab /> },
        { label: "Security", component: <AccessControl /> },
    ];

    const activeTabs = role === "admin" ? adminSettingsTabs : defaultTabs;

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const response = await api.get("/user");
                setUser(response.data);
            } catch (error) {
                console.error("Error fetching data", error.response);
            }
        };

        fetchUserDetails();
    }, []);

    useEffect(() => {
        const fetchUserAndCompanies = () => {
            api.get(`/institutions`)
                .then(({ data: userData }) => {
                    if (userData.role.role === 'admin') {
                        setCompanyInformation(userData.institute);
                    } else {
                        setCompanyInformation([]);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching user and organization details.", error.response);
                })
                .finally(() => {
                    setLoad(false);
                });
        };

        if (user && user.id) {
            fetchUserAndCompanies();
        }
    }, [user]);

    const loadContent = (index) => {
        if (!loadedTabs.includes(index)) {
            setLoadedTabs([...loadedTabs, index]);
        }
    };

    return (
        <>
            {load ? (
                <Preloader />
            ) : (
                <div className="settings-section">
                    <div className="settings-view">
                        <div className="navigation-tab">
                            <ul className="">
                                {activeTabs.map((tab, index) => (
                                    <li
                                        className={`nav-item ${activeTab === index ? "active" : ""}`}
                                        key={index}
                                        onClick={() => {
                                            setActiveTab(index);
                                            loadContent(index);
                                        }}
                                    >
                                        <button
                                            className={`${activeTab === index ? "active" : ""}`}
                                            onClick={() => {
                                                setActiveTab(index);
                                                loadContent(index);
                                            }}
                                        >
                                            {tab.label}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="tab-contents">
                            <div className="tab-content mt-2">
                                {activeTabs.map((tab, index) => (
                                    <div
                                        key={index}
                                        className={`tab-pane fade ${activeTab === index ? "show active" : ""}`}
                                    >
                                        {loadedTabs.includes(index) && tab.component}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default SettingsPage;
