import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import Preloader from "../preloader";
import api from "../../api/axios";
import {
	Chart as ChartJS,
	LineElement,
	PointElement,
	LinearScale,
	Title,
	CategoryScale,
	Tooltip,
	Legend,
} from "chart.js";

ChartJS.register(
	LineElement,
	PointElement,
	LinearScale,
	Title,
	CategoryScale,
	Tooltip,
	Legend
);

const Admissions = () => {
	const [user, setUser] = useState(null);
	const [admissions, setAdmissions] = useState({});
	const [load, setLoad] = useState(true);
	const [quarter, setQuarter] = useState("all");

	useEffect(() => {
		api.get("/user")
			.then(({ data: userData }) => {
				setUser(userData.id);
				setLoad(false);
			})
			.catch((error) => {
				console.error("Error fetching data", error.response);
			});
	}, []);

	useEffect(() => {
		const handleDataFetch = async () => {
			try {
				const response = await api.get(`/users-data/admissions/${user}`);
				setAdmissions(response.data[0][0].data);
				console.log(response.data[0][0].data);
			} catch (error) {
				console.error("Error fetching data", error.response);
			} finally {
				setLoad(false);
			}
		};

		if (user) {
			handleDataFetch();
		}
	}, [user]);

	const handleQuarterChange = (event) => {
		setQuarter(event.target.value);
	};

	const getChartData = () => {
		let labels = [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"Jun",
			"Jul",
			"Aug",
			"Sep",
			"Oct",
			"Nov",
			"Dec",
		];
		let data = [
			admissions.jan,
			admissions.feb,
			admissions.mar,
			admissions.apr,
			admissions.may,
			admissions.jun,
			admissions.jul,
			admissions.aug,
			admissions.sep,
			admissions.oct,
			admissions.nov,
			admissions.dec,
		];

		if (quarter === "Q1") {
			labels = ["Jan", "Feb", "Mar"];
			data = [admissions.jan, admissions.feb, admissions.mar];
		} else if (quarter === "Q2") {
			labels = ["Apr", "May", "Jun"];
			data = [admissions.apr, admissions.may, admissions.jun];
		} else if (quarter === "Q3") {
			labels = ["Jul", "Aug", "Sep"];
			data = [admissions.jul, admissions.aug, admissions.sep];
		} else if (quarter === "Q4") {
			labels = ["Oct", "Nov", "Dec"];
			data = [admissions.oct, admissions.nov, admissions.dec];
		}

		return {
			labels,
			datasets: [
				{
					label: "Admissions",
					data,
					fill: false,
					borderColor: "rgba(75, 192, 192, 1)",
					tension: 0.1,
				},
			],
		};
	};

	return load ? (
		<Preloader />
	) : (
		<div className="campaigns-section">
			<div className="container">
				<div className="d-flex flex-row">
					<h4 className="mr-1">Analysis</h4>
					<h4 className="mr-1">{">"}</h4>
					<h4 className="mr-1">
						<b>Admissions</b>
					</h4>
				</div>
				<div className="row">
					<div
						className="col-lg-12 d-flex flex-row justify-content-between align-items-center"
						style={{ margin: "15px 0px 15px 0px" }}
					>
						<h2 className="page-titles">Admissions</h2>
						<select
							onChange={handleQuarterChange}
							value={quarter}
							className="upload-btn"
						>
							<option value="all">All Months</option>
							<option value="Q1">Q1</option>
							<option value="Q2">Q2</option>
							<option value="Q3">Q3</option>
							<option value="Q4">Q4</option>
						</select>
					</div>
					<div className="col-lg-12">
						<Line data={getChartData()} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Admissions;
