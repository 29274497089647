import React, { useState, useEffect } from "react";
import api from "../../api/axios";
import CustomDialog from "./dialog";
import { TextFieldComponent, SelectField } from "./formFields";

const AddLecturersButton = ({ userId }) => {
    const [open, setOpen] = useState(false);
    const [organizations, setOrganizations] = useState([]);
    const [formData, setFormData] = useState({
        institution_id: "",
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        website: "",
        category: "",
        city: "",
        country: "",
        description: "",
        errorMessage: "",
    });

    useEffect(() => {
        const getOrgs = async () => {
            try {
                const { data: userData } = await api.get(`/institutions/`);
                if (userData.intitution) {
                    setFormData((prevData) => ({
                        ...prevData,
                        institution_id: userData.institution.institution_id,
                    }));
                }
                setOrganizations([userData.institution]);
            } catch (error) {
                console.error("Error fetching users details", error.response);
            }
        };
        if (userId) {
            getOrgs();
        }
    }, [userId]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setFormData((prevData) => ({ ...prevData, errorMessage: "" }));
    };

    const handleFormChange = (field, value) => {
        setFormData((prevData) => ({ ...prevData, [field]: value }));
    };

    const handleCreateLead = async () => {
        const requiredFields = [
            "institution_id",
            "first_name",
            "last_name",
            "contact",
            "email",
            "website",
            "category",
            "city",
            "country",
            "description",
        ];

        const hasEmptyFields = requiredFields.some((field) => !formData[field]);

        if (hasEmptyFields) {
            setFormData((prevData) => ({
                ...prevData,
                errorMessage: "Please fill all fields.",
            }));
            return;
        }

        try {
            const response = await api.post(`/lecturers`, formData);
            console.log("Lecturer added successfully:", response.data);
        } catch (error) {
            setFormData((prevData) => ({
                ...prevData,
                errorMessage: error.message,
            }));
            console.error("Unable to add lecturer", error);
        }
        handleClose();
    };

    return (
        <div>
            <button className="upload-btn" onClick={handleOpen}>
                Add Lecturer
            </button>
            <CustomDialog
                isOpen={open}
                onClose={handleClose}
                event={{ type: "Add Vendor" }}
                user={{ id: userId }}
                handleSubmit={handleCreateLead}
            >
                <div className="d-flex flex-column" style={{ gap: "30px" }}>
                    <SelectField
                        label="Institution"
                        options={organizations}
                        value={formData.institution_id}
                        onChange={(newValue) =>
                            handleFormChange("institution_id", newValue ? newValue.institutions : "")
                        }
                        getOptionLabel={(org) => org.name}
                    />
                    <TextFieldComponent
                        label="First Name"
                        value={formData.first_name}
                        onChange={(e) => handleFormChange("first_name", e.target.value)}
                    />
                    <TextFieldComponent
                        label="Last Name"
                        value={formData.last_name}
                        onChange={(e) => handleFormChange("last_name", e.target.value)}
                    />
                    <TextFieldComponent
                        label="Email"
                        value={formData.email}
                        onChange={(e) => handleFormChange("email", e.target.value)}
                    />
                    <TextFieldComponent
                        label="Phone"
                        value={formData.phone}
                        onChange={(e) => handleFormChange("phone", e.target.value)}
                    />
                    <TextFieldComponent
                        label="Website"
                        value={formData.website}
                        onChange={(e) => handleFormChange("website", e.target.value)}
                    />
                    <TextFieldComponent
                        label="Category"
                        value={formData.category}
                        onChange={(e) => handleFormChange("category", e.target.value)}
                    />
                    <TextFieldComponent
                        label="City"
                        value={formData.city}
                        onChange={(e) => handleFormChange("city", e.target.value)}
                    />
                    <TextFieldComponent
                        label="Country"
                        value={formData.country}
                        onChange={(e) => handleFormChange("country", e.target.value)}
                    />
                    <TextFieldComponent
                        label="Description"
                        value={formData.description}
                        onChange={(e) => handleFormChange("description", e.target.value)}
                    />
                    {formData.errorMessage && (
                        <div style={{ color: "red", marginTop: "10px" }}>
                            {formData.errorMessage}
                        </div>
                    )}
                </div>
            </CustomDialog>
        </div>
    );
};

export default AddLecturersButton;
