// src/components/CreateLeadsButton.js
import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { TextField } from "@mui/material";
import api from "../../api/axios";
import CustomDialog from "./dialog";
import {
    AutocompleteField,
    TextFieldComponent,
    NumberField,
    SelectField
} from './formFields';

const CreateLeadsButton = ({ userId }) => {
    const [open, setOpen] = useState(false);
    const [organizations, setOrganizations] = useState([]);
    const [campaigns, setCampaigns] = useState([]);
    const [salesPhases, setSalesPhases] = useState([]);
    const [statusOptions] = useState(["Active", "Inactive"]);
    const [formData, setFormData] = useState({
		user_id: null,
        campaign_id: null,
        sales_phase_id: null,
        organization_id: null,
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        source: "",
        percentage: "",
        notes: "",
        image: "",
        location: null,
        status: "Active",
        score: 0,
        errorMessage: "",
    });

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const latitude = position.coords.latitude;
                    const longitude = position.coords.longitude;
                    setFormData((prevData) => ({
                        ...prevData,
                        location: `${latitude},${longitude}`,
                    }));
                },
                (error) => {
                    console.error("Error getting user's location:", error);
                }
            );
        } else {
            console.error("Geolocation is not supported by this browser.");
        }
    }, []);

    useEffect(() => {
        const getOrgs = () => {
            api.get(`/organizations/user-organization/${userId}`)
                .then(({ data: userData }) => {
                    if (userData.organizations && userData.organizations.length > 0) {
                        formData.organization_id = userData.organizations[0].organization_id;
                    }
                    setOrganizations(userData.organizations);
                    setFormData((prevData) => ({
                        ...prevData,
                        organization_id: userData.organizations[0].organization_id,
						user_id: userId
                    }));
                    getLeads(userData.organizations[0].organization_id);
                })
                .catch((error) => {
                    console.error("Error fetching users details", error.response);
                });
        };
        if (userId) {
            getOrgs();
        }
    }, [userId]);

    const getLeads = (orgId) => {
        if (!orgId) {
            return;
        }
        api.get(`/users-data/lead-items/${orgId}`)
            .then(({ data: userData }) => {
                setCampaigns(userData.campaigns);
                setSalesPhases(userData.phases);
            })
            .catch((error) => {
                console.error("Error fetching campaigns", error.response);
            });
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleFormChange = (field, value) => {
        setFormData((prevData) => ({ ...prevData, [field]: value }));
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setFormData((prevData) => ({ ...prevData, image: file }));
    };

    const handleImageUpload = async (file) => {
        const formData = new FormData();
        formData.append("file", file);

        try {
            const response = await api.post(`/leads/upload/${userId}`, formData);
            return response.data.path;
        } catch (error) {
            console.error("Error uploading image", error);
            return null;
        }
    };

    const handleCreateLead = async () => {
        const requiredFields = [
            "sales_phase_id",
            "organization_id",
            "first_name",
            "last_name",
            "email",
            "phone",
            "source",
            "percentage",
            "status",
        ];

        const hasEmptyFields = requiredFields.some((field) => !formData[field]);

        if (hasEmptyFields) {
            setFormData((prevData) => ({
                ...prevData,
                errorMessage: "Please fill all fields.",
            }));
            return;
        }

        if (formData.image instanceof File) {
            const imagePath = await handleImageUpload(formData.image);

            if (imagePath) {
                try {
                    const response = await api.post(`/leads`, {
                        ...formData,
                        image: imagePath,
                    });

                    console.log("Lead created successfully:", response.data);
                } catch (error) {
                    console.error("Unable to create lead", error);
                }
            } else {
                console.error("Image upload failed. Lead not created.");
            }
        } else {
            try {
                const response = await api.post(`/leads`, {
                    ...formData,
                });

                console.log("Lead created successfully:", response.data);
            } catch (error) {
                console.error("Unable to create lead", error);
            }
        }
        handleClose();
    };

    return (
        <div>
            <button className="upload-btn" onClick={handleOpen}>
                Create Lead
            </button>
            <CustomDialog
                isOpen={open}
                onClose={handleClose}
                event={{ type: "Add a new lead" }}
                user={{ id: userId }}
                handleSubmit={handleCreateLead}
            >
                <div className="d-flex flex-column mr-3 ml-3" style={{ gap: "30px" }}>
                    <AutocompleteField
                        label="Organization"
                        options={organizations}
                        getOptionLabel={(org) => org.name}
                        valueFinder={(org) => org.organization_id === formData.organization_id}
                        onChange={(newValue) => {
                            handleFormChange("organization_id", newValue ? newValue.organization_id : "");
                            getLeads(newValue ? newValue.organization_id : null);
                        }}
                    />

                    <AutocompleteField
                        label="Sales Phase"
                        options={salesPhases}
                        getOptionLabel={(phase) => phase.stage}
                        valueFinder={(phase) => phase.id === formData.sales_phase_id}
                        onChange={(newValue) => handleFormChange("sales_phase_id", newValue ? newValue.id : "")}
                    />

                    <AutocompleteField
                        label="Campaign"
                        options={campaigns}
                        getOptionLabel={(campaign) => campaign.campaign_name}
                        valueFinder={(campaign) => campaign.id === formData.campaign_id}
                        onChange={(newValue) => handleFormChange("campaign_id", newValue ? newValue.id : "")}
                    />

                    <TextFieldComponent
                        label="First Name"
                        field="first_name"
                        value={formData.first_name}
                        onChange={handleFormChange}
                    />
                    <TextFieldComponent
                        label="Last Name"
                        field="last_name"
                        value={formData.last_name}
                        onChange={handleFormChange}
                    />
                    <TextFieldComponent
                        label="Email"
                        field="email"
                        value={formData.email}
                        onChange={handleFormChange}
                    />
                    <TextFieldComponent
                        label="Contact"
                        field="phone"
                        value={formData.phone}
                        onChange={handleFormChange}
                    />
                    <TextFieldComponent
                        label="Source"
                        field="source"
                        value={formData.source}
                        onChange={handleFormChange}
                    />

                    <SelectField
                        label="Status"
                        value={formData.status}
                        options={statusOptions}
                        onChange={(value) => handleFormChange("status", value)}
                    />

                    <NumberField
                        label="Percentage"
                        field="percentage"
                        value={formData.percentage}
                        onChange={handleFormChange}
                    />
                    <NumberField
                        label="Score"
                        field="score"
                        value={formData.score}
                        onChange={handleFormChange}
                    />

                    <TextField
                        label="Notes"
                        fullWidth
                        multiline
                        rows={4}
                        value={formData.notes}
                        onChange={(e) => handleFormChange("notes", e.target.value)}
                    />

                    <TextField
                        fullWidth
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                    />

                    {formData.errorMessage && (
                        <div style={{ color: "red", marginTop: "10px" }}>
                            {formData.errorMessage}
                        </div>
                    )}
                </div>
            </CustomDialog>
        </div>
    );
};

export default CreateLeadsButton;
