import React, { useState, useEffect } from "react";
import timezones from "../constants/timezones";
import industries from "../constants/industries";

import api from "../api/axios";

import { Autocomplete, MenuItem, Select, TextField } from "@mui/material";

const UserRolesSection = ({ onNextStep, userChoice }) => {
    const handleSkip = () => {
        onNextStep(3);
    };

    const handleChoice = (choice) => {
        userChoice(choice);
        onNextStep(2);
    };

    return (
        <form className="contact-form w-100 validate-form">
            <div className="position-absolute back-to-home">
                <a href="/">{"> finish up later"}</a>
            </div>
            <span className="contact-form-title pb-3">
                Will you creating a new organization or joining an existing one?
            </span>
            <div className="w-100 d-flex justify-content-center">
                <div className="container-contact-form-btn position-relative">
                    <button className="contact-form-btn w-100 ml-2 mr-2" onClick={() => handleChoice("create")}>
                        <span>
                            Add Your Institution
                            <i className="fa fa-long-arrow-right m-l-7" aria-hidden="true"></i>
                        </span>
                    </button>
                </div>
            </div>
            <div className="w-100 d-flex justify-content-center">
                <div className="container-contact-form-btn position-relative">
                    <div className="container-contact-form-btn position-relative">
                        <button className="contact-form-btn w-100 ml-2 mr-2" onClick={() => handleChoice("join")}>
                            <span>
                                Join Institute
                                <i className="fa fa-long-arrow-right m-l-7" aria-hidden="true"></i>
                            </span>
                        </button>
                    </div>
                </div>
            </div>

            <div className="btn-section mt-4">
                <button type="button" className="nav-sign left-btn" onClick={handleSkip}>
                    Skip
                </button>
            </div>
        </form>
    );
};

const JoinOrganization = ({ onNextStep }) => {
    const [organizations, setOrganizations] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [selectedRole, setSelectedRole] = useState("");
    const [formData, setFormData] = useState({
        institution_id: null,
        user_id: null,
        token: null,
        department: null,
        title: "",
        role: "employee",
    });

    const roles = [
        { value: "admin", label: "Admin" },
        { value: "receptionist", label: "Receptionist" },
        { value: "manager", label: "Manager" },
        { value: "student", label: "Student" },
    ];

    useEffect(() => {
        api.get("/user")
            .then(({ data }) => {
                setFormData((prevData) => ({ ...prevData, ["user_id"]: data.id }));
            })
            .catch((error) => {
                console.error("Error fetching data", error.response);
            });
        api.get("/institutions")
            .then(({ data }) => {
                console.log(data);
                setOrganizations(data.data);
            })
            .catch((error) => {
                console.error("Error fetching Organizations:", error.response);
            });
    }, [setOrganizations]);

    const getDepartments = ({institution_id}) => {
        api.get(`departments/organization/${institution_id}`)
        .then(({ data }) => {
            console.log(institution_id, "departments", data);
            setDepartments(data);
            setFormData((prevData) => ({...prevData, ['department']: data[0].id}));
        })
        .catch((error) => {
            console.error("Error fetching Departments:", error.response);
        });
    }

    const handleSkip = () => {
        onNextStep(1);
    };

    const handleFormChange = (field, value) => {
        setFormData((prevData) => ({ ...prevData, [field]: value }));
        if (field === 'institution_id') {
            getDepartments({ institution_id: value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await api.post("/institutions/user-roles", formData);

            console.error("Institution data submitted successfully:", response.data);

            onNextStep(3);
        } catch (error) {
            console.error("Error submitting organization data:", error.response);
        }
    };

    return (
        <form className="contact-form d-flex flex-column zoom-in-slide" onSubmit={handleSubmit}>
            <span className="contact-form-title pb-3">Your Institution's Details</span>
            <div className="wrap-input rs1-wrap-input validate-input">
                <span className="label-input">Select Your Institution</span>
                <Autocomplete
                    fullWidth
                    options={organizations}
                    getOptionLabel={(org) => org.name}
                    value={organizations.find((org) => org.institution_id === formData.institution_id) || null}
                    onChange={(e, newValue) =>
                        handleFormChange("institution_id", newValue ? newValue.institution_id : "")
                    }
                    sx={{
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                            border: "none",
                        },
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            name="institution_id"
                            className="input"
                            placeholder="Select your organization"
                            InputProps={{
                                ...params.InputProps,
                                className: "",
                                style: {
                                    ...params.InputProps.style,
                                },
                            }}
                        />
                    )}
                />
            </div>
            <div
                className="wrap-input rs1-wrap-input validate-input"
                data-validate="Please enter postal code: eg. a123b00c"
            >
                <span className="label-input">Institution Token</span>
                <input
                    name="token"
                    type="text"
                    className="input"
                    required
                    onChange={(e) => handleFormChange("token", e.target.value)}
                    placeholder="Enter your organization's token/code"
                />
                <span className="focus-input"></span>
            </div>

            <div className="wrap-input rs1-wrap-input validate-input">
                <span className="label-input">Select your Department</span>
                <Autocomplete
                    fullWidth
                    options={departments}
                    getOptionLabel={(department) => department.name}
                    value={departments.find((department) => department.id === department.id) || null}
                    onChange={(e, newValue) =>
                        handleFormChange("department", newValue ? newValue.id : "")
                    }
                    sx={{
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                            border: "none",
                        },
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            name="department"
                            className="input"
                            placeholder="Select your department"
                            InputProps={{
                                ...params.InputProps,
                                className: "",
                                style: {
                                    ...params.InputProps.style,
                                },
                            }}
                        />
                    )}
                />
            </div>
            <div
                className="wrap-input rs1-wrap-input validate-input"
                data-validate="Please your title: eg. Sales person"
            >
                <span className="label-input">Enter Your Title</span>
                <input
                    name="title"
                    type="text"
                    className="input"
                    required
                    onChange={(e) => handleFormChange("title", e.target.value)}
                    placeholder="Enter your title in the organization"
                />
                <span className="focus-input"></span>
            </div>

            <div
                className="wrap-input rs1-wrap-input validate-input"
                data-validate="Please enter postal code: eg. 00000"
            >
                <span className="label-input">Your Role</span>
                <Select
                    name="role"
                    fullWidth
                    className="input"
                    value={formData.role || "employee"}
                    sx={{
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                            border: "none",
                        },
                    }}
                    onChange={(e) => {
                        setSelectedRole(e.target.value);
                        handleFormChange("role", e.target.value);
                    }}
                >
                    {roles.map((role) => (
                        <MenuItem key={role.value} value={role.value}>
                            {role.label}
                        </MenuItem>
                    ))}
                </Select>
            </div>
            <div className="d-flex flex-row justify-content-between w-100">
                <button type="button" className="nav-sign left-btn" onClick={handleSkip}>
                    Back
                </button>
                <button type="submit" className="nav-sign active">
                    Continue
                </button>
            </div>
        </form>
    );
};

const OrganisationRegistration = ({ onNextStep }) => {
    const [selectedSize, setSelectedSize] = useState("");
    const sizes = [
        { value: "small", label: "Small" },
        { value: "medium", label: "Medium" },
        { value: "large", label: "Large" },
    ];
    const [formData, setFormData] = useState({
        name: "",
        motto: "",
        email: "",
        phone: "",
        country: "",
        size: "small",
        postal_code: "",
        city: "",
        created_by: "",
        director: "",
    });

    useEffect(() => {
        api.get("/user")
            .then(({ data }) => {
                setFormData((prevData) => ({ ...prevData, created_by: data.id , director: data.id}));
            })
            .catch((error) => {
                console.error("Error fetching data", error.response);
            });
    }, [setFormData]);

    const handleSkip = () => {
        onNextStep(1);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await api.post("/institutions", formData);

            console.log("Institution data submitted successfully:", response.data);
            onNextStep(3);
        } catch (error) {
            console.error("Error submitting Institution data:", error);
        }
    };

    const handleFormChange = (field, value) => {
        setFormData((prevData) => ({ ...prevData, [field]: value }));
    };

    return (
        <form className="contact-form d-flex flex-column zoom-in-slide" onSubmit={handleSubmit}>
            <span className="contact-form-title pb-3">Add Your Institution</span>
            <div className="wrap-input rs1-wrap-input validate-input">
                <span className="label-input">Enter Your Institution's Name</span>
                <input
                    name="name"
                    type="text"
                    className="input"
                    value={formData.name}
                    placeholder="Enter Name"
                    onChange={(e) => handleFormChange("name", e.target.value)}
                    required
                />
                <span className="focus-input"></span>
            </div>
            <div className="wrap-input rs1-wrap-input validate-input">
                <span className="label-input">Enter Your Institution's Motto</span>
                <input
                    name="motto"
                    id="motto"
                    type="text"
                    className="input"
                    value={formData.motto}
                    placeholder="Enter Motto"
                    onChange={(e) => handleFormChange("motto", e.target.value)}
                />
                <span className="focus-input"></span>
            </div>
            <div
                className="wrap-input rs1-wrap-input validate-input"
                data-validate="Please enter a email: eg. info@company.com"
            >
                <span className="label-input">Enter Communication Email</span>
                <input
                    className="input"
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={(e) => handleFormChange("email", e.target.value)}
                    required
                    placeholder="Enter your organization's email"
                />
                <span className="focus-input"></span>
            </div>
            <div
                className="wrap-input rs1-wrap-input validate-input"
                data-validate="Please enter a valid contact: eg. +254 7123 45678"
            >
                <span className="label-input">Enter Phone</span>
                <input
                    className="input"
                    name="phone"
                    type="text"
                    value={formData.phone}
                    onChange={(e) => handleFormChange("phone", e.target.value)}
                    placeholder="Enter your organization's phone"
                />
                <span className="focus-input"></span>
            </div>
            <div className="d-flex flex-row w-100" style={{ gap: "20px" }}>
                <div
                    className="wrap-input rs1-wrap-input validate-input"
                    data-validate="Please enter a city: eg. Nairobi"
                >
                    <span className="label-input">Enter Your Country</span>
                    <input
                        className="input"
                        name="country"
                        type="text"
                        value={formData.state}
                        onChange={(e) => handleFormChange("country", e.target.value)}
                        placeholder="Enter Country"
                    />
                    <span className="focus-input"></span>
                </div>
                <div
                    className="wrap-input rs1-wrap-input validate-input"
                    data-validate="Please enter postal code: eg. 00000"
                >
                    <span className="label-input">Enter Postal Code</span>
                    <input
                        className="input"
                        name="postal_code"
                        type="text"
                        value={formData.postal_code}
                        onChange={(e) => handleFormChange("postal_code", e.target.value)}
                        placeholder="Enter postal code"
                    />
                    <span className="focus-input"></span>
                </div>
            </div>
            <div className="wrap-input rs1-wrap-input validate-input">
                <span className="label-input">Enter your city</span>
                <div className="input-container">
                    <input
                        name="city"
                        type="text"
                        className="input"
                        value={formData.city}
                        placeholder="Enter city"
                        onChange={(e) => handleFormChange("city", e.target.value)}
                    />
                    <span className="focus-input"></span>
                </div>
            </div>
            <div className="wrap-input rs1-wrap-input validate-input">
                <span className="label-input">Select Your Company Size</span>
                <Select
                    name="size"
                    fullWidth
                    className="input"
                    value={selectedSize}
                    placeholder="Select your company size"
                    sx={{
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                            border: "none",
                        },
                    }}
                    onChange={(e) => {
                        setSelectedSize(e.target.value);
                        handleFormChange("size", e.target.value);
                    }}
                >
                    {sizes.map((size) => (
                        <MenuItem key={size.value} value={size.value}>
                            {size.label}
                        </MenuItem>
                    ))}
                </Select>
            </div>
            
            <div className="d-flex dlex-row justify-content-between w-100">
                <button type="button" className="nav-sign left-btn" onClick={handleSkip}>
                    Back
                </button>
                <button type="submit" className="nav-sign active">
                    Continue
                </button>
            </div>
        </form>
    );
};

export { OrganisationRegistration, JoinOrganization, UserRolesSection };
